.subpage-header{
	float: left;
	width: 100%;
	margin-top: 15px;
	text-align: center;
	.subpage-head-box{
		background-repeat: no-repeat;
		background-size: cover ;
		padding: 40px;
		color: $white;
		h1{
			font-weight: 700;
			text-shadow: 0px 0px 30px $black;
		}
		p{
			font-weight: 700;
			text-shadow: 0px 0px 30px $black;
			color: $white;
		}
	}
}
.subpage-container{
	margin-top: 30px;
	display: inline-block;
	
	.section-order{
		&:nth-child(3n+1){
			.slider-box{
				.tab-img{
					order: 2;
					padding: 0;
				}
			}
		}
		&:nth-child(5){
			.slider-box{
				.tab-img{
					order: 2;
					padding: 0;
				}
			}
		}
	}
	
	.slider-box{
		background: $white;
		box-shadow: 4px 7px 14px 0px rgba(0, 0, 0, 0.05);
		border-radius: 30px;
		overflow: hidden;
		text-align: left;
		margin-bottom: 15px;
		.tab-img{
			overflow: hidden;
			height: 300px;
			position: relative;
			img{
				max-width: auto;
				height: 100%;
				position: absolute;
				top:50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
		.slide-img{
			width: 127px;
			margin-bottom: 15px;
			height: 100px;
			img{
				max-width: 100%;
				max-height: 100%;
			}
		}
		.slide-description{
			h3{
				color: $defaultTitleColor;
				margin-bottom: 15px;
			}
			p{
				font-size: 14px;
				height: 54px;
			}
		}
		a{
	
			
		}
	}
}


.category-bg {
    position: absolute;
    top: 0;
    width: 100%;
	height: 100%;
	z-index: 0;
	.path_1 {
		width: 300px;
		height: 300px;
		position: absolute;
		left: -95px;
		background-size: 100%;
		top: 56px;
	}
	.path_2 {
		width: 300px;
		height: 300px;
		position: absolute;
		right: -95px;
		background-size: 100%;
		bottom: 56px;
	}
}

.stores{
	margin-top:279px ;
}



@media only screen and (max-width:768px){
	.subpage-container{
		.card{
			padding: 0 !important;
		}
	}
}

