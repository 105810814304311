.kurumsal_iyilik_platformu{
	position: relative;
	z-index: 0;
    header{
        .logo{
            .header-bg{
                background-image: none !important;
            }
        }
        .main-menu{
            display: inline-block;
            width: auto;
            text-align: left;
            float: right;
            ul{
                text-align: right;
                li{
                    display: inline-block;
                    float: none;
                }
            }
        }
        .login-menu{
            float: right;
        }
    }
    .section{
		&-header{
			text-align: center;
			&:before{
				display: none;
			}
			.title{
				font-weight: 600;
			}
			.description{
				display: inline-block;
				max-width: 600px;
				float: none;
				color: $gray-700;
			}
		}
		&-body{
			margin-top: 30px;
		}
        &-main{
			height: 100vh;
			padding: 0;
        }
        .img{
			position: absolute;
			height: 100vh;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			video {
				right: 0;
				position: absolute;
				top: 0;
			}
            img{
                float: right;
                position: absolute;
				right: 0px;
				top: 62px;
            }
        }
        .section-inner{
            position: relative;
            z-index: 9;
            .title {
                font-size: 5.5vh;
				font-weight: 700;
				margin-bottom: 3vh;
            }
            
            .desc {
                margin-bottom: 9vh;
                max-width: 500px;
            }
            .desc-2 {
                max-width: 500px;
            }
            
            .btn {
				margin-bottom: 5vh;
				font-size: 2.5vh;
            }
        }
	}
	.companies-container {
		width: 100%;
		a{
			width: 20%;
			transition: all .3s ease-in-out;
			background: #fff;
			height: 69px;
			margin-right: 19px;
			border-radius: 15px;
			box-shadow: 0 0 34px 0 rgba(0,93,255,.14);
			position: relative;
			display: inline-block;
			img{
				max-height: 50%;
				transition: all .3s ease-in-out;
				max-width: 50%;
				top: 50%;
				position: absolute;
				transform: translate(-50%,-50%);
				left: 50%;
			}
		}
	}
	.partner-box{
		width: 100%;
		transition: all .3s ease-in-out;
		background: #fff;
		height:200px;
		margin-right: 19px;
		border-radius: 15px;
		box-shadow: 0 0 34px 0 rgba(0,93,255,.14);
		position: relative;
		img{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			max-width: 175px;
			max-height: 175px;
		}
	}
	.other-box{
		width: 100%;
		transition: all .3s ease-in-out;
		background: #fff;
		margin-right: 19px;
		border-radius: 15px;
		box-shadow: 0 0 34px 0 rgba(0,93,255,.14);
		position: relative;
		padding: 30px 15px;
		text-align: center;
		margin-bottom: 30px;
		height: 248px;
		.icon{
			height: 75px;
			position: relative;
			margin-bottom: 15px;
			img{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				height: 100%;
			}
		}
		.title{
			font-weight: 600;
			font-size: 18px;
		}
	}
	
	
	
	footer{
		background-color: #F6BC00;
		padding: 30px 0;
		.logo{
			display: inline-block;
			a{
				padding: 0 !important;
			}
		}
		i{
			font-size: 60px;
		}
		a{
			padding: 21px 0;
			display: block;
		}
	}
}

.ik-header {
	text-align: center;
	i {font-size: 55px;}
}

#exampleModalLong{
	&-backdrop{
		background: $white;
		&.show{
			opacity: 0.8;
		}
	}
	.close{
		opacity: 1;
		position: absolute;
		top: 15px;
		left: 15px;
		text-shadow: 0px 0px 0px;
		font-size: 20px;
		span{
			font-size: 37px;
			font-weight: 100;
		}
	}
	.form{
		label {
			background: #FFF;
			padding: 0 10px;
			margin-left: 15px;
		}
		input{
			margin-top: -19px;
			border-radius: 10px !important;
			height: 59px;
		}
	}
	.modal-dialog{
		max-width: 656px !important;
	}
	.modal-content{
		box-shadow: 6px 18px 24px 0  rgba($color: $black, $alpha: 0.11);
		border-radius: 10px !important;
	}
	.modal-body{
		padding: 50px 75px;
		background-repeat: no-repeat;
		background-position: bottom right;
	}
}

.modal{
	&-backdrop{
		background: $white;
		&.show{
			opacity: 0.8;
		}
	}
}
@media only screen and (max-width:1500px){
	.kurumsal_iyilik_platformu{
		.section{
			&-main{
				.img{
					img{
					}
				}
			}
			.section-inner{
				margin-top: 20vh;
			}
		}
	}
}
@media only screen and (max-width:1300px){
	.kurumsal_iyilik_platformu{
		.section{
			&-main{
				.img{
					img{
						width: 138%;
					}
				}
			}
			.section-inner{
				margin-top: 20vh;
			}
		}
	}
}

@media only screen and (max-width:992px){
	.kurumsal_iyilik_platformu{
		.header{
			.login-menu{
				display: block;
				width: auto !important;
				left: inherit;
				right: 12px;
				top: 23px;
			}
			.main-menu{
				display: none;
				&.active{
					display: block;
				}
			}
		}
		.section{
			&-main{
				.img{
					position: static;
					/* margin-top: 228px; */
					height: auto;

					video{
						position: static;
						width: 100%;
					}
				}
			}
		}
		.modal{
			&-backdrop{
				&.show{
				}
			}
			.close{
				span{
				}
			}
			&-dialog{
			}
			&-content{
			}
			&-body{
				padding: 30px 30px;
			}
		}
		.companies-container {
			width: 100%;
			a{
				width: 44%;
				transition: all .3s ease-in-out;
				background: #fff;
				height: 69px;
				margin-right: 19px;
				border-radius: 15px;
				box-shadow: 0 0 34px 0 rgba(0,93,255,.14);
				position: relative;
				display: inline-block;
				margin-bottom: 15px;
				img{
					max-height: 50%;
					transition: all .3s ease-in-out;
					max-width: 50%;
					top: 50%;
					position: absolute;
					transform: translate(-50%,-50%);
					left: 50%;
				}
			}
		}
	}
}




.navigation{
	display: flex;align-items: center;
}
.login-btn{
	border:3px solid #ffb700;
	width: 164px;
	height: 45px;
	border-radius: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	
	font-size: 18px;
	font-weight: 700;
	color:#ffb700;
	margin-right: 40px;
}
.htw .uyeol-btn{
	margin: 0 auto;
	margin-top: 40px;
	width: 270px;
	height: 50px;
	display: flex;
	font-family: 'Montserrat', sans-serif;
	font-weight: 700;
	border-radius: 30px;
	align-items: center;
	justify-content: center;
	font-size: 17px;
	color:#fff;
}
.htw .uyeol-btn:hover{
	text-decoration: none;
}
.login-btn:hover{
	text-decoration: none;
	color:white;
	background: #ffcc00!important; /* Old browsers */
    background: -moz-linear-gradient(left, #ffcc00 0%, #ff8d00 100%)!important; /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #ffcc00 0%,#ff8d00 100%)!important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(-137deg, #ffcc00 0%,#ff8d00 100%)!important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffcc00', endColorstr='#ff8d00',GradientType=1 ); /* IE6-9 */
	border:none;
}
.register-btn{
	border:3px solid #ffb700;
	width: 164px;
	height: 45px;
	border-radius: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	
	font-size: 18px;
	font-weight: 700;
	margin-left: 30px;
	color:#ffb700;
}
.register-btn:hover{
	text-decoration: none;
	color:white;
	background: #ffcc00!important; /* Old browsers */
    background: -moz-linear-gradient(left, #ffcc00 0%, #ff8d00 100%)!important; /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #ffcc00 0%,#ff8d00 100%)!important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(-137deg, #ffcc00 0%,#ff8d00 100%)!important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffcc00', endColorstr='#ff8d00',GradientType=1 ); /* IE6-9 */
	border:none;
}

.mobile-nav-toggler{
	position: absolute;
    right: 30px;
}

.map-outer{
	position: relative;
	width: 100%;
	margin-top: 109px;
    z-index: 2;
}
 .shadow{
	background-image: url(../img/contact-shadow.png);
	width: 100%;
	height: 417px;
	position: absolute;
	top: 0;
	z-index: 99;
}

.contact-outer{
	padding-bottom: 80px;
	padding-top: 70px;
}
.info{
	margin-bottom: 50px;
}
.info .title{
	
	font-size: 25px;
	color:#483d60;
	margin-bottom: 15px;
}
.info .subtitle{
	
	font-size: 14px;
	color:#878294;
}
.info ul{
	list-style-type: none;
	margin: 0;
	padding: 0;
}
.info ul li{
	margin-bottom: 15px;
	display: flex;
	align-items: center;
}
.info ul li img{
	margin-right: 7px;
}
.info ul li span{
	
	font-size: 14px;
	color:#483e60;
}

.form .title{
	
	font-size: 25px;
	color:#483d60;
	margin-bottom: 15px;
}
.form .input-group {
	margin-bottom: 15px;
}
.form .input-group .input-group-prepend{
	display: flex;
    align-items: center;
    border:1px solid #e0e3e5;
    border-right: 0;
    -webkit-border-top-left-radius: 5px;
	-webkit-border-bottom-left-radius: 5px;
	-moz-border-radius-topleft: 5px;
	-moz-border-radius-bottomleft: 5px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
    height: 35px;
    padding-left: 10px;
    padding-right: 10px;
}
.form .input-group .input-group-prepend.f{
	height: 120px;
	align-items: flex-start;
	padding-top: 10px;
}
.form .input-group input,textarea{
	height: 35px;
	border:1px solid #e0e3e5;
    border-left: 0;

     -webkit-border-top-left-radius: 0px;
	-webkit-border-bottom-left-radius: 0px;
	-moz-border-radius-topleft: 0px;
	-moz-border-radius-bottomleft: 0px;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;

    -webkit-border-top-right-radius: 5px;
	-webkit-border-bottom-right-radius: 5px;
	-moz-border-radius-topright: 5px;
	-moz-border-radius-bottomright: 5px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;

	
	font-weight: 400;
	font-size: 15px;
	padding-top: 10px;
	color:#929699;
}
.form .input-group input:focus{
	box-shadow: none;
	outline: none;
}
.form .input-group textarea:focus{
	box-shadow: none;
	outline: none;
}
.form .input-group textarea{
	height: 120px;
	width: 100%;
	resize: none;
}

.form .send-btn{
	width: 120px;
	height: 50px;
	display: flex;
	font-family: 'Montserrat', sans-serif;
	font-weight: 700;
	border-radius: 30px;
	align-items: center;
	justify-content: center;
	font-size: 17px;
	color:#fff;
}
.form .send-btn:hover{
	text-decoration: none;
}
.form .form-footer{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.form .form-footer .success-message{
	display: flex;
	align-items: center;
	opacity: 0;
}
.form .form-footer .success-message img{
	margin-right: 5px;
}
.form .form-footer .success-message p{
	margin-bottom: 0;
	line-height: 20px;
	
	font-weight: 500;
	color:#32ba7c;
	font-size: 14px;
}

.mobile-header{
	display: none;
	height: 82px;
	position: relative;
	z-index: 9999;
	width: 100vw;
	top: 0;
}
.mobile-header .scroll-m {
	display: none;
	justify-content: space-between;
	align-items: center;
	width:100%;
	padding-left: 35px;
	padding-right: 35px;

}
.zero-m{
	display: flex;
	justify-content: center;
	align-items: center;
	width:100%;
}

.scroll-mh{
	background: white;
	    -webkit-box-shadow: 0px 10px 17px -8px rgba(0,0,0,0.2);
-moz-box-shadow: 0px 10px 17px -8px rgba(0,0,0,0.2);
box-shadow: 0px 10px 17px -8px rgba(0,0,0,0.2);
}
.mobile-header .scroll-m .nav-link{
	
	border:3px solid #ffc400;
	padding-right: 35px!important;
    padding-left: 35px!important;
    -webkit-border-radius: 50px!important;
	-moz-border-radius: 50px!important;
	border-radius: 50px!important;
	height: 43px;
	font-family: 'Montserrat', sans-serif;
	font-weight: 700;
	font-size: 16px;
}
.mobile-header .scroll-m .nav-itemt{
	position: inherit;
}
.mobile-header .scroll-m .logo{
	position:inherit;
}
.mobile-header .scroll-m .mobile-logo{
	box-shadow: none!important;
}
.mobile-header .logo{
	position: absolute;
    top: 38px;
    z-index: 2;
}
.mobile-header .nav-itemt{
	position: absolute;
	right: 30px;
}


.nav-itemt .dropdown-toggle:after{
	content:none;
}
.nav-itemt .dropdown-menu{
	left:auto!important;
	right:0!important;
	top: 20px!important;
	border:0;
	background-color: #f8f9fd;
	text-align: right;
	-webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;

    max-height: 0;
    display: block;
    overflow: hidden;
    opacity: 0;
}
.nav-itemt .dropdown-menu ul{
	list-style-type: none;
	margin: 0;
	padding: 0;
	padding-right: 20px;
}
.nav-itemt .dropdown-menu ul li a{
	color: #847f92;
	
	font-size: 16px;
}
.nav-itemt .dropdown-menu.show{
	max-height: 300px;
    opacity: 1;
}
.nav-itemt .dropdown-menu li{
	margin-bottom: 10px;
}

.nav-itemt .dropdown-menu a:hover{
	text-decoration: none;
}

@media only screen and (max-width : 1200px) {

}

@media only screen and (max-width : 1024px) {

}


@media only screen and (max-width : 992px) {

}


@media only screen and (max-width : 768px) {
	.mobile-header{
		display: flex;
	}
}


@media only screen and (max-width : 480px) {
	
}


@media only screen and (max-width : 320px) {

}

.navigation{
	display: flex;align-items: center;
}
.login-btn{
	border:3px solid #ffb700;
	width: 164px;
	height: 45px;
	border-radius: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	font-weight: 700;
	color:#ffb700;
	margin-right: 40px;
}
.htw .uyeol-btn{
	margin: 0 auto;
	margin-top: 40px;
	width: 270px;
	height: 50px;
	display: flex;
	font-weight: 700;
	border-radius: 30px;
	align-items: center;
	justify-content: center;
	font-size: 17px;
	color:#fff;
}
.htw .uyeol-btn:hover{
	text-decoration: none;
}
.login-btn:hover{
	text-decoration: none;
	color:white;
	background: #ffcc00!important; /* Old browsers */
    background: -moz-linear-gradient(left, #ffcc00 0%, #ff8d00 100%)!important; /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #ffcc00 0%,#ff8d00 100%)!important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(-137deg, #ffcc00 0%,#ff8d00 100%)!important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffcc00', endColorstr='#ff8d00',GradientType=1 ); /* IE6-9 */
	border:none;
}
.register-btn{
	border:3px solid #ffb700;
	width: 164px;
	height: 45px;
	border-radius: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	font-weight: 700;
	margin-left: 30px;
	color:#ffb700;
}
.register-btn:hover{
	text-decoration: none;
	color:white;
	background: #ffcc00!important; /* Old browsers */
    background: -moz-linear-gradient(left, #ffcc00 0%, #ff8d00 100%)!important; /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #ffcc00 0%,#ff8d00 100%)!important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(-137deg, #ffcc00 0%,#ff8d00 100%)!important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffcc00', endColorstr='#ff8d00',GradientType=1 ); /* IE6-9 */
	border:none;
}

.content{
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	margin-top: 70px;
}

.content .title{
	font-weight: 700;
	font-size: 30px;
	color:#42375b;
	margin-bottom: 20px;
}
.content .text{
	font-weight: 400;
	font-size: 14px;
	color:#808b96;
}
.assocs {
	margin-top: 80px;
	margin-bottom: 100px;
}
.assocs .assoc{
	display: flex;
    align-items: center;
    border-bottom: 3px solid #f1f0f3;
    border-right: 3px solid #f1f0f3;
    padding:30px;
}
.assocs .assoc.no-border{
	border-right: 0;
}
.assocs .assoc .logo{
	width: 30vw;
	padding:20px;
	padding-left: 0;
	border-right: 1px solid #f1f0f3;
	margin-right: 20px;
}
.assocs .assoc .logo img{
	width: 100%;
	height: auto;
}
.assocs .assoc .info{
	width: 70vw;
}
.assocs .assoc .info h2{
	font-weight: 700;
	font-size: 16px;
	color:#42375b;
}
.assocs .assoc .info p{
	font-weight: 400;
	font-size: 13px;
	color:#808b96;
	margin-top: 20px;
	height: 150px;
}
.assocs .assoc .info .site-btn{
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	font-weight: 700;
	font-size: 15px;
	color:#42375b;
	cursor:pointer;
}
.assocs .assoc .info .site-btn:hover{
	text-decoration: none;
}
.mobile-nav-toggler{
	position: absolute;
    right: 30px;
}

.assoc_cover{
	width: 100%;
	height: 597px;
	background-size:cover;
	display: flex;
    align-items: center;
    justify-content: flex-start;
    background-position: left center;
    padding-left: 10.5%;
}
.assoc_cover.mobile{
	display: none;
}
.assoc_cover .cover_content{
	display: flex;
	flex-direction: column;
}
.assoc_cover .cover_content .short_name{
	font-weight: 700;
	color:white;
	font-size: 43px;
	line-height: 45px;
}
.assoc_cover .cover_content .name{
	font-weight: 500;
	color:white;
	font-size: 41px;
	line-height: 45px;
}
.assoc_cover .cover_content .desc{
	font-weight: 400;
	color:white;
	font-size: 16px;
	margin-top: 30px;
	margin-bottom: 30px;
}
.htw{
	width: 100%;
	padding-top: 80px;
	text-align: center;
	padding-bottom: 60px;
}
.htw .row{
	margin-top: 50px;
}
.htw .title{
	color:#42375b;
	font-weight: 700;
	font-size: 30px;
	text-align: center;
	line-height: 42px;
}
.htw .title span{
	font-size: 35px;
	font-weight: 400;
}
.htw .step{
	text-align: center;
}
.htw .step .s-title{
	font-weight: 600;
	font-size: 18px;
	color:#42375b;
	margin-bottom: 0;
	margin-top: 40px;
}
.htw .step .desc{
	font-weight: 300;
	font-size: 16px;
	color:#808b96;
	margin-top: 15px;
}
.htw .uyeol-btn{
	margin-top: 40px;
}

.testimonials{
	background-color:#42375b;
	height: 630px;
	padding-top: 40px;
}
.testimonials .title{
	font-weight: 700;
	color:white;
	font-size:30px;
	text-align: center;
}
.testimonials .subtitle{
	text-align: center;
	font-weight: 300;
	color:white;
	font-size:16px;
}
.testimonials .comments{
	margin-top: 70px;
}
.testimonials .comments .comment .inner{
	width: 100%;
	height: 365px;
	border:2px solid #ffffff;
	border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-left: 23px;
    padding-right: 23px;
    text-align: center;
}
.testimonials .comments .comment .inner .message{
	font-size: 16px;
	color:white;
	font-weight: 400;
}
.testimonials .comments .comment .inner .name{
	font-size: 16px;
	color:white;
	font-weight: 700;
}
.brands{
	width: 100%;
	height: 306px;
	background-image:url(../img/assoc-brands-bg.jpg);
	background-size: cover;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.brands .title{
	font-size: 30px;
	color:#42375b;
	font-weight: 700;
}
.brands .subtitle{
	font-size: 30px;
	color:#42375b;
	font-weight: 400;
}
.brands .site-btn{
	width: 215px;
	height: 49px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	font-weight: 700;
	font-size: 15px;
	color: white;
	cursor:pointer;
}
.brands .site-btn:hover{
	text-decoration: none;
}


.mobile-header{
	display: none;
	height: 82px;
	position: relative;
	z-index: 9999;
	width: 100vw;
	top: 0;
}
.mobile-header .scroll-m {
	display: none;
	justify-content: space-between;
	align-items: center;
	width:100%;
	padding-left: 35px;
	padding-right: 35px;

}
.zero-m{
	display: flex;
	justify-content: center;
	align-items: center;
	width:100%;
}

.scroll-mh{
	background: white;
	    -webkit-box-shadow: 0px 10px 17px -8px rgba(0,0,0,0.2);
-moz-box-shadow: 0px 10px 17px -8px rgba(0,0,0,0.2);
box-shadow: 0px 10px 17px -8px rgba(0,0,0,0.2);
}
.mobile-header .scroll-m .nav-link{

	border:3px solid #ffc400;
	padding-right: 35px!important;
    padding-left: 35px!important;
    -webkit-border-radius: 50px!important;
	-moz-border-radius: 50px!important;
	border-radius: 50px!important;
	height: 43px;
	font-weight: 700;
	font-size: 16px;
}
.mobile-header .scroll-m .nav-itemt{
	position: inherit;
}
.mobile-header .scroll-m .logo{
	position:inherit;
}
.mobile-header .scroll-m .mobile-logo{
	box-shadow: none!important;
}
.mobile-header .logo{
	position: absolute;
    top: 38px;
    z-index: 2;
}
.mobile-header .nav-itemt{
	position: absolute;
	right: 30px;
}

.nav-itemt .dropdown-toggle:after{
	content:none;
}
.nav-itemt .dropdown-menu{
	left:auto!important;
	right:0!important;
	top: 20px!important;
	border:0;
	background-color: #f8f9fd;
	text-align: right;
	-webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;

    max-height: 0;
    display: block;
    overflow: hidden;
    opacity: 0;
}
.nav-itemt .dropdown-menu ul{
	list-style-type: none;
	margin: 0;
	padding: 0;
	padding-right: 20px;
}
.nav-itemt .dropdown-menu ul li a{
	color: #847f92;
	font-size: 16px;
}
.nav-itemt .dropdown-menu.show{
	max-height: 300px;
    opacity: 1;
}
.nav-itemt .dropdown-menu li{
	margin-bottom: 10px;
}

.nav-itemt .dropdown-menu a:hover{
	text-decoration: none;
}

@media only screen and (max-width : 1200px) {
	.assoc_cover{
		background-position: center center;
	}
}

@media only screen and (max-width : 1024px) {

}


@media only screen and (max-width : 992px) {

}


@media only screen and (max-width : 768px) {
	.assoc_cover{
		margin-top: 0px;
	}
	.testimonials .comments .comment .inner{
		margin-bottom: 30px;
	}
	.testimonials{
		padding-bottom: 40px;
		height: auto;
	}
	.mobile-header{
		display: flex;
	}
}


@media only screen and (max-width : 480px) {
	.content .title{
		font-size: 22px;
	}
	.content .text{
		font-size: 13px;
	}
	.assocs .assoc .info span{
		font-size: 15px;
	}
	.assocs .assoc .info p{
		font-size: 13px;
	}
	.assoc_cover.desktop{
		display: none;
	}
	.assoc_cover.mobile{
		display: flex;
		height: 490px;
		background-repeat: no-repeat;
		margin-top: 0;
		padding-left: 0;
		align-items: flex-end;
	}
	.assoc_cover.mobile .cover_content{
		margin-top: 0;
	}
	.assoc_cover .cover_content .short_name{
		font-size: 25px;
		    text-shadow: 1.414px 1.414px 6.02px rgba(8, 0, 64, 0.5);

	}
	.assoc_cover .cover_content .name{
		font-size: 18px;
		text-shadow: 1.414px 1.414px 6.02px rgba(8, 0, 64, 0.5);
		line-height: 20px;

	}
	.assoc_cover .cover_content .desc{
		font-size: 13px;
		    text-shadow: 1.414px 1.414px 6.02px rgba(8, 0, 64, 0.5);
		    font-weight: 600;

	}
	.brands .subtitle{
		font-size: 18px;
		text-align: center;
	}
	.brands{
		padding-left: 20px;
		padding-right: 20px;
	}
	.assocs .assoc .info p{
		overflow: hidden;
	}
	.assocs .assoc{
		border:0;
	}
}


@media only screen and (max-width : 320px) {

}
.sss{

	margin-bottom: 90px;
}
.sss .title{
	margin-bottom: 30px;
	font-weight: 700;
	font-size: 26px;
	color: #fcb50e;
}
.sss .card{
	border:0;
	background-color: #f7f7f7;
	border-radius: 10px;
	margin-bottom: 10px;
}
.sss .card .card-header{
	border:0;
	background-color: #f7f7f7;
	border-radius: 10px;
}
.sss .card .card-header h2{
	    align-items: center;
    display: flex;

}
.sss .card .card-header h2 button{
	font-weight: 500;
	color:#847f92;
	font-size: 17px;
	padding-left: 0;
	border: 0;
	border-bottom: 1px solid #e7e7e7;
	text-align: left;
	width: 100%;
	text-align: left;
	padding-bottom: 10px;
	text-decoration: none!important;
}
.sss .card .card-header h2 button.collapsed{
	border: 0;
	padding-bottom: 0;
}
.sss .card .card-header h2 button:hover{
	text-decoration: none;
}
.sss .card .card-header h2 button:focus{
	outline: none!important;
}
.sss .card .card-body{
	font-weight: 400;
	color:#847f92;
	font-size: 14px;
	padding-top: 0;
	padding-left: 33px;
	padding-right: 33px;
}
.content{

}
.content .title{
	margin-bottom: 30px;
	font-weight: 700;
	font-size: 26px;
	color: #fcb50e;
}
.content p{
	font-weight: 400;
	margin-bottom: 27px;
	color:#847f92;
	font-size: 15px;
}
