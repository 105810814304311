.modal {
	.modal-dialog{
		.modal-content{
			border: 0;
			border-radius: 0;
			.modal-header{
				background: #f3f3f3;
				border-bottom: 1px solid #f3f3f3;
				h5{

				}
				.close{

				}
			}
			.modal-body{

			}
			.modal-footer{
				//background: $defaultTitleColor;
			}
		}
	}
}


.iyilik-butonu-tutorial {
	position: fixed;
	right: 1rem;
	top: 1rem;
	border-radius:20px ;
	box-shadow: 0 3px 15px rgba(73, 80, 87, 10%);
	z-index: 99;
	width: 100%;
	max-width: 300px;
	background-color: #fff;
	padding: 25px;
	overflow: hidden;

	&::after {
		content: "";
		position: absolute;
		left: -70px;
		top: -70px;
		width: 150px;
		height: 150px;
		z-index: 9999999;
		background-repeat: no-repeat;
		background-image: url(https://iyilikkazansin.com/assets/site/img/path_1.png);
		background-size: cover;
	}


	.iyilik-butonu-tutorial-wrap {

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
		.btn-close {
			position: absolute;
			right: 1rem;
			top: 1rem;
			background: transparent;
			border: none;
			color: #ccc;
			i {
				font-weight: 300;
			}
			&:hover{
				color: #8a8a8a;
			}
		}
		.icon-logo {
			font-size: 34px;
			margin-bottom: 1rem;
		}
		strong {
			color: #ff015b;
			font-size: 16px;
			margin-bottom: .5rem;
			font-weight: 500;

		}
		p {
			text-align: center;
			font-size: 14px;
			margin-bottom: 1rem;
		}
		img {
			margin-bottom: .5rem;
			&.questionMark {
				margin:0;
			}
		}
	}

}

.fade-down {
	animation: fade-down .8s ease-in-out 2s both;

}
.fade-up {
	animation: fade-up .8s ease-in-out both;

}

@-webkit-keyframes fade-down {
	0% {
	  transform: translateY(-600px);
	}
	100% {
		transform: translateY(0);
	}
     }
    
     
     @-webkit-keyframes fade-up {
	0% {
	  transform: translateY(0);
	}
	100% {
		transform: translateY(-600px);
	}
     }
    
     
