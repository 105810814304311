$darkblue: #213557;
$darkyellow: #b19959;

$pfizer: #0088ce;
$borusan: #f26727;
$hummel:#000000;
.anadoluefesgonulluleri {
  &.hummel {
    section {
      color:$hummel;
      .section-header {
        border-bottom:2px solid $hummel;
      }
    }
    footer {
      background: $hummel;
    }
  }
  header {
    top: 50px;
    z-index: 3;
    .logo {
      a {
        img {
          width: auto;
          height: 100%;
          left: 0;
          transform: translate(0, -50%);
        }
      }
    }
    p {
      color: $white;
      margin-bottom: 0;
      margin-top: 7px;
      display: inline-block;
      font-size: 20px;
    }
  }
  .section {
    &-header {
      border-bottom: 2px solid #213557;
      text-align: left;
      height: 32px;
      margin-bottom: 25px;
      &:before {
        display: none;
      }
      .title {
        text-align: left;
        width: auto;
        background: #fff;
        margin-bottom: -5px;
        display: inline-block;
        padding: 0px 10px 0 0;
        font-weight: 400;
      }
    }
    &-body {
      .section-img {
        height: 400px;
        position: relative;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 69%;
        }
      }
      .section-detail {
        height: 400px;
        padding: 116px 120px;
        color: $white;
        background-size: 100%;
        .desc {
          color: $white;
        }
      }
      .sec-title {
        color: $darkyellow;
      }
      .companies-logos {
        .company-logo {
          height: 150px;
          width: 100%;
          position: relative;
          transition: 0.5s ease all;
          margin-bottom: 15px;
          img {
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          &:hover {
            opacity: 1;
          }
        }
      }
    }
    &-main {
      display: inline-block;
      padding: 0;
      .section-bg {
        img {
          width: 100%;
        }
      }
      .main-inner {
        position: absolute;
        top: 0%;
        width: 100%;
        height: 100%;
        .main-detail {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0%;
          .title {
            color: $white;
            font-weight: 400;
            font-size: 4vh;
          }
          .desc {
            color: $white;
          }
        }
        img {
          width: 245px;
          position: absolute;
          right: 0;
          bottom: 76px;
        }
      }
      .main-shape {
        position: absolute;
        bottom: -142px;
        height: 367px;
        width: 100%;
        background-size: auto 100%;
        left: 0;
        right: 0;
        background-repeat: no-repeat;
      }
    }
    &-platform {
      .title {
        color: $darkyellow;
        margin-top: -92px;
      }
      img {
        margin-right: -20px;
        float: right;
        width: 136%;
      }
    }
  }

  .btn {
    &.btn-black {
      background: $hummel;
      color: #fff;
    }
    padding: 10px 15px;
    &-outline {
      &-white {
        color: $white;
        border: 1px solid $white;
        margin-left: 15px;
        &:hover {
          background: $white;
          color: $darkblue;
        }
      }
    }
    &-darkblue {
      background: $darkblue;
      color: $darkyellow;
      box-shadow: 4px 7px 26px 0 rgba($darkblue, 0.27);
    }
  }
  footer {
    background: #002e79;
    .footer-box {
      .footer-logo {
        a {
          i {
            color: $white;
          }
        }
      }
      p,
      span {
        margin: 0;
        color: $white;
      }
      a {
        color: $white;
      }
    }
  }

  &.hummel {
    section {
      p {
        color:$hummel;

      }
    }
     .section-platform {
      .title {
        color:$hummel;
      }
     } 
  }


}

.akenerji {
  .section-platform {
    .title {
      color: #203e7b;
    }
  }
  .btn {
    &-darkblue {
      color: $white;
      background-color: #203e7b;
    }
  }
  .section-header {
    border-color: 2px solid #203e7b;
    .title {
      color: #203e7b;
    }
  }
  .section-body {
    .section-detail {
      background-size: auto 100%;
      background-position: center;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        background-color: rgba($color: #e90e8c, $alpha: 0.8);
        height: 100%;
        top: 0%;
        left: 0;
      }
      .title {
        position: relative;
        z-index: 1;
      }
      .desc {
        position: relative;
        z-index: 1;
      }
    }
    .section-img {
      img {
        width: 100%;
      }
    }
  }
}

.pfizer {
  .section-platform {
    .title {
      color: $pfizer;
    }
  }
  .btn {
    &-darkblue {
      color: $white;
      background-color: $pfizer;
    }
  }
  .section-header {
    border-color: 2px solid $pfizer;
    .title {
      color: $pfizer;
    }
  }
  .section-body {
    .section-detail {
      background-size: auto 100%;
      background-position: center;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        background-color: rgba($color: #535351, $alpha: 0.8);
        height: 100%;
        top: 0%;
        left: 0;
      }
      .title {
        position: relative;
        z-index: 1;
      }
      .desc {
        position: relative;
        z-index: 1;
      }
    }
    .section-img {
      img {
        width: 100%;
      }
    }
  }
}
.borusan {
  .section-platform {
    .title {
      color: $borusan;
    }
  }
  .btn {
    &-darkblue {
      color: $white;
      background-color: $borusan;
    }
  }
  .section-header {
    border-color: 2px solid $borusan;
    .title {
      color: $borusan;
    }
  }
  .section-body {
    .section-detail {
      &:before {
        background-color: rgba($color: #094ea3, $alpha: 0.8);
      }
    }
    .section-img {
      img {
        width: 80%;
      }
    }
  }
}

.kale {
	p{
		color: #000;
	}
	&.hummel {
	  section {
		color:$hummel;
		.section-header {
		  border-bottom:2px solid $hummel;
		}
	  }
	  footer {
		background: $hummel;
	  }
	}
	header {
	  top: 50px;
	  z-index: 3;
	  .logo {
		a {
			height: 73px;
			margin-top: -27px;
		  img {
			width: 134px;
			left: 0;
			transform: translateY(-54%);
			max-width: inherit;
			max-height: inherit;
		  }
		}
	  }
	  p {
		color: $white;
		margin-bottom: 0;
		margin-top: 7px;
		display: inline-block;
		font-size: 20px;
	  }
	}
	.section {
	  &-header {
		border-bottom: 2px solid #04252e;
		text-align: left;
		height: 32px;
		margin-bottom: 25px;
		&:before {
		  display: none;
		}
		.title {
		  text-align: left;
		  width: auto;
		  background: #fff;
		  margin-bottom: -5px;
		  display: inline-block;
		  padding: 0px 10px 0 0;
		  font-weight: 400;
		  color: #04252e;
		}
	  }
	  &-body {
		.section-img {
		  height: 400px;
		  position: relative;
		  img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 69%;
		  }
		}
		.section-detail {
		  height: 400px;
		  padding: 116px 120px;
		  color: $white;
		  background-size: 100%;
		  .desc {
			color: $white;
		  }
		}
		.sec-title {
		  color: $darkyellow;
		}
		.companies-logos {
		  .company-logo {
			height: 150px;
			width: 100%;
			position: relative;
			transition: 0.5s ease all;
			margin-bottom: 15px;
			img {
			  max-width: 100%;
			  max-height: 100%;
			  position: absolute;
			  top: 50%;
			  left: 50%;
			  transform: translate(-50%, -50%);
			}
			&:hover {
			  opacity: 1;
			}
		  }
		}
	  }
	  &-main {
		display: inline-block;
		padding: 0;
		.section-bg {
		  img {
			width: 100%;
		  }
		}
		.main-inner {
		  position: absolute;
		  top: 0%;
		  width: 100%;
		  height: 100%;
		  .main-detail {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0%;
			.title {
			  color: $white;
			  font-weight: 400;
			  font-size: 4vh;
			}
			.desc {
			  color: $white;
			}
		  }
		  img {
			width: 245px;
			position: absolute;
			right: 0;
			bottom: 76px;
		  }
		}
		.main-shape {
		  position: absolute;
		  bottom: -142px;
		  height: 367px;
		  width: 100%;
		  background-size: auto 100%;
		  left: 0;
		  right: 0;
		  background-repeat: no-repeat;
		}
	  }
	  &-platform {
		.title {
		  color: #ed4636;
		  margin-top: -92px;
		}
		img {
		  margin-right: -20px;
		  float: right;
		  width: 136%;
		}
	  }
	}
  
	.btn {
	  &.btn-black {
		background: $hummel;
		color: #fff;
	  }
	  padding: 10px 15px;
	  &-outline {
		&-white {
		  color: $white;
		  border: 1px solid $white;
		  margin-left: 15px;
		  &:hover {
			background: $white;
			color: $darkblue;
		  }
		}
	  }
	  &-darkblue {
		background: #ed4636;
		color: #FFF;	
		box-shadow: 4px 7px 26px 0 rgba($darkblue, 0.27);
	  }
	}
	footer {
	  background: #04252e;
	  .footer-box {
		  .title{
			color: #ed4636;
		  }
		.footer-logo {
		  a {
			i {
			  color: $white;
			}
		  }
		}
		p,
		span {
		  margin: 0;
		  color: $white;
		}
		a {
		  color: $white;
		}
	  }
	}
  
	&.hummel {
	  section {
		p {
		  color:$hummel;
  
		}
	  }
	   .section-platform {
		.title {
		  color:$hummel;
		}
	   } 
	}
  
  
  }

@media only screen and (max-width: 992px) {
  .anadoluefesgonulluleri {
    header {
      top: 0;
      background: transparent;
      position: absolute;
      .logo {
        width: 113px !important;
        img {
          width: 100%;
        }
      }
    }
    .section {
      padding: 67px 0;
      &-header {
        &:before {
        }
        .title {
        }
      }
      &-body {
        .section-img {
          img {
          }
        }
        .section-detail {
          height: auto;
          background-size: auto 100%;
          padding: 15px;
          .desc {
          }
        }
        .sec-title {
        }
        .companies-logos {
          .company-logo {
            img {
            }
            &:hover {
            }
          }
        }
      }
      &-main {
        padding: 0 !important;
        .section-bg {
          height: 400px;
          overflow: hidden;

          img {
            width: auto;
            height: 100%;
          }
        }
        .main-inner {
          .main-detail {
            padding: 0 15px;
            .title {
            }
          }
        }
        .main-shape {
        }
      }
      &-platform {
        .title {
          margin-top: -47px;
        }
        img {
        }
      }
    }

    .btn {
      &-outline {
        &-white {
          &:hover {
          }
        }
      }
      &-darkblue {
      }
    }
    footer {
      p {
      }
      a {
      }
    }
  }
  .kale {
    header {
      top: 0;
      background: transparent;
      position: absolute;
      .logo {
		width: 113px !important;
		a{
			margin-top: -12px;
		}
        img {
          width: 100% !important;
        }
      }
    }
    .section {
      padding: 67px 0;
      &-header {
        &:before {
        }
        .title {
        }
      }
      &-body {
        .section-img {
          img {
          }
        }
        .section-detail {
          height: auto;
          background-size: auto 100%;
          padding: 15px;
          .desc {
          }
        }
        .sec-title {
        }
        .companies-logos {
          .company-logo {
            img {
            }
            &:hover {
            }
          }
        }
      }
      &-main {
        padding: 0 !important;
        .section-bg {
          height: 400px;
          overflow: hidden;

          img {
            width: auto;
            height: 100%;
          }
        }
        .main-inner {
          .main-detail {
            padding: 0 15px;
            .title {
            }
          }
        }
        .main-shape {
        }
      }
      &-platform {
        .title {
          margin-top: -47px;
        }
        img {
        }
      }
    }

    .btn {
      &-outline {
        &-white {
          &:hover {
          }
        }
      }
      &-darkblue {
      }
    }
    footer {
      p {
      }
      a {
      }
    }
  }
  .pfizer {
    header {
      top: 0;
      background: transparent;
      position: absolute;
      .logo {
        width: 113px !important;
        img {
          width: 100%;
        }
      }
    }
    .section {
      padding: 67px 0;
      &-header {
        &:before {
        }
        .title {
        }
      }
      &-body {
        .section-img {
          img {
          }
        }
        .section-detail {
          height: auto;
          background-size: auto 100%;
          padding: 15px;
          .desc {
          }
        }
        .sec-title {
        }
        .companies-logos {
          .company-logo {
            img {
            }
            &:hover {
            }
          }
        }
      }
      &-main {
        padding: 0 !important;
        .section-bg {
          height: 303px;
          overflow: hidden;

          img {
            width: 181%;
            height: auto;
            position: absolute;
            right: 0;
          }
        }
        .main-inner {
          .main-detail {
            padding: 0 15px;
            .title {
            }
          }
        }
        .main-shape {
        }
      }
      &-platform {
        .title {
          margin-top: -47px;
        }
        img {
        }
      }
    }

    .btn {
      &-outline {
        &-white {
          &:hover {
          }
        }
      }
      &-darkblue {
      }
    }
    footer {
      p {
      }
      a {
      }
    }
  }

  .pfizer header p {
    color: #fff;
    margin-bottom: 0;
    margin-top: 7px;
    display: inline-block;
    font-size: 20px;
    position: absolute;
    top: 62px;
    left: 15px;
  }
}
