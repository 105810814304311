form {
    input {
        padding-left: 30px;
        border-radius: 0 !important;
        background-repeat: no-repeat;
        background-size: 20px 20px;
		background-position: 5px center;
        &:focus {
            outline: none !important;
			box-shadow: none !important;
			border-color: $defaultTitleColor !important;
        }
        &.disable-bg {
            background-size: 0 0;
            background-position: 0 0;
            padding-left: 3px;
        }
        &[type="text"], &[type="password"], &[type="email"],  &[type="tel"]  {
            border: none;
            border: 1px solid #C7C7C7;
            font-size: 14px;
            font-weight: 300;
			color: $dark;
			height: 50px;
			
            &::placeholder {
                font-size: 14px;
                font-weight: 300;
                color: #C7C7C7;
            }
            &[readonly] {
                background-color: transparent;
            }
        }
    }
    .form-row {
        width: 100%;
        justify-content: space-between;
        margin: 0;
        margin-bottom: 10px;
        .left, .right {
            width: 48.5%;
            &.right {
                margin-left: 3%;
            }
        }
        &.three-row {
            @media ( max-width: 767px ) {
                .left, .center, .right {
                    width: 100% !important;
                    &.center, &.right {
                        margin-left: 0 !important;
                        margin-top: 15px;
                    }
                    &.right {
                        margin-bottom: 5px;
                    }
                }
            }
            .left, .center, .right {
                width: 31.3%;
                &.center, &.right {
                    margin-left: 2%;
                }
            }
        }
    }
    button {
    }
    textarea {
        border: none;
        border: 1px solid #C7C7C7;
        padding-left: 30px;
        border-radius: 0;
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-position: 5px center;
        font-size: 14px;
        font-weight: 300;
        resize: none;
        &:focus, &:active, &:hover {
            border-color: #C7C7C7;
            outline: none;
            box-shadow: none;
        }
        &.disable-bg {
            background-size: 0 0;
            background-position: 0 0;
            padding-left: 3px;
        }
    }
    span {
        &.error-verify {
            font-size: 14px;
            font-weight: 300;
            color: $red;
        }
    }
}

input[type=radio] {
	display: none;
	&:checked + span {
	  &:after {
		opacity: 1;
	  }
	  &:before {
		background: $defaultTitleColor;
	  }
	}
	& + span {
	  cursor: pointer;
	  user-select: none;
	  display: inline-block;
	  position: relative;
	  padding-left: 20px;
	  vertical-align: middle;
	  &:after {
		transform: scale(0.5);
		content: '';
		display: block;
		opacity: 0;
		position: absolute;
		top: 50%;
		left: 5px;
		width: 5px;
		height: 5px;
		border-radius: 50%;
		background: #fff;
		box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, .2);
		transition: opacity ease-in 0.2s;
		transform: translateY(-50%);
	  }
	  &:before {
		content: '';
		display: block;
		position: absolute;
		top: 50%;
		left: 0;
		width: 15px;
		height: 15px;
		border-radius: 25px;
		background: $defaultButtonColor;
		transition: ease-in 0.15s;
		transform: translateY(-50%);
	  }
	}
  }

input[type="checkbox"] {
	display: none;
	&:checked + span {
		&:after {
			left: 25px;
		}
		&:before {
			background: $defaultTitleColor;
		}
	}
	& + span {
		cursor: pointer;
		user-select: none;
		display: inline-block;
		position: relative;
		padding-left: 65px;
		padding-top: 5px;
		vertical-align: middle;
		&:after {
			transform: scale(0.7);
			content: '';
			display: block;
			position: absolute;
			top: 0px;
			left: 0px;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			background: #fff;
			box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, .2);
			transition: ease-in 0.15s;
		}
		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0px;
			left: 0;
			width: 55px;
			height: 30px;
			border-radius: 25px;
			background: $defaultTextColor;
			box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, .3) inset;
			transition: ease-in 0.15s;
		}
	}
}
input[type="file"] {
	display: none;
	& + span {
		cursor: pointer;
		user-select: none;
		display: inline-block;
		position: relative;
		padding-left: 65px;
		padding-top: 5px;
		vertical-align: middle;
		background: $white;
		width: 100%;
		border-radius: 100px;
		padding: 10px 15px;
		color: $gray-700;
	}
}
.choices__inner{
	border-radius: 100px !important;
	background: $white !important;
	border: 0 !important;
}
  


.iyilik-butonu-login-form{
	position: relative;
z-index:5;
max-width:570px;
margin:3rem auto;
background-color:$white;
box-shadow:0px 0px 15px rgba($gray-700,.4);
border-radius:20px;

form {
	background-color:$white;
	position: relative;
	z-index:5;
	border-radius:20px;
	padding:35px 60px;

	.form-group {
		&::last-child {
			margin-bottom:0;
		}
		.form-control {
			border-radius:100px !important;
		}
		.text-muted {
			font-size:16px ;
		}
	}
}

	&::before {
		content: '';
		position: absolute;
		left: -50px;
		top: -50px;
		width: 150px;
		height: 150px;
		background-image: url(https://iyilikkazansin.com/assets/site/img/path_1.png);
		background-size: cover;
		z-index:-1;

	}
	&::after {
		content: '';
		position: absolute;
		right: -50px;
		bottom: -50px;
		width: 150px;
		height: 150px;
		background-image: url(http://127.0.0.1:8000/assets/site/img/path_2.png);
		background-size: cover;
		z-index:-1;

	}
}


@media only screen and (max-width:768px){
	.iyilik-butonu-login-form{
		form {
			padding: 20px;
		}
}
}