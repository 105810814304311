@import url('https://fonts.googleapis.com/css?family=Barlow:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap&subset=latin-ext,vietnamese');

$iyilikkazansin-font-family: "iyilikkazansin" !default;
$iyilikkazansin-font-path: "/assets/site/fonts" !default;

$icon-Bill-Drayton: "\e901";
$icon-logo: "\e900";
$icon-icon-login: "\e908";
$icon-icon-online-shop: "\e909";
$icon-icon-shopping-basket: "\e90a";
$icon-iyilikkazansin: "\e902";
$icon-asterisk: "\f069";
$icon-plus: "\f067";
$icon-question: "\f128";
$icon-minus: "\f068";
$icon-glass: "\f000";
$icon-music: "\f001";
$icon-search: "\f002";
$icon-envelope-o: "\f003";
$icon-heart: "\f004";
$icon-star: "\f005";
$icon-star-o: "\f006";
$icon-user: "\f007";
$icon-film: "\f008";
$icon-th-large: "\f009";
$icon-th: "\f00a";
$icon-th-list: "\f00b";
$icon-check: "\f00c";
$icon-close: "\f00d";
$icon-remove: "\f00d";
$icon-times: "\f00d";
$icon-search-plus: "\f00e";
$icon-search-minus: "\f010";
$icon-power-off: "\f011";
$icon-signal: "\f012";
$icon-cog: "\f013";
$icon-gear: "\f013";
$icon-trash-o: "\f014";
$icon-home: "\f015";
$icon-file-o: "\f016";
$icon-clock-o: "\f017";
$icon-road: "\f018";
$icon-download: "\f019";
$icon-arrow-circle-o-down: "\f01a";
$icon-arrow-circle-o-up: "\f01b";
$icon-inbox: "\f01c";
$icon-play-circle-o: "\f01d";
$icon-repeat: "\f01e";
$icon-rotate-right: "\f01e";
$icon-refresh: "\f021";
$icon-list-alt: "\f022";
$icon-lock: "\f023";
$icon-flag: "\f024";
$icon-headphones: "\f025";
$icon-volume-off: "\f026";
$icon-volume-down: "\f027";
$icon-volume-up: "\f028";
$icon-qrcode: "\f029";
$icon-barcode: "\f02a";
$icon-tag: "\f02b";
$icon-tags: "\f02c";
$icon-book: "\f02d";
$icon-bookmark: "\f02e";
$icon-print: "\f02f";
$icon-camera: "\f030";
$icon-font: "\f031";
$icon-bold: "\f032";
$icon-italic: "\f033";
$icon-text-height: "\f034";
$icon-text-width: "\f035";
$icon-align-left: "\f036";
$icon-align-center: "\f037";
$icon-align-right: "\f038";
$icon-align-justify: "\f039";
$icon-list: "\f03a";
$icon-dedent: "\f03b";
$icon-outdent: "\f03b";
$icon-indent: "\f03c";
$icon-video-camera: "\f03d";
$icon-image: "\f03e";
$icon-photo: "\f03e";
$icon-picture-o: "\f03e";
$icon-pencil: "\f040";
$icon-map-marker: "\f041";
$icon-adjust: "\f042";
$icon-tint: "\f043";
$icon-edit: "\f044";
$icon-pencil-square-o: "\f044";
$icon-share-square-o: "\f045";
$icon-check-square-o: "\f046";
$icon-arrows: "\f047";
$icon-step-backward: "\f048";
$icon-fast-backward: "\f049";
$icon-backward: "\f04a";
$icon-play: "\f04b";
$icon-pause: "\f04c";
$icon-stop: "\f04d";
$icon-forward: "\f04e";
$icon-fast-forward: "\f050";
$icon-step-forward: "\f051";
$icon-eject: "\f052";
$icon-chevron-left: "\f053";
$icon-chevron-right: "\f054";
$icon-plus-circle: "\f055";
$icon-minus-circle: "\f056";
$icon-times-circle: "\f057";
$icon-check-circle: "\f058";
$icon-question-circle: "\f059";
$icon-info-circle: "\f05a";
$icon-crosshairs: "\f05b";
$icon-times-circle-o: "\f05c";
$icon-check-circle-o: "\f05d";
$icon-ban: "\f05e";
$icon-arrow-left: "\f060";
$icon-arrow-right: "\f061";
$icon-arrow-up: "\f062";
$icon-arrow-down: "\f063";
$icon-mail-forward: "\f064";
$icon-share: "\f064";
$icon-expand: "\f065";
$icon-compress: "\f066";
$icon-exclamation-circle: "\f06a";
$icon-gift: "\f06b";
$icon-leaf: "\f06c";
$icon-fire: "\f06d";
$icon-eye: "\f06e";
$icon-eye-slash: "\f070";
$icon-exclamation-triangle: "\f071";
$icon-warning: "\f071";
$icon-plane: "\f072";
$icon-calendar: "\f073";
$icon-random: "\f074";
$icon-comment: "\f075";
$icon-magnet: "\f076";
$icon-chevron-up: "\f077";
$icon-chevron-down: "\f078";
$icon-retweet: "\f079";
$icon-shopping-cart: "\f07a";
$icon-folder: "\f07b";
$icon-folder-open: "\f07c";
$icon-arrows-v: "\f07d";
$icon-arrows-h: "\f07e";
$icon-bar-chart: "\f080";
$icon-bar-chart-o: "\f080";
$icon-twitter-square: "\f081";
$icon-facebook-square: "\f082";
$icon-camera-retro: "\f083";
$icon-key: "\f084";
$icon-cogs: "\f085";
$icon-gears: "\f085";
$icon-comments: "\f086";
$icon-thumbs-o-up: "\f087";
$icon-thumbs-o-down: "\f088";
$icon-star-half: "\f089";
$icon-heart-o: "\f08a";
$icon-sign-out: "\f08b";
$icon-linkedin-square: "\f08c";
$icon-thumb-tack: "\f08d";
$icon-external-link: "\f08e";
$icon-sign-in: "\f090";
$icon-trophy: "\f091";
$icon-github-square: "\f092";
$icon-upload: "\f093";
$icon-lemon-o: "\f094";
$icon-phone: "\f095";
$icon-square-o: "\f096";
$icon-bookmark-o: "\f097";
$icon-phone-square: "\f098";
$icon-twitter: "\f099";
$icon-facebook: "\f09a";
$icon-facebook-f: "\f09a";
$icon-github: "\f09b";
$icon-unlock: "\f09c";
$icon-credit-card: "\f09d";
$icon-feed: "\f09e";
$icon-rss: "\f09e";
$icon-hdd-o: "\f0a0";
$icon-bullhorn: "\f0a1";
$icon-bell-o: "\f0a2";
$icon-certificate: "\f0a3";
$icon-hand-o-right: "\f0a4";
$icon-hand-o-left: "\f0a5";
$icon-hand-o-up: "\f0a6";
$icon-hand-o-down: "\f0a7";
$icon-arrow-circle-left: "\f0a8";
$icon-arrow-circle-right: "\f0a9";
$icon-arrow-circle-up: "\f0aa";
$icon-arrow-circle-down: "\f0ab";
$icon-globe: "\f0ac";
$icon-wrench: "\f0ad";
$icon-tasks: "\f0ae";
$icon-filter: "\f0b0";
$icon-briefcase: "\f0b1";
$icon-arrows-alt: "\f0b2";
$icon-group: "\f0c0";
$icon-users: "\f0c0";
$icon-chain: "\f0c1";
$icon-link: "\f0c1";
$icon-cloud: "\f0c2";
$icon-flask: "\f0c3";
$icon-cut: "\f0c4";
$icon-scissors: "\f0c4";
$icon-copy: "\f0c5";
$icon-files-o: "\f0c5";
$icon-paperclip: "\f0c6";
$icon-floppy-o: "\f0c7";
$icon-save: "\f0c7";
$icon-square: "\f0c8";
$icon-bars: "\f0c9";
$icon-navicon: "\f0c9";
$icon-reorder: "\f0c9";
$icon-list-ul: "\f0ca";
$icon-list-ol: "\f0cb";
$icon-strikethrough: "\f0cc";
$icon-underline: "\f0cd";
$icon-table: "\f0ce";
$icon-magic: "\f0d0";
$icon-truck: "\f0d1";
$icon-pinterest: "\f0d2";
$icon-pinterest-square: "\f0d3";
$icon-google-plus-square: "\f0d4";
$icon-google-plus: "\f0d5";
$icon-money: "\f0d6";
$icon-caret-down: "\f0d7";
$icon-caret-up: "\f0d8";
$icon-caret-left: "\f0d9";
$icon-caret-right: "\f0da";
$icon-columns: "\f0db";
$icon-sort: "\f0dc";
$icon-unsorted: "\f0dc";
$icon-sort-desc: "\f0dd";
$icon-sort-down: "\f0dd";
$icon-sort-asc: "\f0de";
$icon-sort-up: "\f0de";
$icon-envelope: "\f0e0";
$icon-linkedin: "\f0e1";
$icon-rotate-left: "\f0e2";
$icon-undo: "\f0e2";
$icon-gavel: "\f0e3";
$icon-legal: "\f0e3";
$icon-dashboard: "\f0e4";
$icon-tachometer: "\f0e4";
$icon-comment-o: "\f0e5";
$icon-comments-o: "\f0e6";
$icon-bolt: "\f0e7";
$icon-flash: "\f0e7";
$icon-sitemap: "\f0e8";
$icon-umbrella: "\f0e9";
$icon-clipboard: "\f0ea";
$icon-paste: "\f0ea";
$icon-lightbulb-o: "\f0eb";
$icon-exchange: "\f0ec";
$icon-cloud-download: "\f0ed";
$icon-cloud-upload: "\f0ee";
$icon-user-md: "\f0f0";
$icon-stethoscope: "\f0f1";
$icon-suitcase: "\f0f2";
$icon-bell: "\f0f3";
$icon-coffee: "\f0f4";
$icon-cutlery: "\f0f5";
$icon-file-text-o: "\f0f6";
$icon-building-o: "\f0f7";
$icon-hospital-o: "\f0f8";
$icon-ambulance: "\f0f9";
$icon-medkit: "\f0fa";
$icon-fighter-jet: "\f0fb";
$icon-beer: "\f0fc";
$icon-h-square: "\f0fd";
$icon-plus-square: "\f0fe";
$icon-angle-double-left: "\f100";
$icon-angle-double-right: "\f101";
$icon-angle-double-up: "\f102";
$icon-angle-double-down: "\f103";
$icon-angle-left: "\f104";
$icon-angle-right: "\f105";
$icon-angle-up: "\f106";
$icon-angle-down: "\f107";
$icon-desktop: "\f108";
$icon-laptop: "\f109";
$icon-tablet: "\f10a";
$icon-mobile: "\f10b";
$icon-mobile-phone: "\f10b";
$icon-circle-o: "\f10c";
$icon-quote-left: "\f10d";
$icon-quote-right: "\f10e";
$icon-spinner: "\f110";
$icon-circle: "\f111";
$icon-mail-reply: "\f112";
$icon-reply: "\f112";
$icon-github-alt: "\f113";
$icon-folder-o: "\f114";
$icon-folder-open-o: "\f115";
$icon-smile-o: "\f118";
$icon-frown-o: "\f119";
$icon-meh-o: "\f11a";
$icon-gamepad: "\f11b";
$icon-keyboard-o: "\f11c";
$icon-flag-o: "\f11d";
$icon-flag-checkered: "\f11e";
$icon-terminal: "\f120";
$icon-code: "\f121";
$icon-mail-reply-all: "\f122";
$icon-reply-all: "\f122";
$icon-star-half-empty: "\f123";
$icon-star-half-full: "\f123";
$icon-star-half-o: "\f123";
$icon-location-arrow: "\f124";
$icon-crop: "\f125";
$icon-code-fork: "\f126";
$icon-chain-broken: "\f127";
$icon-unlink: "\f127";
$icon-info: "\f129";
$icon-exclamation: "\f12a";
$icon-superscript: "\f12b";
$icon-subscript: "\f12c";
$icon-eraser: "\f12d";
$icon-puzzle-piece: "\f12e";
$icon-microphone: "\f130";
$icon-microphone-slash: "\f131";
$icon-shield: "\f132";
$icon-calendar-o: "\f133";
$icon-fire-extinguisher: "\f134";
$icon-rocket: "\f135";
$icon-maxcdn: "\f136";
$icon-chevron-circle-left: "\f137";
$icon-chevron-circle-right: "\f138";
$icon-chevron-circle-up: "\f139";
$icon-chevron-circle-down: "\f13a";
$icon-html5: "\f13b";
$icon-css3: "\f13c";
$icon-anchor: "\f13d";
$icon-unlock-alt: "\f13e";
$icon-bullseye: "\f140";
$icon-ellipsis-h: "\f141";
$icon-ellipsis-v: "\f142";
$icon-rss-square: "\f143";
$icon-play-circle: "\f144";
$icon-ticket: "\f145";
$icon-minus-square: "\f146";
$icon-minus-square-o: "\f147";
$icon-level-up: "\f148";
$icon-level-down: "\f149";
$icon-check-square: "\f14a";
$icon-pencil-square: "\f14b";
$icon-external-link-square: "\f14c";
$icon-share-square: "\f14d";
$icon-compass: "\f14e";
$icon-caret-square-o-down: "\f150";
$icon-toggle-down: "\f150";
$icon-caret-square-o-up: "\f151";
$icon-toggle-up: "\f151";
$icon-caret-square-o-right: "\f152";
$icon-toggle-right: "\f152";
$icon-eur: "\f153";
$icon-euro: "\f153";
$icon-gbp: "\f154";
$icon-dollar: "\f155";
$icon-usd: "\f155";
$icon-inr: "\f156";
$icon-rupee: "\f156";
$icon-cny: "\f157";
$icon-jpy: "\f157";
$icon-rmb: "\f157";
$icon-yen: "\f157";
$icon-rouble: "\f158";
$icon-rub: "\f158";
$icon-ruble: "\f158";
$icon-krw: "\f159";
$icon-won: "\f159";
$icon-bitcoin: "\f15a";
$icon-btc: "\f15a";
$icon-file: "\f15b";
$icon-file-text: "\f15c";
$icon-sort-alpha-asc: "\f15d";
$icon-sort-alpha-desc: "\f15e";
$icon-sort-amount-asc: "\f160";
$icon-sort-amount-desc: "\f161";
$icon-sort-numeric-asc: "\f162";
$icon-sort-numeric-desc: "\f163";
$icon-thumbs-up: "\f164";
$icon-thumbs-down: "\f165";
$icon-youtube-square: "\f166";
$icon-youtube: "\f167";
$icon-xing: "\f168";
$icon-xing-square: "\f169";
$icon-youtube-play: "\f16a";
$icon-dropbox: "\f16b";
$icon-stack-overflow: "\f16c";
$icon-instagram: "\f16d";
$icon-flickr: "\f16e";
$icon-adn: "\f170";
$icon-bitbucket: "\f171";
$icon-bitbucket-square: "\f172";
$icon-tumblr: "\f173";
$icon-tumblr-square: "\f174";
$icon-long-arrow-down: "\f175";
$icon-long-arrow-up: "\f176";
$icon-long-arrow-left: "\f177";
$icon-long-arrow-right: "\f178";
$icon-apple: "\f179";
$icon-windows: "\f17a";
$icon-android: "\f17b";
$icon-linux: "\f17c";
$icon-dribbble: "\f17d";
$icon-skype: "\f17e";
$icon-foursquare: "\f180";
$icon-trello: "\f181";
$icon-female: "\f182";
$icon-male: "\f183";
$icon-gittip: "\f184";
$icon-gratipay: "\f184";
$icon-sun-o: "\f185";
$icon-moon-o: "\f186";
$icon-archive: "\f187";
$icon-bug: "\f188";
$icon-vk: "\f189";
$icon-weibo: "\f18a";
$icon-renren: "\f18b";
$icon-pagelines: "\f18c";
$icon-stack-exchange: "\f18d";
$icon-arrow-circle-o-right: "\f18e";
$icon-arrow-circle-o-left: "\f190";
$icon-caret-square-o-left: "\f191";
$icon-toggle-left: "\f191";
$icon-dot-circle-o: "\f192";
$icon-wheelchair: "\f193";
$icon-vimeo-square: "\f194";
$icon-try: "\f195";
$icon-turkish-lira: "\f195";
$icon-plus-square-o: "\f196";
$icon-space-shuttle: "\f197";
$icon-slack: "\f198";
$icon-envelope-square: "\f199";
$icon-wordpress: "\f19a";
$icon-openid: "\f19b";
$icon-bank: "\f19c";
$icon-institution: "\f19c";
$icon-university: "\f19c";
$icon-graduation-cap: "\f19d";
$icon-mortar-board: "\f19d";
$icon-yahoo: "\f19e";
$icon-google: "\f1a0";
$icon-reddit: "\f1a1";
$icon-reddit-square: "\f1a2";
$icon-stumbleupon-circle: "\f1a3";
$icon-stumbleupon: "\f1a4";
$icon-delicious: "\f1a5";
$icon-digg: "\f1a6";
$icon-pied-piper-pp: "\f1a7";
$icon-pied-piper-alt: "\f1a8";
$icon-drupal: "\f1a9";
$icon-joomla: "\f1aa";
$icon-language: "\f1ab";
$icon-fax: "\f1ac";
$icon-building: "\f1ad";
$icon-child: "\f1ae";
$icon-paw: "\f1b0";
$icon-spoon: "\f1b1";
$icon-cube: "\f1b2";
$icon-cubes: "\f1b3";
$icon-behance: "\f1b4";
$icon-behance-square: "\f1b5";
$icon-steam: "\f1b6";
$icon-steam-square: "\f1b7";
$icon-recycle: "\f1b8";
$icon-automobile: "\f1b9";
$icon-car: "\f1b9";
$icon-cab: "\f1ba";
$icon-taxi: "\f1ba";
$icon-tree: "\f1bb";
$icon-spotify: "\f1bc";
$icon-deviantart: "\f1bd";
$icon-soundcloud: "\f1be";
$icon-database: "\f1c0";
$icon-file-pdf-o: "\f1c1";
$icon-file-word-o: "\f1c2";
$icon-file-excel-o: "\f1c3";
$icon-file-powerpoint-o: "\f1c4";
$icon-file-image-o: "\f1c5";
$icon-file-photo-o: "\f1c5";
$icon-file-picture-o: "\f1c5";
$icon-file-archive-o: "\f1c6";
$icon-file-zip-o: "\f1c6";
$icon-file-audio-o: "\f1c7";
$icon-file-sound-o: "\f1c7";
$icon-file-movie-o: "\f1c8";
$icon-file-video-o: "\f1c8";
$icon-file-code-o: "\f1c9";
$icon-vine: "\f1ca";
$icon-codepen: "\f1cb";
$icon-jsfiddle: "\f1cc";
$icon-life-bouy: "\f1cd";
$icon-life-buoy: "\f1cd";
$icon-life-ring: "\f1cd";
$icon-life-saver: "\f1cd";
$icon-support: "\f1cd";
$icon-circle-o-notch: "\f1ce";
$icon-ra: "\f1d0";
$icon-rebel: "\f1d0";
$icon-resistance: "\f1d0";
$icon-empire: "\f1d1";
$icon-ge: "\f1d1";
$icon-git-square: "\f1d2";
$icon-git: "\f1d3";
$icon-hacker-news: "\f1d4";
$icon-y-combinator-square: "\f1d4";
$icon-yc-square: "\f1d4";
$icon-tencent-weibo: "\f1d5";
$icon-qq: "\f1d6";
$icon-wechat: "\f1d7";
$icon-weixin: "\f1d7";
$icon-paper-plane: "\f1d8";
$icon-send: "\f1d8";
$icon-paper-plane-o: "\f1d9";
$icon-send-o: "\f1d9";
$icon-history: "\f1da";
$icon-circle-thin: "\f1db";
$icon-header: "\f1dc";
$icon-paragraph: "\f1dd";
$icon-sliders: "\f1de";
$icon-share-alt: "\f1e0";
$icon-share-alt-square: "\f1e1";
$icon-bomb: "\f1e2";
$icon-futbol-o: "\f1e3";
$icon-soccer-ball-o: "\f1e3";
$icon-tty: "\f1e4";
$icon-binoculars: "\f1e5";
$icon-plug: "\f1e6";
$icon-slideshare: "\f1e7";
$icon-twitch: "\f1e8";
$icon-yelp: "\f1e9";
$icon-newspaper-o: "\f1ea";
$icon-wifi: "\f1eb";
$icon-calculator: "\f1ec";
$icon-paypal: "\f1ed";
$icon-google-wallet: "\f1ee";
$icon-cc-visa: "\f1f0";
$icon-cc-mastercard: "\f1f1";
$icon-cc-discover: "\f1f2";
$icon-cc-amex: "\f1f3";
$icon-cc-paypal: "\f1f4";
$icon-cc-stripe: "\f1f5";
$icon-bell-slash: "\f1f6";
$icon-bell-slash-o: "\f1f7";
$icon-trash: "\f1f8";
$icon-copyright: "\f1f9";
$icon-at: "\f1fa";
$icon-eyedropper: "\f1fb";
$icon-paint-brush: "\f1fc";
$icon-birthday-cake: "\f1fd";
$icon-area-chart: "\f1fe";
$icon-pie-chart: "\f200";
$icon-line-chart: "\f201";
$icon-lastfm: "\f202";
$icon-lastfm-square: "\f203";
$icon-toggle-off: "\f204";
$icon-toggle-on: "\f205";
$icon-bicycle: "\f206";
$icon-bus: "\f207";
$icon-ioxhost: "\f208";
$icon-angellist: "\f209";
$icon-cc: "\f20a";
$icon-ils: "\f20b";
$icon-shekel: "\f20b";
$icon-sheqel: "\f20b";
$icon-meanpath: "\f20c";
$icon-buysellads: "\f20d";
$icon-connectdevelop: "\f20e";
$icon-dashcube: "\f210";
$icon-forumbee: "\f211";
$icon-leanpub: "\f212";
$icon-sellsy: "\f213";
$icon-shirtsinbulk: "\f214";
$icon-simplybuilt: "\f215";
$icon-skyatlas: "\f216";
$icon-cart-plus: "\f217";
$icon-cart-arrow-down: "\f218";
$icon-diamond: "\f219";
$icon-ship: "\f21a";
$icon-user-secret: "\f21b";
$icon-motorcycle: "\f21c";
$icon-street-view: "\f21d";
$icon-heartbeat: "\f21e";
$icon-venus: "\f221";
$icon-mars: "\f222";
$icon-mercury: "\f223";
$icon-intersex: "\f224";
$icon-transgender: "\f224";
$icon-transgender-alt: "\f225";
$icon-venus-double: "\f226";
$icon-mars-double: "\f227";
$icon-venus-mars: "\f228";
$icon-mars-stroke: "\f229";
$icon-mars-stroke-v: "\f22a";
$icon-mars-stroke-h: "\f22b";
$icon-neuter: "\f22c";
$icon-genderless: "\f22d";
$icon-facebook-official: "\f230";
$icon-pinterest-p: "\f231";
$icon-whatsapp: "\f232";
$icon-server: "\f233";
$icon-user-plus: "\f234";
$icon-user-times: "\f235";
$icon-bed: "\f236";
$icon-hotel: "\f236";
$icon-viacoin: "\f237";
$icon-train: "\f238";
$icon-subway: "\f239";
$icon-medium: "\f23a";
$icon-y-combinator: "\f23b";
$icon-yc: "\f23b";
$icon-optin-monster: "\f23c";
$icon-opencart: "\f23d";
$icon-expeditedssl: "\f23e";
$icon-battery: "\f240";
$icon-battery-4: "\f240";
$icon-battery-full: "\f240";
$icon-battery-3: "\f241";
$icon-battery-three-quarters: "\f241";
$icon-battery-2: "\f242";
$icon-battery-half: "\f242";
$icon-battery-1: "\f243";
$icon-battery-quarter: "\f243";
$icon-battery-0: "\f244";
$icon-battery-empty: "\f244";
$icon-mouse-pointer: "\f245";
$icon-i-cursor: "\f246";
$icon-object-group: "\f247";
$icon-object-ungroup: "\f248";
$icon-sticky-note: "\f249";
$icon-sticky-note-o: "\f24a";
$icon-cc-jcb: "\f24b";
$icon-cc-diners-club: "\f24c";
$icon-clone: "\f24d";
$icon-balance-scale: "\f24e";
$icon-hourglass-o: "\f250";
$icon-hourglass-1: "\f251";
$icon-hourglass-start: "\f251";
$icon-hourglass-2: "\f252";
$icon-hourglass-half: "\f252";
$icon-hourglass-3: "\f253";
$icon-hourglass-end: "\f253";
$icon-hourglass: "\f254";
$icon-hand-grab-o: "\f255";
$icon-hand-rock-o: "\f255";
$icon-hand-paper-o: "\f256";
$icon-hand-stop-o: "\f256";
$icon-hand-scissors-o: "\f257";
$icon-hand-lizard-o: "\f258";
$icon-hand-spock-o: "\f259";
$icon-hand-pointer-o: "\f25a";
$icon-hand-peace-o: "\f25b";
$icon-trademark: "\f25c";
$icon-registered: "\f25d";
$icon-creative-commons: "\f25e";
$icon-gg: "\f260";
$icon-gg-circle: "\f261";
$icon-tripadvisor: "\f262";
$icon-odnoklassniki: "\f263";
$icon-odnoklassniki-square: "\f264";
$icon-get-pocket: "\f265";
$icon-wikipedia-w: "\f266";
$icon-safari: "\f267";
$icon-chrome: "\f268";
$icon-firefox: "\f269";
$icon-opera: "\f26a";
$icon-internet-explorer: "\f26b";
$icon-television: "\f26c";
$icon-tv: "\f26c";
$icon-contao: "\f26d";
$icon-500px: "\f26e";
$icon-amazon: "\f270";
$icon-calendar-plus-o: "\f271";
$icon-calendar-minus-o: "\f272";
$icon-calendar-times-o: "\f273";
$icon-calendar-check-o: "\f274";
$icon-industry: "\f275";
$icon-map-pin: "\f276";
$icon-map-signs: "\f277";
$icon-map-o: "\f278";
$icon-map: "\f279";
$icon-commenting: "\f27a";
$icon-commenting-o: "\f27b";
$icon-houzz: "\f27c";
$icon-vimeo: "\f27d";
$icon-black-tie: "\f27e";
$icon-fonticons: "\f280";
$icon-reddit-alien: "\f281";
$icon-edge: "\f282";
$icon-credit-card-alt: "\f283";
$icon-codiepie: "\f284";
$icon-modx: "\f285";
$icon-fort-awesome: "\f286";
$icon-usb: "\f287";
$icon-product-hunt: "\f288";
$icon-mixcloud: "\f289";
$icon-scribd: "\f28a";
$icon-pause-circle: "\f28b";
$icon-pause-circle-o: "\f28c";
$icon-stop-circle: "\f28d";
$icon-stop-circle-o: "\f28e";
$icon-shopping-bag: "\f290";
$icon-shopping-basket: "\f291";
$icon-hashtag: "\f292";
$icon-bluetooth: "\f293";
$icon-bluetooth-b: "\f294";
$icon-percent: "\f295";
$icon-gitlab: "\f296";
$icon-wpbeginner: "\f297";
$icon-wpforms: "\f298";
$icon-envira: "\f299";
$icon-universal-access: "\f29a";
$icon-wheelchair-alt: "\f29b";
$icon-question-circle-o: "\f29c";
$icon-blind: "\f29d";
$icon-audio-description: "\f29e";
$icon-volume-control-phone: "\f2a0";
$icon-braille: "\f2a1";
$icon-assistive-listening-systems: "\f2a2";
$icon-american-sign-language-interpreting: "\f2a3";
$icon-asl-interpreting: "\f2a3";
$icon-deaf: "\f2a4";
$icon-deafness: "\f2a4";
$icon-hard-of-hearing: "\f2a4";
$icon-glide: "\f2a5";
$icon-glide-g: "\f2a6";
$icon-sign-language: "\f2a7";
$icon-signing: "\f2a7";
$icon-low-vision: "\f2a8";
$icon-viadeo: "\f2a9";
$icon-viadeo-square: "\f2aa";
$icon-snapchat: "\f2ab";
$icon-snapchat-ghost: "\f2ac";
$icon-snapchat-square: "\f2ad";
$icon-pied-piper: "\f2ae";
$icon-first-order: "\f2b0";
$icon-yoast: "\f2b1";
$icon-themeisle: "\f2b2";
$icon-google-plus-circle: "\f2b3";
$icon-google-plus-official: "\f2b3";
$icon-fa: "\f2b4";
$icon-font-awesome: "\f2b4";
$icon-handshake-o: "\f2b5";
$icon-envelope-open: "\f2b6";
$icon-envelope-open-o: "\f2b7";
$icon-linode: "\f2b8";
$icon-address-book: "\f2b9";
$icon-address-book-o: "\f2ba";
$icon-address-card: "\f2bb";
$icon-vcard: "\f2bb";
$icon-address-card-o: "\f2bc";
$icon-vcard-o: "\f2bc";
$icon-user-circle: "\f2bd";
$icon-user-circle-o: "\f2be";
$icon-user-o: "\f2c0";
$icon-id-badge: "\f2c1";
$icon-drivers-license: "\f2c2";
$icon-id-card: "\f2c2";
$icon-drivers-license-o: "\f2c3";
$icon-id-card-o: "\f2c3";
$icon-quora: "\f2c4";
$icon-free-code-camp: "\f2c5";
$icon-telegram: "\f2c6";
$icon-thermometer: "\f2c7";
$icon-thermometer-4: "\f2c7";
$icon-thermometer-full: "\f2c7";
$icon-thermometer-3: "\f2c8";
$icon-thermometer-three-quarters: "\f2c8";
$icon-thermometer-2: "\f2c9";
$icon-thermometer-half: "\f2c9";
$icon-thermometer-1: "\f2ca";
$icon-thermometer-quarter: "\f2ca";
$icon-thermometer-0: "\f2cb";
$icon-thermometer-empty: "\f2cb";
$icon-shower: "\f2cc";
$icon-bath: "\f2cd";
$icon-bathtub: "\f2cd";
$icon-s15: "\f2cd";
$icon-podcast: "\f2ce";
$icon-window-maximize: "\f2d0";
$icon-window-minimize: "\f2d1";
$icon-window-restore: "\f2d2";
$icon-times-rectangle: "\f2d3";
$icon-window-close: "\f2d3";
$icon-times-rectangle-o: "\f2d4";
$icon-window-close-o: "\f2d4";
$icon-bandcamp: "\f2d5";
$icon-grav: "\f2d6";
$icon-etsy: "\f2d7";
$icon-imdb: "\f2d8";
$icon-ravelry: "\f2d9";
$icon-eercast: "\f2da";
$icon-microchip: "\f2db";
$icon-snowflake-o: "\f2dc";
$icon-superpowers: "\f2dd";
$icon-wpexplorer: "\f2de";
$icon-meetup: "\f2e0";



@font-face {
	font-family: '#{$iyilikkazansin-font-family}';
	src:  url('#{$iyilikkazansin-font-path}/#{$iyilikkazansin-font-family}.eot?sdeo7j');
	src:  url('#{$iyilikkazansin-font-path}/#{$iyilikkazansin-font-family}.eot?sdeo7j#iefix') format('embedded-opentype'),
	  url('#{$iyilikkazansin-font-path}/#{$iyilikkazansin-font-family}.ttf?sdeo7j') format('truetype'),
	  url('#{$iyilikkazansin-font-path}/#{$iyilikkazansin-font-family}.woff?sdeo7j') format('woff'),
	  url('#{$iyilikkazansin-font-path}/#{$iyilikkazansin-font-family}.svg?sdeo7j##{$iyilikkazansin-font-family}') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: '#{$iyilikkazansin-font-family}' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
  
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  }
  

  .icon-Bill-Drayton {
	&:before {
	  content: $icon-Bill-Drayton; 
	}
  }
  .icon-logo {
	&:before {
	  content: $icon-logo;    
	}
  }
  .icon-icon-login {
	&:before {
	  content: $icon-icon-login;   
	}
  }
  .icon-icon-online-shop {
	&:before {
	  content: $icon-icon-online-shop;   
	}
  }
  .icon-icon-shopping-basket {
	&:before {
	  content: $icon-icon-shopping-basket;     
	}
  }
  .icon-iyilikkazansin {
	&:before {
	  content: $icon-iyilikkazansin;     
	}
  }
  .icon-asterisk {
	&:before {
	  content: $icon-asterisk; 
	}
  }
  .icon-plus {
	&:before {
	  content: $icon-plus; 
	}
  }
  .icon-question {
	&:before {
	  content: $icon-question; 
	}
  }
  .icon-minus {
	&:before {
	  content: $icon-minus; 
	}
  }
  .icon-glass {
	&:before {
	  content: $icon-glass; 
	}
  }
  .icon-music {
	&:before {
	  content: $icon-music; 
	}
  }
  .icon-search {
	&:before {
	  content: $icon-search; 
	}
  }
  .icon-envelope-o {
	&:before {
	  content: $icon-envelope-o; 
	}
  }
  .icon-heart {
	&:before {
	  content: $icon-heart; 
	}
  }
  .icon-star {
	&:before {
	  content: $icon-star; 
	}
  }
  .icon-star-o {
	&:before {
	  content: $icon-star-o; 
	}
  }
  .icon-user {
	&:before {
	  content: $icon-user; 
	}
  }
  .icon-film {
	&:before {
	  content: $icon-film; 
	}
  }
  .icon-th-large {
	&:before {
	  content: $icon-th-large; 
	}
  }
  .icon-th {
	&:before {
	  content: $icon-th; 
	}
  }
  .icon-th-list {
	&:before {
	  content: $icon-th-list; 
	}
  }
  .icon-check {
	&:before {
	  content: $icon-check; 
	}
  }
  .icon-close {
	&:before {
	  content: $icon-close; 
	}
  }
  .icon-remove {
	&:before {
	  content: $icon-remove; 
	}
  }
  .icon-times {
	&:before {
	  content: $icon-times; 
	}
  }
  .icon-search-plus {
	&:before {
	  content: $icon-search-plus; 
	}
  }
  .icon-search-minus {
	&:before {
	  content: $icon-search-minus; 
	}
  }
  .icon-power-off {
	&:before {
	  content: $icon-power-off; 
	}
  }
  .icon-signal {
	&:before {
	  content: $icon-signal; 
	}
  }
  .icon-cog {
	&:before {
	  content: $icon-cog; 
	}
  }
  .icon-gear {
	&:before {
	  content: $icon-gear; 
	}
  }
  .icon-trash-o {
	&:before {
	  content: $icon-trash-o; 
	}
  }
  .icon-home {
	&:before {
	  content: $icon-home; 
	}
  }
  .icon-file-o {
	&:before {
	  content: $icon-file-o; 
	}
  }
  .icon-clock-o {
	&:before {
	  content: $icon-clock-o; 
	}
  }
  .icon-road {
	&:before {
	  content: $icon-road; 
	}
  }
  .icon-download {
	&:before {
	  content: $icon-download; 
	}
  }
  .icon-arrow-circle-o-down {
	&:before {
	  content: $icon-arrow-circle-o-down; 
	}
  }
  .icon-arrow-circle-o-up {
	&:before {
	  content: $icon-arrow-circle-o-up; 
	}
  }
  .icon-inbox {
	&:before {
	  content: $icon-inbox; 
	}
  }
  .icon-play-circle-o {
	&:before {
	  content: $icon-play-circle-o; 
	}
  }
  .icon-repeat {
	&:before {
	  content: $icon-repeat; 
	}
  }
  .icon-rotate-right {
	&:before {
	  content: $icon-rotate-right; 
	}
  }
  .icon-refresh {
	&:before {
	  content: $icon-refresh; 
	}
  }
  .icon-list-alt {
	&:before {
	  content: $icon-list-alt; 
	}
  }
  .icon-lock {
	&:before {
	  content: $icon-lock; 
	}
  }
  .icon-flag {
	&:before {
	  content: $icon-flag; 
	}
  }
  .icon-headphones {
	&:before {
	  content: $icon-headphones; 
	}
  }
  .icon-volume-off {
	&:before {
	  content: $icon-volume-off; 
	}
  }
  .icon-volume-down {
	&:before {
	  content: $icon-volume-down; 
	}
  }
  .icon-volume-up {
	&:before {
	  content: $icon-volume-up; 
	}
  }
  .icon-qrcode {
	&:before {
	  content: $icon-qrcode; 
	}
  }
  .icon-barcode {
	&:before {
	  content: $icon-barcode; 
	}
  }
  .icon-tag {
	&:before {
	  content: $icon-tag; 
	}
  }
  .icon-tags {
	&:before {
	  content: $icon-tags; 
	}
  }
  .icon-book {
	&:before {
	  content: $icon-book; 
	}
  }
  .icon-bookmark {
	&:before {
	  content: $icon-bookmark; 
	}
  }
  .icon-print {
	&:before {
	  content: $icon-print; 
	}
  }
  .icon-camera {
	&:before {
	  content: $icon-camera; 
	}
  }
  .icon-font {
	&:before {
	  content: $icon-font; 
	}
  }
  .icon-bold {
	&:before {
	  content: $icon-bold; 
	}
  }
  .icon-italic {
	&:before {
	  content: $icon-italic; 
	}
  }
  .icon-text-height {
	&:before {
	  content: $icon-text-height; 
	}
  }
  .icon-text-width {
	&:before {
	  content: $icon-text-width; 
	}
  }
  .icon-align-left {
	&:before {
	  content: $icon-align-left; 
	}
  }
  .icon-align-center {
	&:before {
	  content: $icon-align-center; 
	}
  }
  .icon-align-right {
	&:before {
	  content: $icon-align-right; 
	}
  }
  .icon-align-justify {
	&:before {
	  content: $icon-align-justify; 
	}
  }
  .icon-list {
	&:before {
	  content: $icon-list; 
	}
  }
  .icon-dedent {
	&:before {
	  content: $icon-dedent; 
	}
  }
  .icon-outdent {
	&:before {
	  content: $icon-outdent; 
	}
  }
  .icon-indent {
	&:before {
	  content: $icon-indent; 
	}
  }
  .icon-video-camera {
	&:before {
	  content: $icon-video-camera; 
	}
  }
  .icon-image {
	&:before {
	  content: $icon-image; 
	}
  }
  .icon-photo {
	&:before {
	  content: $icon-photo; 
	}
  }
  .icon-picture-o {
	&:before {
	  content: $icon-picture-o; 
	}
  }
  .icon-pencil {
	&:before {
	  content: $icon-pencil; 
	}
  }
  .icon-map-marker {
	&:before {
	  content: $icon-map-marker; 
	}
  }
  .icon-adjust {
	&:before {
	  content: $icon-adjust; 
	}
  }
  .icon-tint {
	&:before {
	  content: $icon-tint; 
	}
  }
  .icon-edit {
	&:before {
	  content: $icon-edit; 
	}
  }
  .icon-pencil-square-o {
	&:before {
	  content: $icon-pencil-square-o; 
	}
  }
  .icon-share-square-o {
	&:before {
	  content: $icon-share-square-o; 
	}
  }
  .icon-check-square-o {
	&:before {
	  content: $icon-check-square-o; 
	}
  }
  .icon-arrows {
	&:before {
	  content: $icon-arrows; 
	}
  }
  .icon-step-backward {
	&:before {
	  content: $icon-step-backward; 
	}
  }
  .icon-fast-backward {
	&:before {
	  content: $icon-fast-backward; 
	}
  }
  .icon-backward {
	&:before {
	  content: $icon-backward; 
	}
  }
  .icon-play {
	&:before {
	  content: $icon-play; 
	}
  }
  .icon-pause {
	&:before {
	  content: $icon-pause; 
	}
  }
  .icon-stop {
	&:before {
	  content: $icon-stop; 
	}
  }
  .icon-forward {
	&:before {
	  content: $icon-forward; 
	}
  }
  .icon-fast-forward {
	&:before {
	  content: $icon-fast-forward; 
	}
  }
  .icon-step-forward {
	&:before {
	  content: $icon-step-forward; 
	}
  }
  .icon-eject {
	&:before {
	  content: $icon-eject; 
	}
  }
  .icon-chevron-left {
	&:before {
	  content: $icon-chevron-left; 
	}
  }
  .icon-chevron-right {
	&:before {
	  content: $icon-chevron-right; 
	}
  }
  .icon-plus-circle {
	&:before {
	  content: $icon-plus-circle; 
	}
  }
  .icon-minus-circle {
	&:before {
	  content: $icon-minus-circle; 
	}
  }
  .icon-times-circle {
	&:before {
	  content: $icon-times-circle; 
	}
  }
  .icon-check-circle {
	&:before {
	  content: $icon-check-circle; 
	}
  }
  .icon-question-circle {
	&:before {
	  content: $icon-question-circle; 
	}
  }
  .icon-info-circle {
	&:before {
	  content: $icon-info-circle; 
	}
  }
  .icon-crosshairs {
	&:before {
	  content: $icon-crosshairs; 
	}
  }
  .icon-times-circle-o {
	&:before {
	  content: $icon-times-circle-o; 
	}
  }
  .icon-check-circle-o {
	&:before {
	  content: $icon-check-circle-o; 
	}
  }
  .icon-ban {
	&:before {
	  content: $icon-ban; 
	}
  }
  .icon-arrow-left {
	&:before {
	  content: $icon-arrow-left; 
	}
  }
  .icon-arrow-right {
	&:before {
	  content: $icon-arrow-right; 
	}
  }
  .icon-arrow-up {
	&:before {
	  content: $icon-arrow-up; 
	}
  }
  .icon-arrow-down {
	&:before {
	  content: $icon-arrow-down; 
	}
  }
  .icon-mail-forward {
	&:before {
	  content: $icon-mail-forward; 
	}
  }
  .icon-share {
	&:before {
	  content: $icon-share; 
	}
  }
  .icon-expand {
	&:before {
	  content: $icon-expand; 
	}
  }
  .icon-compress {
	&:before {
	  content: $icon-compress; 
	}
  }
  .icon-exclamation-circle {
	&:before {
	  content: $icon-exclamation-circle; 
	}
  }
  .icon-gift {
	&:before {
	  content: $icon-gift; 
	}
  }
  .icon-leaf {
	&:before {
	  content: $icon-leaf; 
	}
  }
  .icon-fire {
	&:before {
	  content: $icon-fire; 
	}
  }
  .icon-eye {
	&:before {
	  content: $icon-eye; 
	}
  }
  .icon-eye-slash {
	&:before {
	  content: $icon-eye-slash; 
	}
  }
  .icon-exclamation-triangle {
	&:before {
	  content: $icon-exclamation-triangle; 
	}
  }
  .icon-warning {
	&:before {
	  content: $icon-warning; 
	}
  }
  .icon-plane {
	&:before {
	  content: $icon-plane; 
	}
  }
  .icon-calendar {
	&:before {
	  content: $icon-calendar; 
	}
  }
  .icon-random {
	&:before {
	  content: $icon-random; 
	}
  }
  .icon-comment {
	&:before {
	  content: $icon-comment; 
	}
  }
  .icon-magnet {
	&:before {
	  content: $icon-magnet; 
	}
  }
  .icon-chevron-up {
	&:before {
	  content: $icon-chevron-up; 
	}
  }
  .icon-chevron-down {
	&:before {
	  content: $icon-chevron-down; 
	}
  }
  .icon-retweet {
	&:before {
	  content: $icon-retweet; 
	}
  }
  .icon-shopping-cart {
	&:before {
	  content: $icon-shopping-cart; 
	}
  }
  .icon-folder {
	&:before {
	  content: $icon-folder; 
	}
  }
  .icon-folder-open {
	&:before {
	  content: $icon-folder-open; 
	}
  }
  .icon-arrows-v {
	&:before {
	  content: $icon-arrows-v; 
	}
  }
  .icon-arrows-h {
	&:before {
	  content: $icon-arrows-h; 
	}
  }
  .icon-bar-chart {
	&:before {
	  content: $icon-bar-chart; 
	}
  }
  .icon-bar-chart-o {
	&:before {
	  content: $icon-bar-chart-o; 
	}
  }
  .icon-twitter-square {
	&:before {
	  content: $icon-twitter-square; 
	}
  }
  .icon-facebook-square {
	&:before {
	  content: $icon-facebook-square; 
	}
  }
  .icon-camera-retro {
	&:before {
	  content: $icon-camera-retro; 
	}
  }
  .icon-key {
	&:before {
	  content: $icon-key; 
	}
  }
  .icon-cogs {
	&:before {
	  content: $icon-cogs; 
	}
  }
  .icon-gears {
	&:before {
	  content: $icon-gears; 
	}
  }
  .icon-comments {
	&:before {
	  content: $icon-comments; 
	}
  }
  .icon-thumbs-o-up {
	&:before {
	  content: $icon-thumbs-o-up; 
	}
  }
  .icon-thumbs-o-down {
	&:before {
	  content: $icon-thumbs-o-down; 
	}
  }
  .icon-star-half {
	&:before {
	  content: $icon-star-half; 
	}
  }
  .icon-heart-o {
	&:before {
	  content: $icon-heart-o; 
	}
  }
  .icon-sign-out {
	&:before {
	  content: $icon-sign-out; 
	}
  }
  .icon-linkedin-square {
	&:before {
	  content: $icon-linkedin-square; 
	}
  }
  .icon-thumb-tack {
	&:before {
	  content: $icon-thumb-tack; 
	}
  }
  .icon-external-link {
	&:before {
	  content: $icon-external-link; 
	}
  }
  .icon-sign-in {
	&:before {
	  content: $icon-sign-in; 
	}
  }
  .icon-trophy {
	&:before {
	  content: $icon-trophy; 
	}
  }
  .icon-github-square {
	&:before {
	  content: $icon-github-square; 
	}
  }
  .icon-upload {
	&:before {
	  content: $icon-upload; 
	}
  }
  .icon-lemon-o {
	&:before {
	  content: $icon-lemon-o; 
	}
  }
  .icon-phone {
	&:before {
	  content: $icon-phone; 
	}
  }
  .icon-square-o {
	&:before {
	  content: $icon-square-o; 
	}
  }
  .icon-bookmark-o {
	&:before {
	  content: $icon-bookmark-o; 
	}
  }
  .icon-phone-square {
	&:before {
	  content: $icon-phone-square; 
	}
  }
  .icon-twitter {
	&:before {
	  content: $icon-twitter; 
	}
  }
  .icon-facebook {
	&:before {
	  content: $icon-facebook; 
	}
  }
  .icon-facebook-f {
	&:before {
	  content: $icon-facebook-f; 
	}
  }
  .icon-github {
	&:before {
	  content: $icon-github; 
	}
  }
  .icon-unlock {
	&:before {
	  content: $icon-unlock; 
	}
  }
  .icon-credit-card {
	&:before {
	  content: $icon-credit-card; 
	}
  }
  .icon-feed {
	&:before {
	  content: $icon-feed; 
	}
  }
  .icon-rss {
	&:before {
	  content: $icon-rss; 
	}
  }
  .icon-hdd-o {
	&:before {
	  content: $icon-hdd-o; 
	}
  }
  .icon-bullhorn {
	&:before {
	  content: $icon-bullhorn; 
	}
  }
  .icon-bell-o {
	&:before {
	  content: $icon-bell-o; 
	}
  }
  .icon-certificate {
	&:before {
	  content: $icon-certificate; 
	}
  }
  .icon-hand-o-right {
	&:before {
	  content: $icon-hand-o-right; 
	}
  }
  .icon-hand-o-left {
	&:before {
	  content: $icon-hand-o-left; 
	}
  }
  .icon-hand-o-up {
	&:before {
	  content: $icon-hand-o-up; 
	}
  }
  .icon-hand-o-down {
	&:before {
	  content: $icon-hand-o-down; 
	}
  }
  .icon-arrow-circle-left {
	&:before {
	  content: $icon-arrow-circle-left; 
	}
  }
  .icon-arrow-circle-right {
	&:before {
	  content: $icon-arrow-circle-right; 
	}
  }
  .icon-arrow-circle-up {
	&:before {
	  content: $icon-arrow-circle-up; 
	}
  }
  .icon-arrow-circle-down {
	&:before {
	  content: $icon-arrow-circle-down; 
	}
  }
  .icon-globe {
	&:before {
	  content: $icon-globe; 
	}
  }
  .icon-wrench {
	&:before {
	  content: $icon-wrench; 
	}
  }
  .icon-tasks {
	&:before {
	  content: $icon-tasks; 
	}
  }
  .icon-filter {
	&:before {
	  content: $icon-filter; 
	}
  }
  .icon-briefcase {
	&:before {
	  content: $icon-briefcase; 
	}
  }
  .icon-arrows-alt {
	&:before {
	  content: $icon-arrows-alt; 
	}
  }
  .icon-group {
	&:before {
	  content: $icon-group; 
	}
  }
  .icon-users {
	&:before {
	  content: $icon-users; 
	}
  }
  .icon-chain {
	&:before {
	  content: $icon-chain; 
	}
  }
  .icon-link {
	&:before {
	  content: $icon-link; 
	}
  }
  .icon-cloud {
	&:before {
	  content: $icon-cloud; 
	}
  }
  .icon-flask {
	&:before {
	  content: $icon-flask; 
	}
  }
  .icon-cut {
	&:before {
	  content: $icon-cut; 
	}
  }
  .icon-scissors {
	&:before {
	  content: $icon-scissors; 
	}
  }
  .icon-copy {
	&:before {
	  content: $icon-copy; 
	}
  }
  .icon-files-o {
	&:before {
	  content: $icon-files-o; 
	}
  }
  .icon-paperclip {
	&:before {
	  content: $icon-paperclip; 
	}
  }
  .icon-floppy-o {
	&:before {
	  content: $icon-floppy-o; 
	}
  }
  .icon-save {
	&:before {
	  content: $icon-save; 
	}
  }
  .icon-square {
	&:before {
	  content: $icon-square; 
	}
  }
  .icon-bars {
	&:before {
	  content: $icon-bars; 
	}
  }
  .icon-navicon {
	&:before {
	  content: $icon-navicon; 
	}
  }
  .icon-reorder {
	&:before {
	  content: $icon-reorder; 
	}
  }
  .icon-list-ul {
	&:before {
	  content: $icon-list-ul; 
	}
  }
  .icon-list-ol {
	&:before {
	  content: $icon-list-ol; 
	}
  }
  .icon-strikethrough {
	&:before {
	  content: $icon-strikethrough; 
	}
  }
  .icon-underline {
	&:before {
	  content: $icon-underline; 
	}
  }
  .icon-table {
	&:before {
	  content: $icon-table; 
	}
  }
  .icon-magic {
	&:before {
	  content: $icon-magic; 
	}
  }
  .icon-truck {
	&:before {
	  content: $icon-truck; 
	}
  }
  .icon-pinterest {
	&:before {
	  content: $icon-pinterest; 
	}
  }
  .icon-pinterest-square {
	&:before {
	  content: $icon-pinterest-square; 
	}
  }
  .icon-google-plus-square {
	&:before {
	  content: $icon-google-plus-square; 
	}
  }
  .icon-google-plus {
	&:before {
	  content: $icon-google-plus; 
	}
  }
  .icon-money {
	&:before {
	  content: $icon-money; 
	}
  }
  .icon-caret-down {
	&:before {
	  content: $icon-caret-down; 
	}
  }
  .icon-caret-up {
	&:before {
	  content: $icon-caret-up; 
	}
  }
  .icon-caret-left {
	&:before {
	  content: $icon-caret-left; 
	}
  }
  .icon-caret-right {
	&:before {
	  content: $icon-caret-right; 
	}
  }
  .icon-columns {
	&:before {
	  content: $icon-columns; 
	}
  }
  .icon-sort {
	&:before {
	  content: $icon-sort; 
	}
  }
  .icon-unsorted {
	&:before {
	  content: $icon-unsorted; 
	}
  }
  .icon-sort-desc {
	&:before {
	  content: $icon-sort-desc; 
	}
  }
  .icon-sort-down {
	&:before {
	  content: $icon-sort-down; 
	}
  }
  .icon-sort-asc {
	&:before {
	  content: $icon-sort-asc; 
	}
  }
  .icon-sort-up {
	&:before {
	  content: $icon-sort-up; 
	}
  }
  .icon-envelope {
	&:before {
	  content: $icon-envelope; 
	}
  }
  .icon-linkedin {
	&:before {
	  content: $icon-linkedin; 
	}
  }
  .icon-rotate-left {
	&:before {
	  content: $icon-rotate-left; 
	}
  }
  .icon-undo {
	&:before {
	  content: $icon-undo; 
	}
  }
  .icon-gavel {
	&:before {
	  content: $icon-gavel; 
	}
  }
  .icon-legal {
	&:before {
	  content: $icon-legal; 
	}
  }
  .icon-dashboard {
	&:before {
	  content: $icon-dashboard; 
	}
  }
  .icon-tachometer {
	&:before {
	  content: $icon-tachometer; 
	}
  }
  .icon-comment-o {
	&:before {
	  content: $icon-comment-o; 
	}
  }
  .icon-comments-o {
	&:before {
	  content: $icon-comments-o; 
	}
  }
  .icon-bolt {
	&:before {
	  content: $icon-bolt; 
	}
  }
  .icon-flash {
	&:before {
	  content: $icon-flash; 
	}
  }
  .icon-sitemap {
	&:before {
	  content: $icon-sitemap; 
	}
  }
  .icon-umbrella {
	&:before {
	  content: $icon-umbrella; 
	}
  }
  .icon-clipboard {
	&:before {
	  content: $icon-clipboard; 
	}
  }
  .icon-paste {
	&:before {
	  content: $icon-paste; 
	}
  }
  .icon-lightbulb-o {
	&:before {
	  content: $icon-lightbulb-o; 
	}
  }
  .icon-exchange {
	&:before {
	  content: $icon-exchange; 
	}
  }
  .icon-cloud-download {
	&:before {
	  content: $icon-cloud-download; 
	}
  }
  .icon-cloud-upload {
	&:before {
	  content: $icon-cloud-upload; 
	}
  }
  .icon-user-md {
	&:before {
	  content: $icon-user-md; 
	}
  }
  .icon-stethoscope {
	&:before {
	  content: $icon-stethoscope; 
	}
  }
  .icon-suitcase {
	&:before {
	  content: $icon-suitcase; 
	}
  }
  .icon-bell {
	&:before {
	  content: $icon-bell; 
	}
  }
  .icon-coffee {
	&:before {
	  content: $icon-coffee; 
	}
  }
  .icon-cutlery {
	&:before {
	  content: $icon-cutlery; 
	}
  }
  .icon-file-text-o {
	&:before {
	  content: $icon-file-text-o; 
	}
  }
  .icon-building-o {
	&:before {
	  content: $icon-building-o; 
	}
  }
  .icon-hospital-o {
	&:before {
	  content: $icon-hospital-o; 
	}
  }
  .icon-ambulance {
	&:before {
	  content: $icon-ambulance; 
	}
  }
  .icon-medkit {
	&:before {
	  content: $icon-medkit; 
	}
  }
  .icon-fighter-jet {
	&:before {
	  content: $icon-fighter-jet; 
	}
  }
  .icon-beer {
	&:before {
	  content: $icon-beer; 
	}
  }
  .icon-h-square {
	&:before {
	  content: $icon-h-square; 
	}
  }
  .icon-plus-square {
	&:before {
	  content: $icon-plus-square; 
	}
  }
  .icon-angle-double-left {
	&:before {
	  content: $icon-angle-double-left; 
	}
  }
  .icon-angle-double-right {
	&:before {
	  content: $icon-angle-double-right; 
	}
  }
  .icon-angle-double-up {
	&:before {
	  content: $icon-angle-double-up; 
	}
  }
  .icon-angle-double-down {
	&:before {
	  content: $icon-angle-double-down; 
	}
  }
  .icon-angle-left {
	&:before {
	  content: $icon-angle-left; 
	}
  }
  .icon-angle-right {
	&:before {
	  content: $icon-angle-right; 
	}
  }
  .icon-angle-up {
	&:before {
	  content: $icon-angle-up; 
	}
  }
  .icon-angle-down {
	&:before {
	  content: $icon-angle-down; 
	}
  }
  .icon-desktop {
	&:before {
	  content: $icon-desktop; 
	}
  }
  .icon-laptop {
	&:before {
	  content: $icon-laptop; 
	}
  }
  .icon-tablet {
	&:before {
	  content: $icon-tablet; 
	}
  }
  .icon-mobile {
	&:before {
	  content: $icon-mobile; 
	}
  }
  .icon-mobile-phone {
	&:before {
	  content: $icon-mobile-phone; 
	}
  }
  .icon-circle-o {
	&:before {
	  content: $icon-circle-o; 
	}
  }
  .icon-quote-left {
	&:before {
	  content: $icon-quote-left; 
	}
  }
  .icon-quote-right {
	&:before {
	  content: $icon-quote-right; 
	}
  }
  .icon-spinner {
	&:before {
	  content: $icon-spinner; 
	}
  }
  .icon-circle {
	&:before {
	  content: $icon-circle; 
	}
  }
  .icon-mail-reply {
	&:before {
	  content: $icon-mail-reply; 
	}
  }
  .icon-reply {
	&:before {
	  content: $icon-reply; 
	}
  }
  .icon-github-alt {
	&:before {
	  content: $icon-github-alt; 
	}
  }
  .icon-folder-o {
	&:before {
	  content: $icon-folder-o; 
	}
  }
  .icon-folder-open-o {
	&:before {
	  content: $icon-folder-open-o; 
	}
  }
  .icon-smile-o {
	&:before {
	  content: $icon-smile-o; 
	}
  }
  .icon-frown-o {
	&:before {
	  content: $icon-frown-o; 
	}
  }
  .icon-meh-o {
	&:before {
	  content: $icon-meh-o; 
	}
  }
  .icon-gamepad {
	&:before {
	  content: $icon-gamepad; 
	}
  }
  .icon-keyboard-o {
	&:before {
	  content: $icon-keyboard-o; 
	}
  }
  .icon-flag-o {
	&:before {
	  content: $icon-flag-o; 
	}
  }
  .icon-flag-checkered {
	&:before {
	  content: $icon-flag-checkered; 
	}
  }
  .icon-terminal {
	&:before {
	  content: $icon-terminal; 
	}
  }
  .icon-code {
	&:before {
	  content: $icon-code; 
	}
  }
  .icon-mail-reply-all {
	&:before {
	  content: $icon-mail-reply-all; 
	}
  }
  .icon-reply-all {
	&:before {
	  content: $icon-reply-all; 
	}
  }
  .icon-star-half-empty {
	&:before {
	  content: $icon-star-half-empty; 
	}
  }
  .icon-star-half-full {
	&:before {
	  content: $icon-star-half-full; 
	}
  }
  .icon-star-half-o {
	&:before {
	  content: $icon-star-half-o; 
	}
  }
  .icon-location-arrow {
	&:before {
	  content: $icon-location-arrow; 
	}
  }
  .icon-crop {
	&:before {
	  content: $icon-crop; 
	}
  }
  .icon-code-fork {
	&:before {
	  content: $icon-code-fork; 
	}
  }
  .icon-chain-broken {
	&:before {
	  content: $icon-chain-broken; 
	}
  }
  .icon-unlink {
	&:before {
	  content: $icon-unlink; 
	}
  }
  .icon-info {
	&:before {
	  content: $icon-info; 
	}
  }
  .icon-exclamation {
	&:before {
	  content: $icon-exclamation; 
	}
  }
  .icon-superscript {
	&:before {
	  content: $icon-superscript; 
	}
  }
  .icon-subscript {
	&:before {
	  content: $icon-subscript; 
	}
  }
  .icon-eraser {
	&:before {
	  content: $icon-eraser; 
	}
  }
  .icon-puzzle-piece {
	&:before {
	  content: $icon-puzzle-piece; 
	}
  }
  .icon-microphone {
	&:before {
	  content: $icon-microphone; 
	}
  }
  .icon-microphone-slash {
	&:before {
	  content: $icon-microphone-slash; 
	}
  }
  .icon-shield {
	&:before {
	  content: $icon-shield; 
	}
  }
  .icon-calendar-o {
	&:before {
	  content: $icon-calendar-o; 
	}
  }
  .icon-fire-extinguisher {
	&:before {
	  content: $icon-fire-extinguisher; 
	}
  }
  .icon-rocket {
	&:before {
	  content: $icon-rocket; 
	}
  }
  .icon-maxcdn {
	&:before {
	  content: $icon-maxcdn; 
	}
  }
  .icon-chevron-circle-left {
	&:before {
	  content: $icon-chevron-circle-left; 
	}
  }
  .icon-chevron-circle-right {
	&:before {
	  content: $icon-chevron-circle-right; 
	}
  }
  .icon-chevron-circle-up {
	&:before {
	  content: $icon-chevron-circle-up; 
	}
  }
  .icon-chevron-circle-down {
	&:before {
	  content: $icon-chevron-circle-down; 
	}
  }
  .icon-html5 {
	&:before {
	  content: $icon-html5; 
	}
  }
  .icon-css3 {
	&:before {
	  content: $icon-css3; 
	}
  }
  .icon-anchor {
	&:before {
	  content: $icon-anchor; 
	}
  }
  .icon-unlock-alt {
	&:before {
	  content: $icon-unlock-alt; 
	}
  }
  .icon-bullseye {
	&:before {
	  content: $icon-bullseye; 
	}
  }
  .icon-ellipsis-h {
	&:before {
	  content: $icon-ellipsis-h; 
	}
  }
  .icon-ellipsis-v {
	&:before {
	  content: $icon-ellipsis-v; 
	}
  }
  .icon-rss-square {
	&:before {
	  content: $icon-rss-square; 
	}
  }
  .icon-play-circle {
	&:before {
	  content: $icon-play-circle; 
	}
  }
  .icon-ticket {
	&:before {
	  content: $icon-ticket; 
	}
  }
  .icon-minus-square {
	&:before {
	  content: $icon-minus-square; 
	}
  }
  .icon-minus-square-o {
	&:before {
	  content: $icon-minus-square-o; 
	}
  }
  .icon-level-up {
	&:before {
	  content: $icon-level-up; 
	}
  }
  .icon-level-down {
	&:before {
	  content: $icon-level-down; 
	}
  }
  .icon-check-square {
	&:before {
	  content: $icon-check-square; 
	}
  }
  .icon-pencil-square {
	&:before {
	  content: $icon-pencil-square; 
	}
  }
  .icon-external-link-square {
	&:before {
	  content: $icon-external-link-square; 
	}
  }
  .icon-share-square {
	&:before {
	  content: $icon-share-square; 
	}
  }
  .icon-compass {
	&:before {
	  content: $icon-compass; 
	}
  }
  .icon-caret-square-o-down {
	&:before {
	  content: $icon-caret-square-o-down; 
	}
  }
  .icon-toggle-down {
	&:before {
	  content: $icon-toggle-down; 
	}
  }
  .icon-caret-square-o-up {
	&:before {
	  content: $icon-caret-square-o-up; 
	}
  }
  .icon-toggle-up {
	&:before {
	  content: $icon-toggle-up; 
	}
  }
  .icon-caret-square-o-right {
	&:before {
	  content: $icon-caret-square-o-right; 
	}
  }
  .icon-toggle-right {
	&:before {
	  content: $icon-toggle-right; 
	}
  }
  .icon-eur {
	&:before {
	  content: $icon-eur; 
	}
  }
  .icon-euro {
	&:before {
	  content: $icon-euro; 
	}
  }
  .icon-gbp {
	&:before {
	  content: $icon-gbp; 
	}
  }
  .icon-dollar {
	&:before {
	  content: $icon-dollar; 
	}
  }
  .icon-usd {
	&:before {
	  content: $icon-usd; 
	}
  }
  .icon-inr {
	&:before {
	  content: $icon-inr; 
	}
  }
  .icon-rupee {
	&:before {
	  content: $icon-rupee; 
	}
  }
  .icon-cny {
	&:before {
	  content: $icon-cny; 
	}
  }
  .icon-jpy {
	&:before {
	  content: $icon-jpy; 
	}
  }
  .icon-rmb {
	&:before {
	  content: $icon-rmb; 
	}
  }
  .icon-yen {
	&:before {
	  content: $icon-yen; 
	}
  }
  .icon-rouble {
	&:before {
	  content: $icon-rouble; 
	}
  }
  .icon-rub {
	&:before {
	  content: $icon-rub; 
	}
  }
  .icon-ruble {
	&:before {
	  content: $icon-ruble; 
	}
  }
  .icon-krw {
	&:before {
	  content: $icon-krw; 
	}
  }
  .icon-won {
	&:before {
	  content: $icon-won; 
	}
  }
  .icon-bitcoin {
	&:before {
	  content: $icon-bitcoin; 
	}
  }
  .icon-btc {
	&:before {
	  content: $icon-btc; 
	}
  }
  .icon-file {
	&:before {
	  content: $icon-file; 
	}
  }
  .icon-file-text {
	&:before {
	  content: $icon-file-text; 
	}
  }
  .icon-sort-alpha-asc {
	&:before {
	  content: $icon-sort-alpha-asc; 
	}
  }
  .icon-sort-alpha-desc {
	&:before {
	  content: $icon-sort-alpha-desc; 
	}
  }
  .icon-sort-amount-asc {
	&:before {
	  content: $icon-sort-amount-asc; 
	}
  }
  .icon-sort-amount-desc {
	&:before {
	  content: $icon-sort-amount-desc; 
	}
  }
  .icon-sort-numeric-asc {
	&:before {
	  content: $icon-sort-numeric-asc; 
	}
  }
  .icon-sort-numeric-desc {
	&:before {
	  content: $icon-sort-numeric-desc; 
	}
  }
  .icon-thumbs-up {
	&:before {
	  content: $icon-thumbs-up; 
	}
  }
  .icon-thumbs-down {
	&:before {
	  content: $icon-thumbs-down; 
	}
  }
  .icon-youtube-square {
	&:before {
	  content: $icon-youtube-square; 
	}
  }
  .icon-youtube {
	&:before {
	  content: $icon-youtube; 
	}
  }
  .icon-xing {
	&:before {
	  content: $icon-xing; 
	}
  }
  .icon-xing-square {
	&:before {
	  content: $icon-xing-square; 
	}
  }
  .icon-youtube-play {
	&:before {
	  content: $icon-youtube-play; 
	}
  }
  .icon-dropbox {
	&:before {
	  content: $icon-dropbox; 
	}
  }
  .icon-stack-overflow {
	&:before {
	  content: $icon-stack-overflow; 
	}
  }
  .icon-instagram {
	&:before {
	  content: $icon-instagram; 
	}
  }
  .icon-flickr {
	&:before {
	  content: $icon-flickr; 
	}
  }
  .icon-adn {
	&:before {
	  content: $icon-adn; 
	}
  }
  .icon-bitbucket {
	&:before {
	  content: $icon-bitbucket; 
	}
  }
  .icon-bitbucket-square {
	&:before {
	  content: $icon-bitbucket-square; 
	}
  }
  .icon-tumblr {
	&:before {
	  content: $icon-tumblr; 
	}
  }
  .icon-tumblr-square {
	&:before {
	  content: $icon-tumblr-square; 
	}
  }
  .icon-long-arrow-down {
	&:before {
	  content: $icon-long-arrow-down; 
	}
  }
  .icon-long-arrow-up {
	&:before {
	  content: $icon-long-arrow-up; 
	}
  }
  .icon-long-arrow-left {
	&:before {
	  content: $icon-long-arrow-left; 
	}
  }
  .icon-long-arrow-right {
	&:before {
	  content: $icon-long-arrow-right; 
	}
  }
  .icon-apple {
	&:before {
	  content: $icon-apple; 
	}
  }
  .icon-windows {
	&:before {
	  content: $icon-windows; 
	}
  }
  .icon-android {
	&:before {
	  content: $icon-android; 
	}
  }
  .icon-linux {
	&:before {
	  content: $icon-linux; 
	}
  }
  .icon-dribbble {
	&:before {
	  content: $icon-dribbble; 
	}
  }
  .icon-skype {
	&:before {
	  content: $icon-skype; 
	}
  }
  .icon-foursquare {
	&:before {
	  content: $icon-foursquare; 
	}
  }
  .icon-trello {
	&:before {
	  content: $icon-trello; 
	}
  }
  .icon-female {
	&:before {
	  content: $icon-female; 
	}
  }
  .icon-male {
	&:before {
	  content: $icon-male; 
	}
  }
  .icon-gittip {
	&:before {
	  content: $icon-gittip; 
	}
  }
  .icon-gratipay {
	&:before {
	  content: $icon-gratipay; 
	}
  }
  .icon-sun-o {
	&:before {
	  content: $icon-sun-o; 
	}
  }
  .icon-moon-o {
	&:before {
	  content: $icon-moon-o; 
	}
  }
  .icon-archive {
	&:before {
	  content: $icon-archive; 
	}
  }
  .icon-bug {
	&:before {
	  content: $icon-bug; 
	}
  }
  .icon-vk {
	&:before {
	  content: $icon-vk; 
	}
  }
  .icon-weibo {
	&:before {
	  content: $icon-weibo; 
	}
  }
  .icon-renren {
	&:before {
	  content: $icon-renren; 
	}
  }
  .icon-pagelines {
	&:before {
	  content: $icon-pagelines; 
	}
  }
  .icon-stack-exchange {
	&:before {
	  content: $icon-stack-exchange; 
	}
  }
  .icon-arrow-circle-o-right {
	&:before {
	  content: $icon-arrow-circle-o-right; 
	}
  }
  .icon-arrow-circle-o-left {
	&:before {
	  content: $icon-arrow-circle-o-left; 
	}
  }
  .icon-caret-square-o-left {
	&:before {
	  content: $icon-caret-square-o-left; 
	}
  }
  .icon-toggle-left {
	&:before {
	  content: $icon-toggle-left; 
	}
  }
  .icon-dot-circle-o {
	&:before {
	  content: $icon-dot-circle-o; 
	}
  }
  .icon-wheelchair {
	&:before {
	  content: $icon-wheelchair; 
	}
  }
  .icon-vimeo-square {
	&:before {
	  content: $icon-vimeo-square; 
	}
  }
  .icon-try {
	&:before {
	  content: $icon-try; 
	}
  }
  .icon-turkish-lira {
	&:before {
	  content: $icon-turkish-lira; 
	}
  }
  .icon-plus-square-o {
	&:before {
	  content: $icon-plus-square-o; 
	}
  }
  .icon-space-shuttle {
	&:before {
	  content: $icon-space-shuttle; 
	}
  }
  .icon-slack {
	&:before {
	  content: $icon-slack; 
	}
  }
  .icon-envelope-square {
	&:before {
	  content: $icon-envelope-square; 
	}
  }
  .icon-wordpress {
	&:before {
	  content: $icon-wordpress; 
	}
  }
  .icon-openid {
	&:before {
	  content: $icon-openid; 
	}
  }
  .icon-bank {
	&:before {
	  content: $icon-bank; 
	}
  }
  .icon-institution {
	&:before {
	  content: $icon-institution; 
	}
  }
  .icon-university {
	&:before {
	  content: $icon-university; 
	}
  }
  .icon-graduation-cap {
	&:before {
	  content: $icon-graduation-cap; 
	}
  }
  .icon-mortar-board {
	&:before {
	  content: $icon-mortar-board; 
	}
  }
  .icon-yahoo {
	&:before {
	  content: $icon-yahoo; 
	}
  }
  .icon-google {
	&:before {
	  content: $icon-google; 
	}
  }
  .icon-reddit {
	&:before {
	  content: $icon-reddit; 
	}
  }
  .icon-reddit-square {
	&:before {
	  content: $icon-reddit-square; 
	}
  }
  .icon-stumbleupon-circle {
	&:before {
	  content: $icon-stumbleupon-circle; 
	}
  }
  .icon-stumbleupon {
	&:before {
	  content: $icon-stumbleupon; 
	}
  }
  .icon-delicious {
	&:before {
	  content: $icon-delicious; 
	}
  }
  .icon-digg {
	&:before {
	  content: $icon-digg; 
	}
  }
  .icon-pied-piper-pp {
	&:before {
	  content: $icon-pied-piper-pp; 
	}
  }
  .icon-pied-piper-alt {
	&:before {
	  content: $icon-pied-piper-alt; 
	}
  }
  .icon-drupal {
	&:before {
	  content: $icon-drupal; 
	}
  }
  .icon-joomla {
	&:before {
	  content: $icon-joomla; 
	}
  }
  .icon-language {
	&:before {
	  content: $icon-language; 
	}
  }
  .icon-fax {
	&:before {
	  content: $icon-fax; 
	}
  }
  .icon-building {
	&:before {
	  content: $icon-building; 
	}
  }
  .icon-child {
	&:before {
	  content: $icon-child; 
	}
  }
  .icon-paw {
	&:before {
	  content: $icon-paw; 
	}
  }
  .icon-spoon {
	&:before {
	  content: $icon-spoon; 
	}
  }
  .icon-cube {
	&:before {
	  content: $icon-cube; 
	}
  }
  .icon-cubes {
	&:before {
	  content: $icon-cubes; 
	}
  }
  .icon-behance {
	&:before {
	  content: $icon-behance; 
	}
  }
  .icon-behance-square {
	&:before {
	  content: $icon-behance-square; 
	}
  }
  .icon-steam {
	&:before {
	  content: $icon-steam; 
	}
  }
  .icon-steam-square {
	&:before {
	  content: $icon-steam-square; 
	}
  }
  .icon-recycle {
	&:before {
	  content: $icon-recycle; 
	}
  }
  .icon-automobile {
	&:before {
	  content: $icon-automobile; 
	}
  }
  .icon-car {
	&:before {
	  content: $icon-car; 
	}
  }
  .icon-cab {
	&:before {
	  content: $icon-cab; 
	}
  }
  .icon-taxi {
	&:before {
	  content: $icon-taxi; 
	}
  }
  .icon-tree {
	&:before {
	  content: $icon-tree; 
	}
  }
  .icon-spotify {
	&:before {
	  content: $icon-spotify; 
	}
  }
  .icon-deviantart {
	&:before {
	  content: $icon-deviantart; 
	}
  }
  .icon-soundcloud {
	&:before {
	  content: $icon-soundcloud; 
	}
  }
  .icon-database {
	&:before {
	  content: $icon-database; 
	}
  }
  .icon-file-pdf-o {
	&:before {
	  content: $icon-file-pdf-o; 
	}
  }
  .icon-file-word-o {
	&:before {
	  content: $icon-file-word-o; 
	}
  }
  .icon-file-excel-o {
	&:before {
	  content: $icon-file-excel-o; 
	}
  }
  .icon-file-powerpoint-o {
	&:before {
	  content: $icon-file-powerpoint-o; 
	}
  }
  .icon-file-image-o {
	&:before {
	  content: $icon-file-image-o; 
	}
  }
  .icon-file-photo-o {
	&:before {
	  content: $icon-file-photo-o; 
	}
  }
  .icon-file-picture-o {
	&:before {
	  content: $icon-file-picture-o; 
	}
  }
  .icon-file-archive-o {
	&:before {
	  content: $icon-file-archive-o; 
	}
  }
  .icon-file-zip-o {
	&:before {
	  content: $icon-file-zip-o; 
	}
  }
  .icon-file-audio-o {
	&:before {
	  content: $icon-file-audio-o; 
	}
  }
  .icon-file-sound-o {
	&:before {
	  content: $icon-file-sound-o; 
	}
  }
  .icon-file-movie-o {
	&:before {
	  content: $icon-file-movie-o; 
	}
  }
  .icon-file-video-o {
	&:before {
	  content: $icon-file-video-o; 
	}
  }
  .icon-file-code-o {
	&:before {
	  content: $icon-file-code-o; 
	}
  }
  .icon-vine {
	&:before {
	  content: $icon-vine; 
	}
  }
  .icon-codepen {
	&:before {
	  content: $icon-codepen; 
	}
  }
  .icon-jsfiddle {
	&:before {
	  content: $icon-jsfiddle; 
	}
  }
  .icon-life-bouy {
	&:before {
	  content: $icon-life-bouy; 
	}
  }
  .icon-life-buoy {
	&:before {
	  content: $icon-life-buoy; 
	}
  }
  .icon-life-ring {
	&:before {
	  content: $icon-life-ring; 
	}
  }
  .icon-life-saver {
	&:before {
	  content: $icon-life-saver; 
	}
  }
  .icon-support {
	&:before {
	  content: $icon-support; 
	}
  }
  .icon-circle-o-notch {
	&:before {
	  content: $icon-circle-o-notch; 
	}
  }
  .icon-ra {
	&:before {
	  content: $icon-ra; 
	}
  }
  .icon-rebel {
	&:before {
	  content: $icon-rebel; 
	}
  }
  .icon-resistance {
	&:before {
	  content: $icon-resistance; 
	}
  }
  .icon-empire {
	&:before {
	  content: $icon-empire; 
	}
  }
  .icon-ge {
	&:before {
	  content: $icon-ge; 
	}
  }
  .icon-git-square {
	&:before {
	  content: $icon-git-square; 
	}
  }
  .icon-git {
	&:before {
	  content: $icon-git; 
	}
  }
  .icon-hacker-news {
	&:before {
	  content: $icon-hacker-news; 
	}
  }
  .icon-y-combinator-square {
	&:before {
	  content: $icon-y-combinator-square; 
	}
  }
  .icon-yc-square {
	&:before {
	  content: $icon-yc-square; 
	}
  }
  .icon-tencent-weibo {
	&:before {
	  content: $icon-tencent-weibo; 
	}
  }
  .icon-qq {
	&:before {
	  content: $icon-qq; 
	}
  }
  .icon-wechat {
	&:before {
	  content: $icon-wechat; 
	}
  }
  .icon-weixin {
	&:before {
	  content: $icon-weixin; 
	}
  }
  .icon-paper-plane {
	&:before {
	  content: $icon-paper-plane; 
	}
  }
  .icon-send {
	&:before {
	  content: $icon-send; 
	}
  }
  .icon-paper-plane-o {
	&:before {
	  content: $icon-paper-plane-o; 
	}
  }
  .icon-send-o {
	&:before {
	  content: $icon-send-o; 
	}
  }
  .icon-history {
	&:before {
	  content: $icon-history; 
	}
  }
  .icon-circle-thin {
	&:before {
	  content: $icon-circle-thin; 
	}
  }
  .icon-header {
	&:before {
	  content: $icon-header; 
	}
  }
  .icon-paragraph {
	&:before {
	  content: $icon-paragraph; 
	}
  }
  .icon-sliders {
	&:before {
	  content: $icon-sliders; 
	}
  }
  .icon-share-alt {
	&:before {
	  content: $icon-share-alt; 
	}
  }
  .icon-share-alt-square {
	&:before {
	  content: $icon-share-alt-square; 
	}
  }
  .icon-bomb {
	&:before {
	  content: $icon-bomb; 
	}
  }
  .icon-futbol-o {
	&:before {
	  content: $icon-futbol-o; 
	}
  }
  .icon-soccer-ball-o {
	&:before {
	  content: $icon-soccer-ball-o; 
	}
  }
  .icon-tty {
	&:before {
	  content: $icon-tty; 
	}
  }
  .icon-binoculars {
	&:before {
	  content: $icon-binoculars; 
	}
  }
  .icon-plug {
	&:before {
	  content: $icon-plug; 
	}
  }
  .icon-slideshare {
	&:before {
	  content: $icon-slideshare; 
	}
  }
  .icon-twitch {
	&:before {
	  content: $icon-twitch; 
	}
  }
  .icon-yelp {
	&:before {
	  content: $icon-yelp; 
	}
  }
  .icon-newspaper-o {
	&:before {
	  content: $icon-newspaper-o; 
	}
  }
  .icon-wifi {
	&:before {
	  content: $icon-wifi; 
	}
  }
  .icon-calculator {
	&:before {
	  content: $icon-calculator; 
	}
  }
  .icon-paypal {
	&:before {
	  content: $icon-paypal; 
	}
  }
  .icon-google-wallet {
	&:before {
	  content: $icon-google-wallet; 
	}
  }
  .icon-cc-visa {
	&:before {
	  content: $icon-cc-visa; 
	}
  }
  .icon-cc-mastercard {
	&:before {
	  content: $icon-cc-mastercard; 
	}
  }
  .icon-cc-discover {
	&:before {
	  content: $icon-cc-discover; 
	}
  }
  .icon-cc-amex {
	&:before {
	  content: $icon-cc-amex; 
	}
  }
  .icon-cc-paypal {
	&:before {
	  content: $icon-cc-paypal; 
	}
  }
  .icon-cc-stripe {
	&:before {
	  content: $icon-cc-stripe; 
	}
  }
  .icon-bell-slash {
	&:before {
	  content: $icon-bell-slash; 
	}
  }
  .icon-bell-slash-o {
	&:before {
	  content: $icon-bell-slash-o; 
	}
  }
  .icon-trash {
	&:before {
	  content: $icon-trash; 
	}
  }
  .icon-copyright {
	&:before {
	  content: $icon-copyright; 
	}
  }
  .icon-at {
	&:before {
	  content: $icon-at; 
	}
  }
  .icon-eyedropper {
	&:before {
	  content: $icon-eyedropper; 
	}
  }
  .icon-paint-brush {
	&:before {
	  content: $icon-paint-brush; 
	}
  }
  .icon-birthday-cake {
	&:before {
	  content: $icon-birthday-cake; 
	}
  }
  .icon-area-chart {
	&:before {
	  content: $icon-area-chart; 
	}
  }
  .icon-pie-chart {
	&:before {
	  content: $icon-pie-chart; 
	}
  }
  .icon-line-chart {
	&:before {
	  content: $icon-line-chart; 
	}
  }
  .icon-lastfm {
	&:before {
	  content: $icon-lastfm; 
	}
  }
  .icon-lastfm-square {
	&:before {
	  content: $icon-lastfm-square; 
	}
  }
  .icon-toggle-off {
	&:before {
	  content: $icon-toggle-off; 
	}
  }
  .icon-toggle-on {
	&:before {
	  content: $icon-toggle-on; 
	}
  }
  .icon-bicycle {
	&:before {
	  content: $icon-bicycle; 
	}
  }
  .icon-bus {
	&:before {
	  content: $icon-bus; 
	}
  }
  .icon-ioxhost {
	&:before {
	  content: $icon-ioxhost; 
	}
  }
  .icon-angellist {
	&:before {
	  content: $icon-angellist; 
	}
  }
  .icon-cc {
	&:before {
	  content: $icon-cc; 
	}
  }
  .icon-ils {
	&:before {
	  content: $icon-ils; 
	}
  }
  .icon-shekel {
	&:before {
	  content: $icon-shekel; 
	}
  }
  .icon-sheqel {
	&:before {
	  content: $icon-sheqel; 
	}
  }
  .icon-meanpath {
	&:before {
	  content: $icon-meanpath; 
	}
  }
  .icon-buysellads {
	&:before {
	  content: $icon-buysellads; 
	}
  }
  .icon-connectdevelop {
	&:before {
	  content: $icon-connectdevelop; 
	}
  }
  .icon-dashcube {
	&:before {
	  content: $icon-dashcube; 
	}
  }
  .icon-forumbee {
	&:before {
	  content: $icon-forumbee; 
	}
  }
  .icon-leanpub {
	&:before {
	  content: $icon-leanpub; 
	}
  }
  .icon-sellsy {
	&:before {
	  content: $icon-sellsy; 
	}
  }
  .icon-shirtsinbulk {
	&:before {
	  content: $icon-shirtsinbulk; 
	}
  }
  .icon-simplybuilt {
	&:before {
	  content: $icon-simplybuilt; 
	}
  }
  .icon-skyatlas {
	&:before {
	  content: $icon-skyatlas; 
	}
  }
  .icon-cart-plus {
	&:before {
	  content: $icon-cart-plus; 
	}
  }
  .icon-cart-arrow-down {
	&:before {
	  content: $icon-cart-arrow-down; 
	}
  }
  .icon-diamond {
	&:before {
	  content: $icon-diamond; 
	}
  }
  .icon-ship {
	&:before {
	  content: $icon-ship; 
	}
  }
  .icon-user-secret {
	&:before {
	  content: $icon-user-secret; 
	}
  }
  .icon-motorcycle {
	&:before {
	  content: $icon-motorcycle; 
	}
  }
  .icon-street-view {
	&:before {
	  content: $icon-street-view; 
	}
  }
  .icon-heartbeat {
	&:before {
	  content: $icon-heartbeat; 
	}
  }
  .icon-venus {
	&:before {
	  content: $icon-venus; 
	}
  }
  .icon-mars {
	&:before {
	  content: $icon-mars; 
	}
  }
  .icon-mercury {
	&:before {
	  content: $icon-mercury; 
	}
  }
  .icon-intersex {
	&:before {
	  content: $icon-intersex; 
	}
  }
  .icon-transgender {
	&:before {
	  content: $icon-transgender; 
	}
  }
  .icon-transgender-alt {
	&:before {
	  content: $icon-transgender-alt; 
	}
  }
  .icon-venus-double {
	&:before {
	  content: $icon-venus-double; 
	}
  }
  .icon-mars-double {
	&:before {
	  content: $icon-mars-double; 
	}
  }
  .icon-venus-mars {
	&:before {
	  content: $icon-venus-mars; 
	}
  }
  .icon-mars-stroke {
	&:before {
	  content: $icon-mars-stroke; 
	}
  }
  .icon-mars-stroke-v {
	&:before {
	  content: $icon-mars-stroke-v; 
	}
  }
  .icon-mars-stroke-h {
	&:before {
	  content: $icon-mars-stroke-h; 
	}
  }
  .icon-neuter {
	&:before {
	  content: $icon-neuter; 
	}
  }
  .icon-genderless {
	&:before {
	  content: $icon-genderless; 
	}
  }
  .icon-facebook-official {
	&:before {
	  content: $icon-facebook-official; 
	}
  }
  .icon-pinterest-p {
	&:before {
	  content: $icon-pinterest-p; 
	}
  }
  .icon-whatsapp {
	&:before {
	  content: $icon-whatsapp; 
	}
  }
  .icon-server {
	&:before {
	  content: $icon-server; 
	}
  }
  .icon-user-plus {
	&:before {
	  content: $icon-user-plus; 
	}
  }
  .icon-user-times {
	&:before {
	  content: $icon-user-times; 
	}
  }
  .icon-bed {
	&:before {
	  content: $icon-bed; 
	}
  }
  .icon-hotel {
	&:before {
	  content: $icon-hotel; 
	}
  }
  .icon-viacoin {
	&:before {
	  content: $icon-viacoin; 
	}
  }
  .icon-train {
	&:before {
	  content: $icon-train; 
	}
  }
  .icon-subway {
	&:before {
	  content: $icon-subway; 
	}
  }
  .icon-medium {
	&:before {
	  content: $icon-medium; 
	}
  }
  .icon-y-combinator {
	&:before {
	  content: $icon-y-combinator; 
	}
  }
  .icon-yc {
	&:before {
	  content: $icon-yc; 
	}
  }
  .icon-optin-monster {
	&:before {
	  content: $icon-optin-monster; 
	}
  }
  .icon-opencart {
	&:before {
	  content: $icon-opencart; 
	}
  }
  .icon-expeditedssl {
	&:before {
	  content: $icon-expeditedssl; 
	}
  }
  .icon-battery {
	&:before {
	  content: $icon-battery; 
	}
  }
  .icon-battery-4 {
	&:before {
	  content: $icon-battery-4; 
	}
  }
  .icon-battery-full {
	&:before {
	  content: $icon-battery-full; 
	}
  }
  .icon-battery-3 {
	&:before {
	  content: $icon-battery-3; 
	}
  }
  .icon-battery-three-quarters {
	&:before {
	  content: $icon-battery-three-quarters; 
	}
  }
  .icon-battery-2 {
	&:before {
	  content: $icon-battery-2; 
	}
  }
  .icon-battery-half {
	&:before {
	  content: $icon-battery-half; 
	}
  }
  .icon-battery-1 {
	&:before {
	  content: $icon-battery-1; 
	}
  }
  .icon-battery-quarter {
	&:before {
	  content: $icon-battery-quarter; 
	}
  }
  .icon-battery-0 {
	&:before {
	  content: $icon-battery-0; 
	}
  }
  .icon-battery-empty {
	&:before {
	  content: $icon-battery-empty; 
	}
  }
  .icon-mouse-pointer {
	&:before {
	  content: $icon-mouse-pointer; 
	}
  }
  .icon-i-cursor {
	&:before {
	  content: $icon-i-cursor; 
	}
  }
  .icon-object-group {
	&:before {
	  content: $icon-object-group; 
	}
  }
  .icon-object-ungroup {
	&:before {
	  content: $icon-object-ungroup; 
	}
  }
  .icon-sticky-note {
	&:before {
	  content: $icon-sticky-note; 
	}
  }
  .icon-sticky-note-o {
	&:before {
	  content: $icon-sticky-note-o; 
	}
  }
  .icon-cc-jcb {
	&:before {
	  content: $icon-cc-jcb; 
	}
  }
  .icon-cc-diners-club {
	&:before {
	  content: $icon-cc-diners-club; 
	}
  }
  .icon-clone {
	&:before {
	  content: $icon-clone; 
	}
  }
  .icon-balance-scale {
	&:before {
	  content: $icon-balance-scale; 
	}
  }
  .icon-hourglass-o {
	&:before {
	  content: $icon-hourglass-o; 
	}
  }
  .icon-hourglass-1 {
	&:before {
	  content: $icon-hourglass-1; 
	}
  }
  .icon-hourglass-start {
	&:before {
	  content: $icon-hourglass-start; 
	}
  }
  .icon-hourglass-2 {
	&:before {
	  content: $icon-hourglass-2; 
	}
  }
  .icon-hourglass-half {
	&:before {
	  content: $icon-hourglass-half; 
	}
  }
  .icon-hourglass-3 {
	&:before {
	  content: $icon-hourglass-3; 
	}
  }
  .icon-hourglass-end {
	&:before {
	  content: $icon-hourglass-end; 
	}
  }
  .icon-hourglass {
	&:before {
	  content: $icon-hourglass; 
	}
  }
  .icon-hand-grab-o {
	&:before {
	  content: $icon-hand-grab-o; 
	}
  }
  .icon-hand-rock-o {
	&:before {
	  content: $icon-hand-rock-o; 
	}
  }
  .icon-hand-paper-o {
	&:before {
	  content: $icon-hand-paper-o; 
	}
  }
  .icon-hand-stop-o {
	&:before {
	  content: $icon-hand-stop-o; 
	}
  }
  .icon-hand-scissors-o {
	&:before {
	  content: $icon-hand-scissors-o; 
	}
  }
  .icon-hand-lizard-o {
	&:before {
	  content: $icon-hand-lizard-o; 
	}
  }
  .icon-hand-spock-o {
	&:before {
	  content: $icon-hand-spock-o; 
	}
  }
  .icon-hand-pointer-o {
	&:before {
	  content: $icon-hand-pointer-o; 
	}
  }
  .icon-hand-peace-o {
	&:before {
	  content: $icon-hand-peace-o; 
	}
  }
  .icon-trademark {
	&:before {
	  content: $icon-trademark; 
	}
  }
  .icon-registered {
	&:before {
	  content: $icon-registered; 
	}
  }
  .icon-creative-commons {
	&:before {
	  content: $icon-creative-commons; 
	}
  }
  .icon-gg {
	&:before {
	  content: $icon-gg; 
	}
  }
  .icon-gg-circle {
	&:before {
	  content: $icon-gg-circle; 
	}
  }
  .icon-tripadvisor {
	&:before {
	  content: $icon-tripadvisor; 
	}
  }
  .icon-odnoklassniki {
	&:before {
	  content: $icon-odnoklassniki; 
	}
  }
  .icon-odnoklassniki-square {
	&:before {
	  content: $icon-odnoklassniki-square; 
	}
  }
  .icon-get-pocket {
	&:before {
	  content: $icon-get-pocket; 
	}
  }
  .icon-wikipedia-w {
	&:before {
	  content: $icon-wikipedia-w; 
	}
  }
  .icon-safari {
	&:before {
	  content: $icon-safari; 
	}
  }
  .icon-chrome {
	&:before {
	  content: $icon-chrome; 
	}
  }
  .icon-firefox {
	&:before {
	  content: $icon-firefox; 
	}
  }
  .icon-opera {
	&:before {
	  content: $icon-opera; 
	}
  }
  .icon-internet-explorer {
	&:before {
	  content: $icon-internet-explorer; 
	}
  }
  .icon-television {
	&:before {
	  content: $icon-television; 
	}
  }
  .icon-tv {
	&:before {
	  content: $icon-tv; 
	}
  }
  .icon-contao {
	&:before {
	  content: $icon-contao; 
	}
  }
  .icon-500px {
	&:before {
	  content: $icon-500px; 
	}
  }
  .icon-amazon {
	&:before {
	  content: $icon-amazon; 
	}
  }
  .icon-calendar-plus-o {
	&:before {
	  content: $icon-calendar-plus-o; 
	}
  }
  .icon-calendar-minus-o {
	&:before {
	  content: $icon-calendar-minus-o; 
	}
  }
  .icon-calendar-times-o {
	&:before {
	  content: $icon-calendar-times-o; 
	}
  }
  .icon-calendar-check-o {
	&:before {
	  content: $icon-calendar-check-o; 
	}
  }
  .icon-industry {
	&:before {
	  content: $icon-industry; 
	}
  }
  .icon-map-pin {
	&:before {
	  content: $icon-map-pin; 
	}
  }
  .icon-map-signs {
	&:before {
	  content: $icon-map-signs; 
	}
  }
  .icon-map-o {
	&:before {
	  content: $icon-map-o; 
	}
  }
  .icon-map {
	&:before {
	  content: $icon-map; 
	}
  }
  .icon-commenting {
	&:before {
	  content: $icon-commenting; 
	}
  }
  .icon-commenting-o {
	&:before {
	  content: $icon-commenting-o; 
	}
  }
  .icon-houzz {
	&:before {
	  content: $icon-houzz; 
	}
  }
  .icon-vimeo {
	&:before {
	  content: $icon-vimeo; 
	}
  }
  .icon-black-tie {
	&:before {
	  content: $icon-black-tie; 
	}
  }
  .icon-fonticons {
	&:before {
	  content: $icon-fonticons; 
	}
  }
  .icon-reddit-alien {
	&:before {
	  content: $icon-reddit-alien; 
	}
  }
  .icon-edge {
	&:before {
	  content: $icon-edge; 
	}
  }
  .icon-credit-card-alt {
	&:before {
	  content: $icon-credit-card-alt; 
	}
  }
  .icon-codiepie {
	&:before {
	  content: $icon-codiepie; 
	}
  }
  .icon-modx {
	&:before {
	  content: $icon-modx; 
	}
  }
  .icon-fort-awesome {
	&:before {
	  content: $icon-fort-awesome; 
	}
  }
  .icon-usb {
	&:before {
	  content: $icon-usb; 
	}
  }
  .icon-product-hunt {
	&:before {
	  content: $icon-product-hunt; 
	}
  }
  .icon-mixcloud {
	&:before {
	  content: $icon-mixcloud; 
	}
  }
  .icon-scribd {
	&:before {
	  content: $icon-scribd; 
	}
  }
  .icon-pause-circle {
	&:before {
	  content: $icon-pause-circle; 
	}
  }
  .icon-pause-circle-o {
	&:before {
	  content: $icon-pause-circle-o; 
	}
  }
  .icon-stop-circle {
	&:before {
	  content: $icon-stop-circle; 
	}
  }
  .icon-stop-circle-o {
	&:before {
	  content: $icon-stop-circle-o; 
	}
  }
  .icon-shopping-bag {
	&:before {
	  content: $icon-shopping-bag; 
	}
  }
  .icon-shopping-basket {
	&:before {
	  content: $icon-shopping-basket; 
	}
  }
  .icon-hashtag {
	&:before {
	  content: $icon-hashtag; 
	}
  }
  .icon-bluetooth {
	&:before {
	  content: $icon-bluetooth; 
	}
  }
  .icon-bluetooth-b {
	&:before {
	  content: $icon-bluetooth-b; 
	}
  }
  .icon-percent {
	&:before {
	  content: $icon-percent; 
	}
  }
  .icon-gitlab {
	&:before {
	  content: $icon-gitlab; 
	}
  }
  .icon-wpbeginner {
	&:before {
	  content: $icon-wpbeginner; 
	}
  }
  .icon-wpforms {
	&:before {
	  content: $icon-wpforms; 
	}
  }
  .icon-envira {
	&:before {
	  content: $icon-envira; 
	}
  }
  .icon-universal-access {
	&:before {
	  content: $icon-universal-access; 
	}
  }
  .icon-wheelchair-alt {
	&:before {
	  content: $icon-wheelchair-alt; 
	}
  }
  .icon-question-circle-o {
	&:before {
	  content: $icon-question-circle-o; 
	}
  }
  .icon-blind {
	&:before {
	  content: $icon-blind; 
	}
  }
  .icon-audio-description {
	&:before {
	  content: $icon-audio-description; 
	}
  }
  .icon-volume-control-phone {
	&:before {
	  content: $icon-volume-control-phone; 
	}
  }
  .icon-braille {
	&:before {
	  content: $icon-braille; 
	}
  }
  .icon-assistive-listening-systems {
	&:before {
	  content: $icon-assistive-listening-systems; 
	}
  }
  .icon-american-sign-language-interpreting {
	&:before {
	  content: $icon-american-sign-language-interpreting; 
	}
  }
  .icon-asl-interpreting {
	&:before {
	  content: $icon-asl-interpreting; 
	}
  }
  .icon-deaf {
	&:before {
	  content: $icon-deaf; 
	}
  }
  .icon-deafness {
	&:before {
	  content: $icon-deafness; 
	}
  }
  .icon-hard-of-hearing {
	&:before {
	  content: $icon-hard-of-hearing; 
	}
  }
  .icon-glide {
	&:before {
	  content: $icon-glide; 
	}
  }
  .icon-glide-g {
	&:before {
	  content: $icon-glide-g; 
	}
  }
  .icon-sign-language {
	&:before {
	  content: $icon-sign-language; 
	}
  }
  .icon-signing {
	&:before {
	  content: $icon-signing; 
	}
  }
  .icon-low-vision {
	&:before {
	  content: $icon-low-vision; 
	}
  }
  .icon-viadeo {
	&:before {
	  content: $icon-viadeo; 
	}
  }
  .icon-viadeo-square {
	&:before {
	  content: $icon-viadeo-square; 
	}
  }
  .icon-snapchat {
	&:before {
	  content: $icon-snapchat; 
	}
  }
  .icon-snapchat-ghost {
	&:before {
	  content: $icon-snapchat-ghost; 
	}
  }
  .icon-snapchat-square {
	&:before {
	  content: $icon-snapchat-square; 
	}
  }
  .icon-pied-piper {
	&:before {
	  content: $icon-pied-piper; 
	}
  }
  .icon-first-order {
	&:before {
	  content: $icon-first-order; 
	}
  }
  .icon-yoast {
	&:before {
	  content: $icon-yoast; 
	}
  }
  .icon-themeisle {
	&:before {
	  content: $icon-themeisle; 
	}
  }
  .icon-google-plus-circle {
	&:before {
	  content: $icon-google-plus-circle; 
	}
  }
  .icon-google-plus-official {
	&:before {
	  content: $icon-google-plus-official; 
	}
  }
  .icon-fa {
	&:before {
	  content: $icon-fa; 
	}
  }
  .icon-font-awesome {
	&:before {
	  content: $icon-font-awesome; 
	}
  }
  .icon-handshake-o {
	&:before {
	  content: $icon-handshake-o; 
	}
  }
  .icon-envelope-open {
	&:before {
	  content: $icon-envelope-open; 
	}
  }
  .icon-envelope-open-o {
	&:before {
	  content: $icon-envelope-open-o; 
	}
  }
  .icon-linode {
	&:before {
	  content: $icon-linode; 
	}
  }
  .icon-address-book {
	&:before {
	  content: $icon-address-book; 
	}
  }
  .icon-address-book-o {
	&:before {
	  content: $icon-address-book-o; 
	}
  }
  .icon-address-card {
	&:before {
	  content: $icon-address-card; 
	}
  }
  .icon-vcard {
	&:before {
	  content: $icon-vcard; 
	}
  }
  .icon-address-card-o {
	&:before {
	  content: $icon-address-card-o; 
	}
  }
  .icon-vcard-o {
	&:before {
	  content: $icon-vcard-o; 
	}
  }
  .icon-user-circle {
	&:before {
	  content: $icon-user-circle; 
	}
  }
  .icon-user-circle-o {
	&:before {
	  content: $icon-user-circle-o; 
	}
  }
  .icon-user-o {
	&:before {
	  content: $icon-user-o; 
	}
  }
  .icon-id-badge {
	&:before {
	  content: $icon-id-badge; 
	}
  }
  .icon-drivers-license {
	&:before {
	  content: $icon-drivers-license; 
	}
  }
  .icon-id-card {
	&:before {
	  content: $icon-id-card; 
	}
  }
  .icon-drivers-license-o {
	&:before {
	  content: $icon-drivers-license-o; 
	}
  }
  .icon-id-card-o {
	&:before {
	  content: $icon-id-card-o; 
	}
  }
  .icon-quora {
	&:before {
	  content: $icon-quora; 
	}
  }
  .icon-free-code-camp {
	&:before {
	  content: $icon-free-code-camp; 
	}
  }
  .icon-telegram {
	&:before {
	  content: $icon-telegram; 
	}
  }
  .icon-thermometer {
	&:before {
	  content: $icon-thermometer; 
	}
  }
  .icon-thermometer-4 {
	&:before {
	  content: $icon-thermometer-4; 
	}
  }
  .icon-thermometer-full {
	&:before {
	  content: $icon-thermometer-full; 
	}
  }
  .icon-thermometer-3 {
	&:before {
	  content: $icon-thermometer-3; 
	}
  }
  .icon-thermometer-three-quarters {
	&:before {
	  content: $icon-thermometer-three-quarters; 
	}
  }
  .icon-thermometer-2 {
	&:before {
	  content: $icon-thermometer-2; 
	}
  }
  .icon-thermometer-half {
	&:before {
	  content: $icon-thermometer-half; 
	}
  }
  .icon-thermometer-1 {
	&:before {
	  content: $icon-thermometer-1; 
	}
  }
  .icon-thermometer-quarter {
	&:before {
	  content: $icon-thermometer-quarter; 
	}
  }
  .icon-thermometer-0 {
	&:before {
	  content: $icon-thermometer-0; 
	}
  }
  .icon-thermometer-empty {
	&:before {
	  content: $icon-thermometer-empty; 
	}
  }
  .icon-shower {
	&:before {
	  content: $icon-shower; 
	}
  }
  .icon-bath {
	&:before {
	  content: $icon-bath; 
	}
  }
  .icon-bathtub {
	&:before {
	  content: $icon-bathtub; 
	}
  }
  .icon-s15 {
	&:before {
	  content: $icon-s15; 
	}
  }
  .icon-podcast {
	&:before {
	  content: $icon-podcast; 
	}
  }
  .icon-window-maximize {
	&:before {
	  content: $icon-window-maximize; 
	}
  }
  .icon-window-minimize {
	&:before {
	  content: $icon-window-minimize; 
	}
  }
  .icon-window-restore {
	&:before {
	  content: $icon-window-restore; 
	}
  }
  .icon-times-rectangle {
	&:before {
	  content: $icon-times-rectangle; 
	}
  }
  .icon-window-close {
	&:before {
	  content: $icon-window-close; 
	}
  }
  .icon-times-rectangle-o {
	&:before {
	  content: $icon-times-rectangle-o; 
	}
  }
  .icon-window-close-o {
	&:before {
	  content: $icon-window-close-o; 
	}
  }
  .icon-bandcamp {
	&:before {
	  content: $icon-bandcamp; 
	}
  }
  .icon-grav {
	&:before {
	  content: $icon-grav; 
	}
  }
  .icon-etsy {
	&:before {
	  content: $icon-etsy; 
	}
  }
  .icon-imdb {
	&:before {
	  content: $icon-imdb; 
	}
  }
  .icon-ravelry {
	&:before {
	  content: $icon-ravelry; 
	}
  }
  .icon-eercast {
	&:before {
	  content: $icon-eercast; 
	}
  }
  .icon-microchip {
	&:before {
	  content: $icon-microchip; 
	}
  }
  .icon-snowflake-o {
	&:before {
	  content: $icon-snowflake-o; 
	}
  }
  .icon-superpowers {
	&:before {
	  content: $icon-superpowers; 
	}
  }
  .icon-wpexplorer {
	&:before {
	  content: $icon-wpexplorer; 
	}
  }
  .icon-meetup {
	&:before {
	  content: $icon-meetup; 
	}
  }
  
  