.register{
	overflow: hidden;
	background-position: top center;
}
.register-wizard.login {
	.card{
		padding: 50px 90px;
	}
}

.register-wizard {
    width: 100%;
    height: 100vh;
	background: rgba(0, 0, 0, 0.74);
	overflow: hidden;
	.card{
		padding: 50px 100px;
		text-align: center;
		box-shadow: 0 0 0 0;
		.card-header{
			background: transparent;
			border: 0;
			.logo{
				width: 200px;
				display: inline-block;
				margin-bottom: 15px;
				padding: 15px 0;
				border-bottom:2px solid #CCC ;
			}
		}
		.socialmedia-register{
			margin-bottom: 30px;
			.btn{
				color: #fff;
				width: 100%;
				font-size: 14px;
				height: 40px;
				&-facebook{
					background: #4584EA;
					margin-right: 5px;
				}
				&-google{
					background: #DB4437;
				}
			}
		}
		.form-register{
			input{
				border-radius: 100px !important;
			}
		}
		.text{
			a{
				font-weight: 600;
			}
		}
		.login{
			p{
				display: inline-block;
			}
		}
	}
}

.stepwizard{
	width: 100%;
	position: absolute;

}

.form-wizard {
	color: #888888;
	padding: 30px;
	.wizard-form-radio {
		display: inline-block;
		margin-left: 5px;
		position: relative;
		input[type="radio"] {
			-webkit-appearance: none;
			-moz-appearance: none;
			-ms-appearance: none;
			-o-appearance: none;
			appearance: none;
			background-color: #dddddd;
			height: 25px;
			width: 25px;
			display: inline-block;
			vertical-align: middle;
			border-radius: 50%;
			position: relative;
			cursor: pointer;
			&:focus {
				outline: 0;
			}
			&:checked {
				background-color: #fb1647;
				&::before {
					content: "";
					position: absolute;
					width: 10px;
					height: 10px;
					display: inline-block;
					background-color: #ffffff;
					border-radius: 50%;
					left: 1px;
					right: 0;
					margin: 0 auto;
					top: 8px;
				  }
				  &::after {
					content: "";
					display: inline-block;
					webkit-animation: click-radio-wave 0.65s;
					-moz-animation: click-radio-wave 0.65s;
					animation: click-radio-wave 0.65s;
					background: #000000;
					content: '';
					display: block;
					position: relative;
					z-index: 100;
					border-radius: 50%;
				  }
			  }
			  ~ label {
				padding-left: 10px;
				cursor: pointer;
			}
		}
	}
	.form-wizard-header {
		text-align: center;
		position: absolute;
		top: 98px;
		width: 200px;
	}
	.form-wizard-next-btn, .form-wizard-previous-btn, .form-wizard-submit {
		background-color: #d65470;
		color: #ffffff;
		display: inline-block;
		min-width: 100px;
		min-width: 120px;
		padding: 10px;
		text-align: center;
		&:hover, &:focus{
			color: #ffffff;
			opacity: 0.6;
			text-decoration: none;
		}
	}
	.form-radio {
		width: 12.2868%;
		margin: 0 1%;
	}
	.button-previous {
		position: absolute;
		top: 50px;
		z-index: 2;
		font-size: 22px;
		left: 50px;
		color: $defaultTitleColor;
	}
	.form-title {
		margin-top: 41px;
		margin-bottom: 15px;
		h4{
			font-weight: 700;
			margin-bottom: 0px;
			color: $defaultTextColor;
		}
	}
	.wizard-fieldset {
		transition: 0.5s ease all;
		&:nth-child(2n+1) {
			position: absolute;
			max-width: 1382px;
			transform: translateY(-50%) scale(0.5);
			top: 50%;
			right: -789px;
			opacity: 0.5;
		}
		
		&:nth-child(2n+0) {
			position: absolute;
			max-width: 595px;
			top: 50%;
			transform: translateY(-50%) scale(0.8);
			left: -375px;
			opacity: 0.5;
		}
		&.active {
			opacity: 1;
			left: 50%;
			transform: translate(-50%, -50%) scale(1);
		}
	}
	.wizard-form-error {
		display: none;
		/* background-color: #d70b0b; */
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 50px;
		width: 100%;
		z-index: 0;
		border-radius: 100px;
		border-bottom: 2px solid #ff0000;
		top: 5px;
	}
	.form-group {
		position: relative;
		margin: 25px 0;
		.form-control {
			font-weight: 300;
			height: auto !important;
			padding: 15px;
			color: #888888;
			background-color: #f1f1f1;
			border: none;
			position: relative;
			z-index: 3;
			&:focus {
				box-shadow: none;
			}
		}
	}
	.wizard-form-text-label {
		position: absolute;
		left: 10px;
		top: 16px;
		transition: 0.2s linear all;
	}
	.focus-input {
		.wizard-form-text-label {
			color: #d65470;
			top: -18px;
			transition: 0.2s linear all;
			font-size: 12px;
		}
	}
	.form-wizard-steps {
		margin: 30px 0;
		text-align: center;
		li {
			width: 49%;
			float: none;
			position: relative;
			display: inline-block;
			a {
				background-color: #dddddd;
				border-radius: 50%;
				display: inline-block;
				height: 15px;
				line-height: 15px;
				position: relative;
				text-align: center;
				width: 15px;
				z-index: 1;
				&::after {
					background-color: #f3f3f3;
					content: "";
					height: 2px;
					left: 0;
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					width: 52px;
					border-bottom: 1px solid #dddddd;
					border-top: 1px solid #dddddd;
				}
				&.active, &.activated{
					background-color: $defaultButtonColor;
					color: #ffffff;
					
					&:after {
						background-color:  $defaultButtonColor;
						left: 100%;
						border-color:  $defaultButtonColor;
					}
				}
			}
			&:last-child{
				a{
					&:after{
						left: -41px;
					}
				}
			}
		}
	}
	.wizard-password-eye {
		position: absolute;
		right: 32px;
		top: 50%;
		transform: translateY(-50%);
		cursor: pointer;
	}
	label{
		width: 100%;
	}
	input[type="radio"] {
		display: none;
		&:checked + span {
			box-shadow: 3px 4px 6px 0px rgba(0, 0, 0, 0.14);
			background: #FFF;
			transform: scale(1.1);
			
			&:after {
				left: 25px;
			}
			&:before {
				background: $defaultTitleColor;
			}
			.img{
				img{
					filter: grayscale(0%);
					-webkit-transition: .3s ease-in-out all;
					transition: .3s ease-in-out all;
				}
			}
		}
		& + span {
			cursor: pointer;
			user-select: none;
			display: inline-block;
			position: relative;
			vertical-align: middle;
			background: linear-gradient(#F2F2F2, #FFF);
			width: 100%;
			padding: 0;
			width: 100%;
			margin-bottom: 15px;
			border-radius: 5px;
			height: 16vh;
			border-radius: 30px;
			transition: .3s ease-in-out all;
			.img{
				position: relative;
				height: 8vh;
				img{
					max-width: 60%;
					position: absolute;
					top:50%;
					left: 50%;
					transform: translate(-50%, -50%);
					filter: grayscale(100%);
					-webkit-transition: .3s ease-in-out all;
					transition: .3s ease-in-out all;
					max-height: 80%;
				}
			}
			.title{
				position: relative;
				height: 20px;
				width: 100%;
				text-align: center;
				p{
					width: 100%;
					position: absolute;
					top:50%;
					transform: translateY(-50%);
					font-size: 1.3vh;
					font-weight: 600;
				}
			}
			&:after {
				display: none;
			}
			&:before {
				display: none;
			}
		}
	}
}
  @keyframes click-radio-wave {
	0% {
	  width: 25px;
	  height: 25px;
	  opacity: 0.35;
	  position: relative;
	}
	100% {
	  width: 60px;
	  height: 60px;
	  margin-left: -15px;
	  margin-top: -15px;
	  opacity: 0.0;
	}
  }

.register-wizard .card .card-header .logo img {
    max-height: 9vh;
	max-width: 19vh;
}
  @media only screen and (max-width:991px){
	.wizard-fieldset{
		width: 100%;
		flex: inherit;
	}
  }

  @media screen and (max-width: 767px) {
	.wizard-content-left {
	  height: auto;
	}
  }
  @media only screen and (max-width:1441px){
	.form-wizard-header{
		top: 74px !important;
	}
  }
  @media only screen and (max-width:1280px){
	.form-wizard-header{
		top: 27px !important;
	}
  }
@media only screen and (max-width:992px){
	.register{
		overflow: inherit;
	}
	.register-wizard{
		padding-bottom: 15px;
		height: auto;
		.form-wizard{
			padding: 0;
			.form-wizard-header{
				top:15px;
				left:50%;
				transform: translateX(-50%);
				z-index: 2;
			}
		}
		.wizard-fieldset:nth-child(2n+0){
			position: static;
			transform: translate(0);
			margin-top: 30px;
		}
		.wizard-fieldset:nth-child(2n+1){
			position: static;
			transform: translate(0);
			margin-top: 30px;
			
		}
		.wizard-fieldset{
			display: none;
			&.active{
				display: block;
			}
		}
		.card{
			padding: 15px;
			border: 0;
		}
		.form-radio{
			width: 48%;
		}
	}
}

@media only screen and (max-width:768px){
	.register{
		background-image: none !important;
		.register-wizard{
			background:transparent;
		}
		.container{
			padding: 0;
		}
		.card{
			padding:13vh 15px;
		}
	}
	.fixed-button {
		position: fixed !important;
		bottom: 0;
		background: white;
		width: 100%;
		padding: 21px;
		text-align: center;
		margin-bottom: 0 !important;
		left: 0;
		display: none;
		box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.29);
		&.mobile-b{
		}
	}
	.form-scroll {
		padding: 0 18px;
	}
	  
	  .register-wizard .wizard-fieldset:nth-child(2n+0){
		margin-top: 0;
	}
	  .register-wizard .wizard-fieldset:nth-child(2n+1){
		  margin-top: 0;
	  }
	  .register-wizard .card .socialmedia-register{
		  margin-bottom: 0;
	  }
	  .register-wizard .card .socialmedia-register .btn{
		  font-size: 13px;
	  }

	  .form-wizard input[type="radio"]:checked + span {
		box-shadow: 3px 4px 6px 0px rgba(0, 0, 0, 0.14);
		background: #FFF;
		transform: scale(1.1);
		position: relative;
		border: 1px solid #FCC50A;
		z-index: 1;
	}
}

@media only screen and (max-width:420px){
	.card{
		padding:6vh 15px !important;
	}
}

@media only screen and (max-width:320px){
	.card{
		.socialmedia-register .btn{
			font-size: 11px !important;
		}
	}
}
  

