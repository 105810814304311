.btn{
	padding: 5px 20px;
	&-green{
		background: lighten($green, 40%);
		color: darken($green, 20%);
		&:hover{
			background: darken($green, 0%);
			color: lighten($green, 100%);
		}
	}
	&-blue{
		background: lighten($blue, 40%);
		color: darken($blue, 20%);
		&:hover{
			background: darken($blue, 0%);
			color: lighten($blue, 100%);
		}
	}
	&-pink{
		background: lighten($pink, 25%);
		color: darken($pink, 20%);
		&:hover{
			background: darken($pink, 0%);
			color: lighten($pink, 100%);
		}
	}
	&-yellow{
		background: lighten($yellow, 40%);
		color: darken($yellow, 20%);
		&:hover{
			background: darken($yellow, 0%);
			color: lighten($yellow, 100%);
		}
	}
	&-social {
		display:flex;
		background:transparant;
		border:2px solid #4584EA;
		border-radius:100px !important;
		color:#4584EA;
		justify-content: center;
		align-items: center;
		margin-bottom: 20px;
		padding: 9px;
		
		i {
			margin-right: .5rem;
		}
		
		&:hover {
			background-color: #4584EA;
			color: $white;
		}
		&.btn-google {
			border-color: #DB4437;
			color: #DB4437;
			&:hover {
				background-color: #DB4437;
			color: $white;
			}
		}
		&.btn-register {
			border-color:#FF055B;
			color:#FF055B;
			&:hover {
				background-color: #FF055B;
			color: $white;
			}
		}
	}
}