.fixed{
	position: fixed;
	width: 100%;
}
.home{
	section{
		&.section{
			padding: 0;
			z-index: 0;
			.section-header{
				padding: 30px 0 0;
				&:before{
					display: none;
				}
				h2{
					font-weight: 600;
				}
			}
			&.category{
				.section-header{
					position: relative;
					width: 100%;
					height: 75px;
					z-index: 0;
					h2{
						background: $white;
						padding-right:30px;
						position: relative;
						z-index: 1;
					}
					hr{
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						width: 100%;
					}
					.btn{
						padding-left:30px;
						background: $white;
						position: relative;
						z-index: 1;
						&:hover{
							color: $defaultButtonColor;
						}
					}
				}
			}
			.section-box{
				background:$gray-100;
				border-radius: 20px;
				width: calc(100% + 30px);
				padding: 15px;
				margin-left: -15px;
				height: 390px;
				margin-bottom: 100px;
			}
			.section-container{
				position: relative;
			}
			#main-slider{
				width: 100%;
				margin-top: 15px;
				.swiper-slide{
					border-radius: 20px;
					overflow: hidden;
					img{
						width: 100%;
					}
				}
				#main-pagination {
					position: absolute;
					top: 50%;
					right: 15px;
					display: inline-block;
					width: 15px;
					z-index: 2;
					transform: translateY(-50%);
					.slide-pagination{
						width: 10px;
						height: 10px;
						display: inline-block;
						background-color: $gray-200;
						border-radius: 100%;
						&.active{
							background-color: $defaultButtonColor;
						}
					}
				}
			}
			#best-slider{
				position: relative;
				width: calc(100% + 30px);
				margin-left: -15px;
				&.swiper-container{
					/* overflow: initial; */
					/* .swiper-slide-active, .swiper-slide-next {
						opacity:1 !important;	
					}
					.swiper-slide {
						opacity: 0;
					} */
				}
				.swiper-slide{
					padding: 14px 15px;
					.slider-box{
						background: $white;
						box-shadow: 4px 7px 14px 0px rgba(0, 0, 0, 0.05);
						border-radius: 30px;
						overflow: hidden;
						text-align: left;
						.tab-img{
							height: 275px;
							position: relative;
							overflow: hidden;
							img{
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
							}
						}
						.slide-img{
							width: 100px;
							margin-bottom: 15px;
							height: 64px;
							position: relative;
							overflow: hidden;
							img{
								width: 100%;
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%,-50%);
							}
						}
						.slide-description{
							h3{
								color: $defaultTitleColor;
								margin-bottom: 15px;
							}
							p{
								font-size: 14px;
								height: 64px;
							}
						}
						a{

						}
					}
				}
			}
			.swiper-button-prev{
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: 50px;
				height: 50px;
				border: 1px solid $gray-400;
				color: $gray-400;
				left: -60px;
				padding: 4px;
				border-radius: 100%;
				font-size: 28px;
				z-index: 2;
				&:hover{
					color: $defaultButtonColor;
					border-color: $defaultButtonColor;
				}
			}
			.swiper-button-next{
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: 50px;
				height: 50px;
				border: 1px solid $gray-400;
				color: $gray-400;
				right: -60px;
				padding: 4px;
				border-radius: 100%;
				font-size: 28px;
				z-index: 2;
				&:hover{
					color: $defaultButtonColor;
					border-color: $defaultButtonColor;
				}
			}
		}
	}
}
.btn{
	&-outline{
		&-red{
			color: $defaultButtonColor;
			border-color: $defaultButtonColor;
			border-width: 2px;
			&:hover{
				background: $defaultButtonColor;
				color: $white;
				box-shadow: 4px 7px 26px 0px rgba(0, 0, 0, 0.24);
			}
		}
	}
}
.card{
	background: $white;
	box-shadow: $boxShadow;
	border: 0;
	border-radius: 15px;
	overflow: hidden;
	margin-bottom: 15px;
	.card-header{
		position: relative;
		background: $white;
		border: 0;
		.card-img{
			height: 105px;
			position: relative;
			img{
				position: absolute;
				max-height: 126%;
    			max-width: 78%;
				top:63%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
		.badge{
			position: absolute;
			top: 15px;
			right: 0;
			background: $defaultButtonColor;
			color: $white;
			padding: 8px 20px;
			border-radius: 0;
			&:before{
				content: '';
				width: 0px;
				height: 0px;
				border-left: 16px solid $white;
				border-right: 8px solid $defaultButtonColor;
				border-top: 14px solid $defaultButtonColor;
				border-bottom: 14px solid $defaultButtonColor;
				position: absolute;
				left: -22px;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
	.card-body{
		h4{
			color: $defaultTitleColor;
		}
		p{
			font-size: 14px;
			height: 43px;
		}
	}
	.card-footer{
		padding: 0;
		border: 0;
		.btn{
			border-radius: 0;
			padding: 15px 0;
			border: 0;
			&:hover{
				background: $defaultButtonColor;
				color: $white;
			}
		}
	}
}
.category-slider{
	width: 100%;
}

#best-pagination{
	width: 100%;
	text-align: center;
	.slide-pagination{
		width: 10px;
		height: 10px;
		background: $gray-200;
		display: inline-block;
		border-radius: 100%;
		margin: 0 2px;
		cursor: pointer;
		&.active{
			background: $defaultButtonColor;
		}
	}
}

.popular-slider{
	padding: 0 15px;
	width: 100%;
	
}

.popular-button-prev{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 50px;
	height: 50px;
	border: 1px solid $gray-400;
	color: $gray-400;
	left: -60px;
	padding: 4px;
	border-radius: 100%;
	font-size: 28px;
	z-index: 2;
	&:hover{
		color: $defaultButtonColor;
		border-color: $defaultButtonColor;
	}
}
.popular-button-next{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 50px;
	height: 50px;
	border: 1px solid $gray-400;
	color: $gray-400;
	right: -60px;
	padding: 4px;
	border-radius: 100%;
	font-size: 28px;
	z-index: 2;
	&:hover{
		color: $defaultButtonColor;
		border-color: $defaultButtonColor;
	}
}

@media only screen and (max-width:992px){
	section{
		.card{
			padding: 0 !important;
		}
	}
	.section-box{
		height: auto !important;
		margin-bottom: 15px !important;
	}
}

@media only screen and (max-width:768px){
	.home{
		section{
			&.section{
				.section-header{
					&:before{
					}
					h2{
					}
				}
				&.category{
					.section-header{
						h2{
						}
						hr{
						}
						.btn{
							&:hover{
							}
						}
					}
				}
				.section-box{
				}
				.section-container{
				}
				#main-slider{
					.swiper-slide{
						img{
						}
					}
					#main-pagination {
						.slide-pagination{
							display: block;
							margin: 3px 0;
							&.active{
							}
						}
					}
				}
				#best-slider{
					&.swiper-container{
						/* overflow: initial; */
						/* .swiper-slide-active, .swiper-slide-next {
							opacity:1 !important;	
						}
						.swiper-slide {
							opacity: 0;
						} */
					}
					.swiper-slide{
						.slider-box{
							.tab-img{
								img{
								}
							}
							.slide-img{
								img{
								}
							}
							.slide-description{
								h3{
								}
								p{
								}
							}
							a{
	
							}
						}
					}
				}
				.swiper-button-prev{
					&:hover{
					}
				}
				.swiper-button-next{
					&:hover{
					}
				}
			}
		}
	}
	.slider-box{
		text-align: center;
		.tab-img{
			position: relative;
			height: 159px !important;
			overflow: hidden;
			img{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				height: 100% !important;
				width: auto !important;
			}
		}
		.slide-img{
			display: inline-block;
			width: 100% !important;
			text-align: center;
			margin-top: 15px;
			height: 40px !important;
			margin-bottom: 0 !important;
			img{
				max-width: 30%;
			}
		}
		.slide-description{
			text-align: center;
		}
		.btn{
			display: inline-block;
			width: 100%;
			margin-bottom: 15px;
		}
	}
	.home{
		.swiper-button-prev{
			display: none;
		}
		.swiper-button-next{
			display: none;
		}
		.card{
			.card-header{
				.card-img{
				}
				.badge{
					display: none;
					&:before{
					}
				}
			}
			.card-body{
				h4{
				}
				p{
				}
			}
			.card-footer{
				.btn{
					&:hover{
					}
				}
			}
		}
		
	}
	.swiper-pagination{
		width: 100%;
		text-align: center;
		.slide-pagination{
			width: 10px;
			height: 10px;
			background: $gray-200;
			display: inline-block;
			border-radius: 100%;
			&.active{
				background: $defaultButtonColor;
			}
		}
	}

	#main-pagination, #best-pagination, .category-pagination{
		width: 100%;
		text-align: center;
		.slide-pagination{
			width: 10px;
			height: 10px;
			background: $gray-200;
			display: inline-block;
			border-radius: 100%;
			margin: 0 2px;
			cursor: pointer;
			&.active{
				background: $defaultButtonColor;
			}
		}
	}
	.category-slider{
		.swiper-slide{
			flex:none;
			max-width: inherit;
		}
	}
}