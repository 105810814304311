footer{
	background-color: $white;
	position: relative;
	margin-top: 0px;
	.footer-bg{
		height: 386px;
		width: 100%;
		background-size: 32%;
		background-repeat: no-repeat;
		background-position: bottom right;
		position: absolute;
		top: -366px;
	}
	.footer-box{
		padding:40px 0;
		color: black;
		.title{
			margin-bottom: 23px;
			color: $defaultButtonColor;
		}
		.footer-logo{
			margin-bottom: 15px;
			a{
				img{
					width: 100%;
				}
				i{
					font-size: 60px;
					color: $defaultTextColor;
				}
			}
		}
		p{
			color: black;
		}
		span{
			font-size: 13px;
		}
		a{
			color: $white;
		}
		ul{
			li{
				padding-bottom: 10px;
				a{
					color: $black;
					font-size: 14px;
				}
			}
			&.social-media{
				margin-top: 15px;
				li{
					display: inline-block;
					a{
						color: #FFF;
						font-size: 22px;
						padding-right:30px;
						&:hover{
							color: $defaultTextColor;
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width:991px){
	footer{
		
		.footer-box{
			padding: 15px 0;
			ul{
				margin: 0;
			}
			.footer-logo{
				width: 200px;
				i{
					font-size: 30px !important;
				}
			}
		}
	}
}
@media only screen and (max-width:540px){
	footer{
		margin-top: 109px;
		.footer-bg{
			background-size: 100%;
		}
		.footer-box{

		}
	}
}