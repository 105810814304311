.hidden{
	display: none;
}
.section{
	padding: 100px 0;
	width: 100%;
	position: relative;
	.container{
		position: relative;
		z-index: 2;
	}
	.section-header{
		text-align: center;
		position: relative;
		.title{
			font-weight: 700;
		}
		.description{

		}
		&:before{
			content: '';
			width: 50px;
			height: 5px;
			border-radius: 100px;
			background: $defaultButtonColor;
			position: absolute;
			top: -20px;
			left: 50%;
			transform: translateX(-50%);
		}
	}
	&.main-section{
		text-align: center;
		/* min-height: 100vh; */
		padding-top: 196px;
		overflow: hidden;
		h1{
			text-align: center;
			font-weight: 700;
			.mobile-text {
				display: inline-block;
				margin-left: -8px;
				margin-right: 8px;
			}
			span{
				color: $defaultButtonColor;
				border-bottom: 4px solid $defaultTextColor;
				&.typed-cursor{
					color: #676767;
					font-weight: 300;
					&.typed-cursor--blink{
						animation: blink 0.5s infinite !important;
					}
					@keyframes blink {
						0% {
							opacity: 1;
						}
						50% {
							opacity: 0;
						}
						100% {
							opacity: 1;
						}
					}
				}
			}
		}
		p{
			font-size: 19px;
			margin-top: 36px;
		
			&.free-text{
				font-size: 20px;
				background-image: url('/assets/site/img/scribble.png') !important;
			}
		}
		.btn{
			
			&-custom{
				margin-top: 16px;
			}
			
		}
		.total-donation{
			display: inline-block;
			margin-top: 40px;
			height: 43px;
			border: 2px solid #EBEBEB;
			padding: 0 74px;
			border-radius: 15px;
			p{
				margin-top: -18px;
				background-color: $white;
				padding: 0 19px;
				margin-bottom: 0;
			}
			h1{
				color: #FF015B;
				margin-top: 1px;
				display: inline-block;
				background: #FFF;
				padding: 0 20px;
			}
		}
		.companies-container{
			width: 100%;
			margin-top: 268px;
			a{
				width: 100%;
				/* filter: grayscale(100%); */
				-webkit-transition: .3s ease-in-out all;
				transition: .3s ease-in-out all;
				background: #fff;
				height: 69px;
				margin-right: 19px;
				border-radius: 15px;
				box-shadow: 0px 0px 34px 0px rgba(0, 93, 255, 0.14);
				position: relative;
				img{
					    /* opacity: 0.2; */
					max-height: 50%;
					transition: .3s ease-in-out all;
					max-width: 50%;
					top: 50%;
					position: absolute;
					transform: translate(-50%, -50%);
					left: 50%;
				}
				&:hover{
					filter: grayscale(0%);
					img{
						opacity: 1;
					}
				}
				
			}
		}
		.button-content{
			position: relative;
			margin-top: 52px;
			margin-bottom: 46px;
		}
		.free-text{
			left: -8px;
			position: absolute;
			background-repeat: no-repeat;
			background-position: bottom center;
			background-color: transparent !important;
			width: 100px;
			height: 139px;
			color: #fdc508;
			top: 0;
			font-weight: 600;
		}
	}
	&.iyilik-butonu-main {
		.companies-container {
			margin-top: 5rem !important;
		}
	}
	&.aboutus{
		padding: 0;
	}
	&.support{
		padding: 100px 0 0;
	}
	&.count-section{
		padding: 55px 0;
		background-position: center;
		.category-bg{
			.path_1{
				width: 200px;
				height: 200px;
			}
			.path_2{
				width: 200px;
				height: 200px;
			}
		}
	}
}

.twitter-typeahead {
    width: 100%;
}

.highlight{
	position: relative;
	z-index: 1;
	border-bottom: 1px solid #2d0840;
	color: #ff005b;
	display: inline-block;
	span{
		position: relative;
		z-index: 1;
	}
}
.btn{
	border-radius: 100px;
	&-custom{
		color: $defaultButtonColor;
		border: 2px solid $defaultButtonColor;
		font-size: 24px;
		padding: 15px 20px;
		font-weight: 600;
	}
	&-danger{
		background: #FF015B;
		border-color: #FF015B;
		padding: 9px 32px;
		font-weight: 600;
		color: #fff;
		box-shadow: 4px 7px 26px 0px rgba(0, 0, 0, 0.24);
		&:hover{
			background: darken($defaultButtonColor, 5%);
			border-color: darken($defaultButtonColor, 5%);
			box-shadow:  0px 0px 0px 0px;
		}
		&:disabled{
			&:hover{
				background: #dc3545;
				border-color:#dc3545;
				box-shadow: 4px 7px 26px 0px rgba(0, 0, 0, 0.24);
			}
		}
	}
	&-gradient{
		&-yellow{
			background: linear-gradient(#FDC50B, #FFA700);
			color: #FFF;
		}
	}
}
.fade{
	&.in{
		opacity: 1;
	}
}


.section-container {
    text-align: center;
	padding: 15px 0;
	width: 100%;
	z-index: 1;
	.tab-container{
		max-width: 800px;
		width: 100%;
		display: inline-block;
		.nav{
			li{
				width: 25%;
				a{
					border-bottom: 1px solid #D8D8D8;
					float: left;
					width: 100%;
					
					i{
						font-size: 50px;
					}
					p{
						font-weight: 600;
						margin-top: 15px;
						font-size: 14px;
						opacity: 0.5;
					}
					&:hover, &.active{
						border-bottom: 4px solid $defaultTextColor;
						color: $defaultTextColor;
						border-radius: 3px;
						p{
							opacity: 1;
						}
					}
				}
			}
		}
		.tab-content{
			padding: 5px 0;
			text-align: left;
			h2{

			}
			ul{
				margin-top: 15px;
				li{
					position: relative;
					margin-bottom: 15px;
					font-size: 14px;
					span{
						width: 17px;
						height: 17px;
						background-color: #b1f5f8;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						border-radius: 100%;
						left: -26px;
						font-size: 11px;
						text-align: center;
					}
				}
			}
			.tab-img{
				text-align: center;
				height: 362px;
				position: relative;
				img{
					max-width: 54%;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					border-radius: 7px;
				}
			}
		}
	}
	.tab-supports{
		input[type=radio]{
			& + span{
				border: 1px solid $defaultTextColor;
				color: $defaultTextColor;
				padding: 7px 13px;
				border-radius: 10px;
				margin-right: 5px;
				&:before{
					width: 0;
					height: 100%;
					left: 0;
				}
			}
			&:checked{
				& + span{
					color: #FFF;
					border: 1px solid $defaultButtonColor;
					background: $defaultButtonColor;
					&:after{
						display: none;
						background: $defaultButtonColor;
						width: 100%;
						left: 0;
						height: 100%;

					}
				}
			}
		}
		a{
			margin-bottom: 5px;
		}
		&-companies{
			padding: 30px 0;
			.companies-box {
				height: 96px;
				background: #eaeaea;
				margin-bottom: 30px;
				border-radius: 10px;
				position: relative;
				transition: .3s ease-in-out all;
				img{
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					filter: grayscale(100%);
					-webkit-transition: .3s ease-in-out all;
					transition: .3s ease-in-out all;
					max-height: 52px;
					max-width: 72px;
				}
			}
			.supports-logo{
				transition: all 0.5s ease;
				transition: all 0.5s ease;
				width: 100%;
				flex: none;
				max-width: 12%;
				&.active{
					transform: scale(1.15);
					.companies-box{
						transition: .3s ease-in-out all;
						background: #FFF;
						box-shadow: 3px 4px 6px 0px rgba(0, 0, 0, 0.14);
						img{
							filter: grayscale(0%);
							-webkit-transition: .3s ease-in-out all;
							transition: .3s ease-in-out all;
						}
					}
				}
			}
		}
	}
	&.counter-container{
		background-color: $white;
		box-shadow: 0px 0px 30px 0px rgba($color: $black, $alpha: 0.2);
		padding: 50px 0;
		border-radius: 15px;
		position: relative;
		z-index: 2;
	}
	.counter-box {
		text-align: center;
		h1{
			font-weight: 700;
			color: $defaultButtonColor;
		}
	}
	
}
#aboutus{
	text-align: center;
}

blockquote.blockquote {
	position: relative;
	max-width: 750px;
	display: inline-block;
	margin-top: 91px;
	.quotes {
		position: absolute;
		font-size: 62px;
		color: #CCC;
		z-index: 0;
		&#left {
			top: -79px;
			left: 0;
		}
		&#right {
			bottom: -77px;
			right: -56px;
		}
	}
	p{
		padding: 0 100px;
		text-align: left;
		color: $gray-700;
	}
	.quote-name{
		text-align: right;
		color: $gray-700;
		margin-right: 30px;
		h1{
			margin-bottom: 0;
		}
		small{
			color: $defaultTextColor;
		}
	}
}

.tab{
	&-btn{
		a{
			i{
				width: 60px;
				height: 60px;
				border-radius: 100%;
				background: #D8D8D8;
				padding: 20px 0;
				font-size: 26px !important;
				display: inline-block;
				text-align: center;
				&.icon-iyilikkazansin{
					text-align: left;
					padding-left:17px ;
				}
			}
		}
		&-blue{
			a{
				i{
					background: lighten($blue, 45%);
					color: $blue;
				}
			}
		}
		&-green{
			a{
				i{
					background: lighten($green, 45%);
					color: $green;
				}
			}
		}
		&-pink{
			a{
				i{
					background: lighten($pink, 30%);
					color: $pink;
				}
			}
		}
		&-yellow{
			a{
				i{
					background: lighten($yellow, 45%);
					color: $yellow;
				}
			}
		}
	}
}
.button-custom{
	margin-top: 30px;
	.btn{
		font-size: 18px;
		padding: 15px 50px;
	}
	.free-text{
		margin-top: 15px;
		a{
			font-weight: 600;
		}
	}
}

.team-box{
	margin-bottom: 15px;
	.team-image{
		position: relative;
		height: 373px;
		overflow: hidden;
		border-radius: 15px;
		img{
			transition: all .5s ease;
			width: 100%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		figure{
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(247, 189, 0, 0.815);
			visibility: hidden;
			opacity: 0;
			transition: 0.5s ease all;
			figcaption{
				position: absolute;
				bottom: 15px;
				width: 100%;
				color: $white;
				p{
					color: $white;

				}
				a{
					color: $white;
					font-size: 24px;
				}
			}
		}
		
	}
	&:hover{
		.team-image{
			
			img{
			}
			figure{
				visibility: visible;
				opacity: 1;
				figcaption{
		
				}
			}
		}
	}
	p{
		font-weight: 700;
		color: $black;
		font-size: 20px;
	}
}

@media only screen and (max-width:991px){
	#aboutus{
		padding-top: 103px;
	}
	section{
		&.main-section{
			h1{
				.word{
					width: 100% !important;
					display: inline-block;
				}
				.typed-cursor{
					display: none;
				}
			}
			.total-donation{
				text-align: center;
				p{
					display: inline-block;
					float: none !important;
				}
			}
			.companies-container{
				
				a{
					width: 49% !important;
					display: inline-block;
					margin-bottom: 15px;
					margin-right: 10px !important;
					img{
						width: 100px;
					}
				}
			}
			div{
				position: relative;
				z-index: 4;
			}
		}
		.tab-container{
			.nav{
				li{
					a{
						padding:15px;
						p{
							
						}
					}
				}
			}
			.tab-content{
				h2{
					margin-top: 15px;
				}
				ul{
					li{
						&:before{
							display: none !important;
						}
					}
				}
			}
		}
	}
	.section-container{
		.tab-supports-companies{
			.supports-logo{
				max-width: 20%;
			}
		}
	}
	.supporters-box{
		&-company{
			width: 100px !important;
		}
		&-img{
			height: 100px !important;
			img{

			}
		}
		&-detail{

		}
	}
	
}
@media only screen and (max-width:767px){
	#perc-percent {
		display: none;
	}
	
	section{
		.section-header{
			h2{
				font-size: 24px;
				padding: 0 !important;
			}
		}
		&.section{
			/* overflow: hidden; */
			/* .section-header{
				&:before{
					top:0;
				}
			} */
		}
		&.main-section{
			padding-top:157px !important ;
			padding-bottom: 40px;
			h1{
				.word{
					width: 100% !important;
					display: inline-block;
				}
				.mobile-text{
					display: none !important ;
				}
				.typed-cursor{
					display: none;
				}
				
			}
			p{
				font-size: 1.1rem !important;
			}
			.total-donation{
				text-align: center;
				p{
					display: inline-block;
					float: none !important;
					
				}
				.free-text{
					background-image: none !important;
					background-color: transparent !important;
					width: 100%;
					margin-top: 2px;
					a{
						font-weight: 700;
					}
				}
			}
			.companies-container{
				margin-top: 166px !important;
				a{
					width: 47% !important;
					display: inline-block;
					margin-bottom: 15px;
					margin-right: 2% !important;
					&:nth-child(7), &:nth-child(6), &:nth-child(5){
						display: none;
					}
					img{
						width: 100px;
					}
				}
			}
			.btn-custom{
				margin-top: 0 !important;
			}
			.total-donation{
				height: 60px !important;
				padding: 0 !important;
			}
			.button-content{
				margin-top: 34px !important;
				margin-bottom: 15px !important;
				.free-text{
					display: none;
				}
			}
		}
		&.support{
			padding:56px 0 0 !important;
			
		}
		&.count-section{
			padding:0 !important;
			background-position: -265px;
			background-color: #fff6d1;
			
		}
		&#supporters{
			padding-bottom: 0 !important;
			.supporters-container{
				&:nth-child(12), &:nth-child(11), &:nth-child(10), &:nth-child(9), &:nth-child(8), &:nth-child(7), &:nth-child(6), &:nth-child(5){
					display: none !important;
				} 
			}
		}
		.tab-container{
			.nav{
				li{
					a{
						padding:15px;
						i{
							&.icon-iyilikkazansin{
								padding-left: 13px !important;
							}
						}
						p{
							display: none !important;
						}
					}
				}
			}
			.tab-content{
				h2{
					margin-top: 15px;
				}
				ul{
					li{
						&:before{
							display: none !important;
						}
					}
				}
			}
		}
	}
	.section-container {
		.tab-container {
			.nav {
				li {
					a {
						i{
							width: 50px !important;
							height: 50px !important;
							font-size: 19px !important;
							padding: 15px 0 !important;
							
						}
					}
				}
			}
		}
		.tab-supports-companies {
			.supports-logo{
				max-width: 48%;
				display: none;
				&.active{
					display: block;
				}
			}
		}
	}
	.section-container{
		padding: 15px !important;
	}
	.btn{
		&-custom{
			font-size: 17px;
			padding: 9px 11px;
		}
		&-danger{
			background: $defaultButtonColor !important;
			border-color: $defaultButtonColor !important;
		}
	}
	blockquote.blockquote {
		.quotes {
			&#left {
				top: -125px;
			}
			&#right {
				right: 15px;
			}
		}
		p{
			padding: 0;
		}
		.quote-name{
			h1{
			}
			small{
			}
		}
	}
}
