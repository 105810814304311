@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/bootstrap.scss';
//Base
@import './base/variables';
@import './base/elem';
@import './base/fonts';
@import './base/keyframes';
@import './base/modal';
@import './base/forms';
@import './base/buttons';

//Plugin
@import 'node_modules/aos/src/sass/aos.scss';
@import 'node_modules/swiper/src/swiper.scss';
@import './Profile/module/tinydatepicker';
@import 'node_modules/choices.js/src/styles/choices.scss';

//Shared
@import './shared/header';
@import './shared/hheader';
@import './shared/footer';

//Pages
@import './Home/landing';
@import './Home/home';
@import './Category/category';
@import './Profile/profile';
@import './Register/register';

//Components
@import './components/box';
@import './components/paralax';

@import './anadoluefes';
@import './kip';
@import './ticimax';

.wrapper {
  overflow: hidden;
}
/* .container{
	max-width: 1300px;
} */

.choices__item--selectable::after {
  display: none !important;
}
.choices__item--selectable {
  padding-right: 10px !important;
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 2rem;
  }
  p {
    font-size: 1.1rem;
  }
}

.tog-stk {
    height: 72px;
    margin-top: -17px;
}

.tog-stk img {
    height: 100%;
}


.sub-stk img {
  height: 200px;
}

.sub-stk .donation-companies {
  height: 150px !important;
}

.sub-stk .card-body .donation-companies img {
  max-width: 100%;
  max-height: 100%;
}

@media only screen and (max-width:767px){
  .tog-stk{
    display:none;
  }
}