
.menu-mobile {
    position: absolute;
    width: 40px;
    height: 40px;
    right: 15px;
    display: none;
    top: 22px;
    padding: 5px;
	span {
		position: absolute;
		width: 75%;
		height: 2px;
		background: #ff005b;
		top: 50%;
		transform: translateY(-50%);
		transition: 0.5s ease all;
		&:before {
			width: 100%;
			height: 2px;
			position: absolute;
			top: -7px;
			content: '';
			transition: 0.5s ease all;
			background: #ff005b;
		}
		&:after {
			width: 100%;
			height: 2px;
			position: absolute;
			top: 7px;
			content: '';
			transition: 0.5s ease all;
			background: #ff005b;
		}
	}
	&.active{
		span{
			transform: rotate(45deg);
			&:before{
				transform: rotate(-90deg);
				top:1px
			}
			&:after{
				opacity: 0;
			}
		}
	}
	
}
header{
	padding: 10px 0;
	float: left;
	width: 100%;
	position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
	.logo {
		width: 217px;
		float: left;
		position: relative;
		margin-right: 15px;
		.header-bg{
			width: 482px;
			height: 416px;
			position: absolute;
			top: -167px;
			left: -175px;
			background-repeat: no-repeat;
			/* transform: rotate(20deg); */
			z-index: 0;
			background-size: 449px;
		}
		a{
			font-size: 20px;
			position: relative;
			z-index: 1;
			height: 50px;
			overflow: hidden;
			display: inline-block;
			width: 100%;
			img{
				margin-top: 0px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				max-width: 100%;
    			max-height: 100%;
			}
			i{
				font-size: 40px;
				margin-top: 6px;
				display: inline-block;
			}
		}
	}
	.main-menu{
		position: relative;
		z-index: 1;
		ul{
			li{
				float: left;
				a{
					float: left;
					padding:15px 10px;
					color: $defaultTextColor;
					&:hover{
						text-decoration: none;
						color:$defaultTitleColor
					}
				}
			}
		}
	}
	
}
.login-menu{
	padding: 8px 0;
	position: relative;
	z-index: 1;
	&.login-mobile{
		display: none;
	}
	a{
		&:hover{
			color: $defaultTitleColor;
		}
		&.login-button{
			&:hover{
				color: $defaultButtonColor;
				border: 2px solid $defaultButtonColor;
				background: transparent !important;
				transform: scale(0.9);
			}
		}
	}
}

.req{
	position: absolute;
	top: 0;
	z-index: 1;
	background-color: $defaultButtonColor;
	color:$white;
	border-radius: 100%;
	width: 15px;
	height: 15px;
	font-size: 11px;
}

@media only screen and (max-width:991px){
	.menu-mobile{
		display: block;
		background: #fff;
    	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.28);
	}
	header{
		.main-menu{
			display: none;
			position: absolute;
			width: 200px;
			right: 0;
			top: 65px;
			background: #FFF;
			box-shadow: -4px 5px 18px 0px rgba(0, 0, 0, 0.15);
			&.active{
				display: block;
				li{
					width: 100%;
				}
			}
		}
		
	}
}

@media only screen and (max-width:768px){
	header {
		z-index: 3;
		.logo{
			width: 150px!important;
			img{
				left: 0 !important;
				transform: translate(0%, -50%) !important;
			}
		}
		.header-bg{
			height: 227px !important;
		}
	}
	
	.login-menu{
		margin-right: 50px;
	}
}


@media only screen and (max-width:420px){
	header{
		padding: 13px 0;
		position: fixed;
		background: #FFF;
		box-shadow: 0px 0px 34px 0px rgba(0, 93, 255, 0.14);
		.header-bg{
			height: 227px !important;
			/* display: none; */
			background-position-y: -152px;
			background-position-x: -26px;
		}
		.menu-mobile{
			top: 16px;
			border-radius: 12px;
		}
		.main-menu{
			top: 83px;
		}
	}
	/* .login-menu a {
		color: #FFF;
		border: 0 !important;
	} */
	.login-menu {
		position: absolute;
		top: 0px;
		width: 100%;
		left: 0px;
		padding: 0;
		display: none;
		&.login-mobile{
			display: block;
			text-align: center;
			margin: 0;
			a{
				display: inline-block;
				float: none;
				margin-bottom: 15px;
			}
		}
	}
}