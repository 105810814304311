.rellax-container{
	position: absolute;
	width: 700px;
	height: 100%;
	z-index: 0;
	&.right-center{
		right: -400px;
	}
	&.left-center{
		left: -400px;
	}
	.rellax{
		position: absolute;
		width: 700px;
		height: 201%;
		background-repeat: no-repeat;
		&.bg{
			&-1{
				width: 700px;
				height: 100%;
			}
			&-2{
				top: 72px;
    			left: 58px;
			}
			&-3{
				top: -79px;
    			left: -149px;
			}
			&-4{
				top: 0;
				right: 84px;
			}
			&-5{
				top: 240px;
				right: -90px;
			}
			&-6{
				top: 230px;
    			right: -506px;
			}
			&-7{
				top: -1106px;
				left: -54px;
			}
			&-8{
				top: 346px;
    			right: -7px;
			}
			&-9{
				top: -647px;
				right: -203px;
			}
			&-10{
				top: -801px;
				right: 56px;
			}
		}
	
	}
}

@media only screen and (max-width:768px){
	.rellax-container{
		display: none;
	}
}