@charset "UTF-8";
/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import url("https://fonts.googleapis.com/css?family=Barlow:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap&subset=latin-ext,vietnamese");
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

html {
  scroll-behavior: smooth; }

body {
  font-family: 'Barlow', sans-serif;
  color: #2D0A40; }

ul, li {
  list-style-type: none;
  padding: 0; }

p {
  color: #2D0A40; }

a {
  transition: 0.3s ease all;
  color: #2D0A40; }
  a:focus, a:active {
    outline: none !important;
    box-shadow: none !important; }
  a:hover {
    text-decoration: none; }
  a.btn-small {
    padding-left: 20px;
    padding-right: 20px;
    border: transparent;
    font-size: 12px;
    font-weight: 700;
    box-shadow: 0 2px 3px #000; }

@font-face {
  font-family: "iyilikkazansin";
  src: url("/assets/site/fonts/iyilikkazansin.eot?sdeo7j");
  src: url("/assets/site/fonts/iyilikkazansin.eot?sdeo7j#iefix") format("embedded-opentype"), url("/assets/site/fonts/iyilikkazansin.ttf?sdeo7j") format("truetype"), url("/assets/site/fonts/iyilikkazansin.woff?sdeo7j") format("woff"), url("/assets/site/fonts/iyilikkazansin.svg?sdeo7j#iyilikkazansin") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "iyilikkazansin" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-Bill-Drayton:before {
  content: ""; }

.icon-logo:before {
  content: ""; }

.icon-icon-login:before {
  content: ""; }

.icon-icon-online-shop:before {
  content: ""; }

.icon-icon-shopping-basket:before {
  content: ""; }

.icon-iyilikkazansin:before {
  content: ""; }

.icon-asterisk:before {
  content: ""; }

.icon-plus:before {
  content: ""; }

.icon-question:before {
  content: ""; }

.icon-minus:before {
  content: ""; }

.icon-glass:before {
  content: ""; }

.icon-music:before {
  content: ""; }

.icon-search:before {
  content: ""; }

.icon-envelope-o:before {
  content: ""; }

.icon-heart:before {
  content: ""; }

.icon-star:before {
  content: ""; }

.icon-star-o:before {
  content: ""; }

.icon-user:before {
  content: ""; }

.icon-film:before {
  content: ""; }

.icon-th-large:before {
  content: ""; }

.icon-th:before {
  content: ""; }

.icon-th-list:before {
  content: ""; }

.icon-check:before {
  content: ""; }

.icon-close:before {
  content: ""; }

.icon-remove:before {
  content: ""; }

.icon-times:before {
  content: ""; }

.icon-search-plus:before {
  content: ""; }

.icon-search-minus:before {
  content: ""; }

.icon-power-off:before {
  content: ""; }

.icon-signal:before {
  content: ""; }

.icon-cog:before {
  content: ""; }

.icon-gear:before {
  content: ""; }

.icon-trash-o:before {
  content: ""; }

.icon-home:before {
  content: ""; }

.icon-file-o:before {
  content: ""; }

.icon-clock-o:before {
  content: ""; }

.icon-road:before {
  content: ""; }

.icon-download:before {
  content: ""; }

.icon-arrow-circle-o-down:before {
  content: ""; }

.icon-arrow-circle-o-up:before {
  content: ""; }

.icon-inbox:before {
  content: ""; }

.icon-play-circle-o:before {
  content: ""; }

.icon-repeat:before {
  content: ""; }

.icon-rotate-right:before {
  content: ""; }

.icon-refresh:before {
  content: ""; }

.icon-list-alt:before {
  content: ""; }

.icon-lock:before {
  content: ""; }

.icon-flag:before {
  content: ""; }

.icon-headphones:before {
  content: ""; }

.icon-volume-off:before {
  content: ""; }

.icon-volume-down:before {
  content: ""; }

.icon-volume-up:before {
  content: ""; }

.icon-qrcode:before {
  content: ""; }

.icon-barcode:before {
  content: ""; }

.icon-tag:before {
  content: ""; }

.icon-tags:before {
  content: ""; }

.icon-book:before {
  content: ""; }

.icon-bookmark:before {
  content: ""; }

.icon-print:before {
  content: ""; }

.icon-camera:before {
  content: ""; }

.icon-font:before {
  content: ""; }

.icon-bold:before {
  content: ""; }

.icon-italic:before {
  content: ""; }

.icon-text-height:before {
  content: ""; }

.icon-text-width:before {
  content: ""; }

.icon-align-left:before {
  content: ""; }

.icon-align-center:before {
  content: ""; }

.icon-align-right:before {
  content: ""; }

.icon-align-justify:before {
  content: ""; }

.icon-list:before {
  content: ""; }

.icon-dedent:before {
  content: ""; }

.icon-outdent:before {
  content: ""; }

.icon-indent:before {
  content: ""; }

.icon-video-camera:before {
  content: ""; }

.icon-image:before {
  content: ""; }

.icon-photo:before {
  content: ""; }

.icon-picture-o:before {
  content: ""; }

.icon-pencil:before {
  content: ""; }

.icon-map-marker:before {
  content: ""; }

.icon-adjust:before {
  content: ""; }

.icon-tint:before {
  content: ""; }

.icon-edit:before {
  content: ""; }

.icon-pencil-square-o:before {
  content: ""; }

.icon-share-square-o:before {
  content: ""; }

.icon-check-square-o:before {
  content: ""; }

.icon-arrows:before {
  content: ""; }

.icon-step-backward:before {
  content: ""; }

.icon-fast-backward:before {
  content: ""; }

.icon-backward:before {
  content: ""; }

.icon-play:before {
  content: ""; }

.icon-pause:before {
  content: ""; }

.icon-stop:before {
  content: ""; }

.icon-forward:before {
  content: ""; }

.icon-fast-forward:before {
  content: ""; }

.icon-step-forward:before {
  content: ""; }

.icon-eject:before {
  content: ""; }

.icon-chevron-left:before {
  content: ""; }

.icon-chevron-right:before {
  content: ""; }

.icon-plus-circle:before {
  content: ""; }

.icon-minus-circle:before {
  content: ""; }

.icon-times-circle:before {
  content: ""; }

.icon-check-circle:before {
  content: ""; }

.icon-question-circle:before {
  content: ""; }

.icon-info-circle:before {
  content: ""; }

.icon-crosshairs:before {
  content: ""; }

.icon-times-circle-o:before {
  content: ""; }

.icon-check-circle-o:before {
  content: ""; }

.icon-ban:before {
  content: ""; }

.icon-arrow-left:before {
  content: ""; }

.icon-arrow-right:before {
  content: ""; }

.icon-arrow-up:before {
  content: ""; }

.icon-arrow-down:before {
  content: ""; }

.icon-mail-forward:before {
  content: ""; }

.icon-share:before {
  content: ""; }

.icon-expand:before {
  content: ""; }

.icon-compress:before {
  content: ""; }

.icon-exclamation-circle:before {
  content: ""; }

.icon-gift:before {
  content: ""; }

.icon-leaf:before {
  content: ""; }

.icon-fire:before {
  content: ""; }

.icon-eye:before {
  content: ""; }

.icon-eye-slash:before {
  content: ""; }

.icon-exclamation-triangle:before {
  content: ""; }

.icon-warning:before {
  content: ""; }

.icon-plane:before {
  content: ""; }

.icon-calendar:before {
  content: ""; }

.icon-random:before {
  content: ""; }

.icon-comment:before {
  content: ""; }

.icon-magnet:before {
  content: ""; }

.icon-chevron-up:before {
  content: ""; }

.icon-chevron-down:before {
  content: ""; }

.icon-retweet:before {
  content: ""; }

.icon-shopping-cart:before {
  content: ""; }

.icon-folder:before {
  content: ""; }

.icon-folder-open:before {
  content: ""; }

.icon-arrows-v:before {
  content: ""; }

.icon-arrows-h:before {
  content: ""; }

.icon-bar-chart:before {
  content: ""; }

.icon-bar-chart-o:before {
  content: ""; }

.icon-twitter-square:before {
  content: ""; }

.icon-facebook-square:before {
  content: ""; }

.icon-camera-retro:before {
  content: ""; }

.icon-key:before {
  content: ""; }

.icon-cogs:before {
  content: ""; }

.icon-gears:before {
  content: ""; }

.icon-comments:before {
  content: ""; }

.icon-thumbs-o-up:before {
  content: ""; }

.icon-thumbs-o-down:before {
  content: ""; }

.icon-star-half:before {
  content: ""; }

.icon-heart-o:before {
  content: ""; }

.icon-sign-out:before {
  content: ""; }

.icon-linkedin-square:before {
  content: ""; }

.icon-thumb-tack:before {
  content: ""; }

.icon-external-link:before {
  content: ""; }

.icon-sign-in:before {
  content: ""; }

.icon-trophy:before {
  content: ""; }

.icon-github-square:before {
  content: ""; }

.icon-upload:before {
  content: ""; }

.icon-lemon-o:before {
  content: ""; }

.icon-phone:before {
  content: ""; }

.icon-square-o:before {
  content: ""; }

.icon-bookmark-o:before {
  content: ""; }

.icon-phone-square:before {
  content: ""; }

.icon-twitter:before {
  content: ""; }

.icon-facebook:before {
  content: ""; }

.icon-facebook-f:before {
  content: ""; }

.icon-github:before {
  content: ""; }

.icon-unlock:before {
  content: ""; }

.icon-credit-card:before {
  content: ""; }

.icon-feed:before {
  content: ""; }

.icon-rss:before {
  content: ""; }

.icon-hdd-o:before {
  content: ""; }

.icon-bullhorn:before {
  content: ""; }

.icon-bell-o:before {
  content: ""; }

.icon-certificate:before {
  content: ""; }

.icon-hand-o-right:before {
  content: ""; }

.icon-hand-o-left:before {
  content: ""; }

.icon-hand-o-up:before {
  content: ""; }

.icon-hand-o-down:before {
  content: ""; }

.icon-arrow-circle-left:before {
  content: ""; }

.icon-arrow-circle-right:before {
  content: ""; }

.icon-arrow-circle-up:before {
  content: ""; }

.icon-arrow-circle-down:before {
  content: ""; }

.icon-globe:before {
  content: ""; }

.icon-wrench:before {
  content: ""; }

.icon-tasks:before {
  content: ""; }

.icon-filter:before {
  content: ""; }

.icon-briefcase:before {
  content: ""; }

.icon-arrows-alt:before {
  content: ""; }

.icon-group:before {
  content: ""; }

.icon-users:before {
  content: ""; }

.icon-chain:before {
  content: ""; }

.icon-link:before {
  content: ""; }

.icon-cloud:before {
  content: ""; }

.icon-flask:before {
  content: ""; }

.icon-cut:before {
  content: ""; }

.icon-scissors:before {
  content: ""; }

.icon-copy:before {
  content: ""; }

.icon-files-o:before {
  content: ""; }

.icon-paperclip:before {
  content: ""; }

.icon-floppy-o:before {
  content: ""; }

.icon-save:before {
  content: ""; }

.icon-square:before {
  content: ""; }

.icon-bars:before {
  content: ""; }

.icon-navicon:before {
  content: ""; }

.icon-reorder:before {
  content: ""; }

.icon-list-ul:before {
  content: ""; }

.icon-list-ol:before {
  content: ""; }

.icon-strikethrough:before {
  content: ""; }

.icon-underline:before {
  content: ""; }

.icon-table:before {
  content: ""; }

.icon-magic:before {
  content: ""; }

.icon-truck:before {
  content: ""; }

.icon-pinterest:before {
  content: ""; }

.icon-pinterest-square:before {
  content: ""; }

.icon-google-plus-square:before {
  content: ""; }

.icon-google-plus:before {
  content: ""; }

.icon-money:before {
  content: ""; }

.icon-caret-down:before {
  content: ""; }

.icon-caret-up:before {
  content: ""; }

.icon-caret-left:before {
  content: ""; }

.icon-caret-right:before {
  content: ""; }

.icon-columns:before {
  content: ""; }

.icon-sort:before {
  content: ""; }

.icon-unsorted:before {
  content: ""; }

.icon-sort-desc:before {
  content: ""; }

.icon-sort-down:before {
  content: ""; }

.icon-sort-asc:before {
  content: ""; }

.icon-sort-up:before {
  content: ""; }

.icon-envelope:before {
  content: ""; }

.icon-linkedin:before {
  content: ""; }

.icon-rotate-left:before {
  content: ""; }

.icon-undo:before {
  content: ""; }

.icon-gavel:before {
  content: ""; }

.icon-legal:before {
  content: ""; }

.icon-dashboard:before {
  content: ""; }

.icon-tachometer:before {
  content: ""; }

.icon-comment-o:before {
  content: ""; }

.icon-comments-o:before {
  content: ""; }

.icon-bolt:before {
  content: ""; }

.icon-flash:before {
  content: ""; }

.icon-sitemap:before {
  content: ""; }

.icon-umbrella:before {
  content: ""; }

.icon-clipboard:before {
  content: ""; }

.icon-paste:before {
  content: ""; }

.icon-lightbulb-o:before {
  content: ""; }

.icon-exchange:before {
  content: ""; }

.icon-cloud-download:before {
  content: ""; }

.icon-cloud-upload:before {
  content: ""; }

.icon-user-md:before {
  content: ""; }

.icon-stethoscope:before {
  content: ""; }

.icon-suitcase:before {
  content: ""; }

.icon-bell:before {
  content: ""; }

.icon-coffee:before {
  content: ""; }

.icon-cutlery:before {
  content: ""; }

.icon-file-text-o:before {
  content: ""; }

.icon-building-o:before {
  content: ""; }

.icon-hospital-o:before {
  content: ""; }

.icon-ambulance:before {
  content: ""; }

.icon-medkit:before {
  content: ""; }

.icon-fighter-jet:before {
  content: ""; }

.icon-beer:before {
  content: ""; }

.icon-h-square:before {
  content: ""; }

.icon-plus-square:before {
  content: ""; }

.icon-angle-double-left:before {
  content: ""; }

.icon-angle-double-right:before {
  content: ""; }

.icon-angle-double-up:before {
  content: ""; }

.icon-angle-double-down:before {
  content: ""; }

.icon-angle-left:before {
  content: ""; }

.icon-angle-right:before {
  content: ""; }

.icon-angle-up:before {
  content: ""; }

.icon-angle-down:before {
  content: ""; }

.icon-desktop:before {
  content: ""; }

.icon-laptop:before {
  content: ""; }

.icon-tablet:before {
  content: ""; }

.icon-mobile:before {
  content: ""; }

.icon-mobile-phone:before {
  content: ""; }

.icon-circle-o:before {
  content: ""; }

.icon-quote-left:before {
  content: ""; }

.icon-quote-right:before {
  content: ""; }

.icon-spinner:before {
  content: ""; }

.icon-circle:before {
  content: ""; }

.icon-mail-reply:before {
  content: ""; }

.icon-reply:before {
  content: ""; }

.icon-github-alt:before {
  content: ""; }

.icon-folder-o:before {
  content: ""; }

.icon-folder-open-o:before {
  content: ""; }

.icon-smile-o:before {
  content: ""; }

.icon-frown-o:before {
  content: ""; }

.icon-meh-o:before {
  content: ""; }

.icon-gamepad:before {
  content: ""; }

.icon-keyboard-o:before {
  content: ""; }

.icon-flag-o:before {
  content: ""; }

.icon-flag-checkered:before {
  content: ""; }

.icon-terminal:before {
  content: ""; }

.icon-code:before {
  content: ""; }

.icon-mail-reply-all:before {
  content: ""; }

.icon-reply-all:before {
  content: ""; }

.icon-star-half-empty:before {
  content: ""; }

.icon-star-half-full:before {
  content: ""; }

.icon-star-half-o:before {
  content: ""; }

.icon-location-arrow:before {
  content: ""; }

.icon-crop:before {
  content: ""; }

.icon-code-fork:before {
  content: ""; }

.icon-chain-broken:before {
  content: ""; }

.icon-unlink:before {
  content: ""; }

.icon-info:before {
  content: ""; }

.icon-exclamation:before {
  content: ""; }

.icon-superscript:before {
  content: ""; }

.icon-subscript:before {
  content: ""; }

.icon-eraser:before {
  content: ""; }

.icon-puzzle-piece:before {
  content: ""; }

.icon-microphone:before {
  content: ""; }

.icon-microphone-slash:before {
  content: ""; }

.icon-shield:before {
  content: ""; }

.icon-calendar-o:before {
  content: ""; }

.icon-fire-extinguisher:before {
  content: ""; }

.icon-rocket:before {
  content: ""; }

.icon-maxcdn:before {
  content: ""; }

.icon-chevron-circle-left:before {
  content: ""; }

.icon-chevron-circle-right:before {
  content: ""; }

.icon-chevron-circle-up:before {
  content: ""; }

.icon-chevron-circle-down:before {
  content: ""; }

.icon-html5:before {
  content: ""; }

.icon-css3:before {
  content: ""; }

.icon-anchor:before {
  content: ""; }

.icon-unlock-alt:before {
  content: ""; }

.icon-bullseye:before {
  content: ""; }

.icon-ellipsis-h:before {
  content: ""; }

.icon-ellipsis-v:before {
  content: ""; }

.icon-rss-square:before {
  content: ""; }

.icon-play-circle:before {
  content: ""; }

.icon-ticket:before {
  content: ""; }

.icon-minus-square:before {
  content: ""; }

.icon-minus-square-o:before {
  content: ""; }

.icon-level-up:before {
  content: ""; }

.icon-level-down:before {
  content: ""; }

.icon-check-square:before {
  content: ""; }

.icon-pencil-square:before {
  content: ""; }

.icon-external-link-square:before {
  content: ""; }

.icon-share-square:before {
  content: ""; }

.icon-compass:before {
  content: ""; }

.icon-caret-square-o-down:before {
  content: ""; }

.icon-toggle-down:before {
  content: ""; }

.icon-caret-square-o-up:before {
  content: ""; }

.icon-toggle-up:before {
  content: ""; }

.icon-caret-square-o-right:before {
  content: ""; }

.icon-toggle-right:before {
  content: ""; }

.icon-eur:before {
  content: ""; }

.icon-euro:before {
  content: ""; }

.icon-gbp:before {
  content: ""; }

.icon-dollar:before {
  content: ""; }

.icon-usd:before {
  content: ""; }

.icon-inr:before {
  content: ""; }

.icon-rupee:before {
  content: ""; }

.icon-cny:before {
  content: ""; }

.icon-jpy:before {
  content: ""; }

.icon-rmb:before {
  content: ""; }

.icon-yen:before {
  content: ""; }

.icon-rouble:before {
  content: ""; }

.icon-rub:before {
  content: ""; }

.icon-ruble:before {
  content: ""; }

.icon-krw:before {
  content: ""; }

.icon-won:before {
  content: ""; }

.icon-bitcoin:before {
  content: ""; }

.icon-btc:before {
  content: ""; }

.icon-file:before {
  content: ""; }

.icon-file-text:before {
  content: ""; }

.icon-sort-alpha-asc:before {
  content: ""; }

.icon-sort-alpha-desc:before {
  content: ""; }

.icon-sort-amount-asc:before {
  content: ""; }

.icon-sort-amount-desc:before {
  content: ""; }

.icon-sort-numeric-asc:before {
  content: ""; }

.icon-sort-numeric-desc:before {
  content: ""; }

.icon-thumbs-up:before {
  content: ""; }

.icon-thumbs-down:before {
  content: ""; }

.icon-youtube-square:before {
  content: ""; }

.icon-youtube:before {
  content: ""; }

.icon-xing:before {
  content: ""; }

.icon-xing-square:before {
  content: ""; }

.icon-youtube-play:before {
  content: ""; }

.icon-dropbox:before {
  content: ""; }

.icon-stack-overflow:before {
  content: ""; }

.icon-instagram:before {
  content: ""; }

.icon-flickr:before {
  content: ""; }

.icon-adn:before {
  content: ""; }

.icon-bitbucket:before {
  content: ""; }

.icon-bitbucket-square:before {
  content: ""; }

.icon-tumblr:before {
  content: ""; }

.icon-tumblr-square:before {
  content: ""; }

.icon-long-arrow-down:before {
  content: ""; }

.icon-long-arrow-up:before {
  content: ""; }

.icon-long-arrow-left:before {
  content: ""; }

.icon-long-arrow-right:before {
  content: ""; }

.icon-apple:before {
  content: ""; }

.icon-windows:before {
  content: ""; }

.icon-android:before {
  content: ""; }

.icon-linux:before {
  content: ""; }

.icon-dribbble:before {
  content: ""; }

.icon-skype:before {
  content: ""; }

.icon-foursquare:before {
  content: ""; }

.icon-trello:before {
  content: ""; }

.icon-female:before {
  content: ""; }

.icon-male:before {
  content: ""; }

.icon-gittip:before {
  content: ""; }

.icon-gratipay:before {
  content: ""; }

.icon-sun-o:before {
  content: ""; }

.icon-moon-o:before {
  content: ""; }

.icon-archive:before {
  content: ""; }

.icon-bug:before {
  content: ""; }

.icon-vk:before {
  content: ""; }

.icon-weibo:before {
  content: ""; }

.icon-renren:before {
  content: ""; }

.icon-pagelines:before {
  content: ""; }

.icon-stack-exchange:before {
  content: ""; }

.icon-arrow-circle-o-right:before {
  content: ""; }

.icon-arrow-circle-o-left:before {
  content: ""; }

.icon-caret-square-o-left:before {
  content: ""; }

.icon-toggle-left:before {
  content: ""; }

.icon-dot-circle-o:before {
  content: ""; }

.icon-wheelchair:before {
  content: ""; }

.icon-vimeo-square:before {
  content: ""; }

.icon-try:before {
  content: ""; }

.icon-turkish-lira:before {
  content: ""; }

.icon-plus-square-o:before {
  content: ""; }

.icon-space-shuttle:before {
  content: ""; }

.icon-slack:before {
  content: ""; }

.icon-envelope-square:before {
  content: ""; }

.icon-wordpress:before {
  content: ""; }

.icon-openid:before {
  content: ""; }

.icon-bank:before {
  content: ""; }

.icon-institution:before {
  content: ""; }

.icon-university:before {
  content: ""; }

.icon-graduation-cap:before {
  content: ""; }

.icon-mortar-board:before {
  content: ""; }

.icon-yahoo:before {
  content: ""; }

.icon-google:before {
  content: ""; }

.icon-reddit:before {
  content: ""; }

.icon-reddit-square:before {
  content: ""; }

.icon-stumbleupon-circle:before {
  content: ""; }

.icon-stumbleupon:before {
  content: ""; }

.icon-delicious:before {
  content: ""; }

.icon-digg:before {
  content: ""; }

.icon-pied-piper-pp:before {
  content: ""; }

.icon-pied-piper-alt:before {
  content: ""; }

.icon-drupal:before {
  content: ""; }

.icon-joomla:before {
  content: ""; }

.icon-language:before {
  content: ""; }

.icon-fax:before {
  content: ""; }

.icon-building:before {
  content: ""; }

.icon-child:before {
  content: ""; }

.icon-paw:before {
  content: ""; }

.icon-spoon:before {
  content: ""; }

.icon-cube:before {
  content: ""; }

.icon-cubes:before {
  content: ""; }

.icon-behance:before {
  content: ""; }

.icon-behance-square:before {
  content: ""; }

.icon-steam:before {
  content: ""; }

.icon-steam-square:before {
  content: ""; }

.icon-recycle:before {
  content: ""; }

.icon-automobile:before {
  content: ""; }

.icon-car:before {
  content: ""; }

.icon-cab:before {
  content: ""; }

.icon-taxi:before {
  content: ""; }

.icon-tree:before {
  content: ""; }

.icon-spotify:before {
  content: ""; }

.icon-deviantart:before {
  content: ""; }

.icon-soundcloud:before {
  content: ""; }

.icon-database:before {
  content: ""; }

.icon-file-pdf-o:before {
  content: ""; }

.icon-file-word-o:before {
  content: ""; }

.icon-file-excel-o:before {
  content: ""; }

.icon-file-powerpoint-o:before {
  content: ""; }

.icon-file-image-o:before {
  content: ""; }

.icon-file-photo-o:before {
  content: ""; }

.icon-file-picture-o:before {
  content: ""; }

.icon-file-archive-o:before {
  content: ""; }

.icon-file-zip-o:before {
  content: ""; }

.icon-file-audio-o:before {
  content: ""; }

.icon-file-sound-o:before {
  content: ""; }

.icon-file-movie-o:before {
  content: ""; }

.icon-file-video-o:before {
  content: ""; }

.icon-file-code-o:before {
  content: ""; }

.icon-vine:before {
  content: ""; }

.icon-codepen:before {
  content: ""; }

.icon-jsfiddle:before {
  content: ""; }

.icon-life-bouy:before {
  content: ""; }

.icon-life-buoy:before {
  content: ""; }

.icon-life-ring:before {
  content: ""; }

.icon-life-saver:before {
  content: ""; }

.icon-support:before {
  content: ""; }

.icon-circle-o-notch:before {
  content: ""; }

.icon-ra:before {
  content: ""; }

.icon-rebel:before {
  content: ""; }

.icon-resistance:before {
  content: ""; }

.icon-empire:before {
  content: ""; }

.icon-ge:before {
  content: ""; }

.icon-git-square:before {
  content: ""; }

.icon-git:before {
  content: ""; }

.icon-hacker-news:before {
  content: ""; }

.icon-y-combinator-square:before {
  content: ""; }

.icon-yc-square:before {
  content: ""; }

.icon-tencent-weibo:before {
  content: ""; }

.icon-qq:before {
  content: ""; }

.icon-wechat:before {
  content: ""; }

.icon-weixin:before {
  content: ""; }

.icon-paper-plane:before {
  content: ""; }

.icon-send:before {
  content: ""; }

.icon-paper-plane-o:before {
  content: ""; }

.icon-send-o:before {
  content: ""; }

.icon-history:before {
  content: ""; }

.icon-circle-thin:before {
  content: ""; }

.icon-header:before {
  content: ""; }

.icon-paragraph:before {
  content: ""; }

.icon-sliders:before {
  content: ""; }

.icon-share-alt:before {
  content: ""; }

.icon-share-alt-square:before {
  content: ""; }

.icon-bomb:before {
  content: ""; }

.icon-futbol-o:before {
  content: ""; }

.icon-soccer-ball-o:before {
  content: ""; }

.icon-tty:before {
  content: ""; }

.icon-binoculars:before {
  content: ""; }

.icon-plug:before {
  content: ""; }

.icon-slideshare:before {
  content: ""; }

.icon-twitch:before {
  content: ""; }

.icon-yelp:before {
  content: ""; }

.icon-newspaper-o:before {
  content: ""; }

.icon-wifi:before {
  content: ""; }

.icon-calculator:before {
  content: ""; }

.icon-paypal:before {
  content: ""; }

.icon-google-wallet:before {
  content: ""; }

.icon-cc-visa:before {
  content: ""; }

.icon-cc-mastercard:before {
  content: ""; }

.icon-cc-discover:before {
  content: ""; }

.icon-cc-amex:before {
  content: ""; }

.icon-cc-paypal:before {
  content: ""; }

.icon-cc-stripe:before {
  content: ""; }

.icon-bell-slash:before {
  content: ""; }

.icon-bell-slash-o:before {
  content: ""; }

.icon-trash:before {
  content: ""; }

.icon-copyright:before {
  content: ""; }

.icon-at:before {
  content: ""; }

.icon-eyedropper:before {
  content: ""; }

.icon-paint-brush:before {
  content: ""; }

.icon-birthday-cake:before {
  content: ""; }

.icon-area-chart:before {
  content: ""; }

.icon-pie-chart:before {
  content: ""; }

.icon-line-chart:before {
  content: ""; }

.icon-lastfm:before {
  content: ""; }

.icon-lastfm-square:before {
  content: ""; }

.icon-toggle-off:before {
  content: ""; }

.icon-toggle-on:before {
  content: ""; }

.icon-bicycle:before {
  content: ""; }

.icon-bus:before {
  content: ""; }

.icon-ioxhost:before {
  content: ""; }

.icon-angellist:before {
  content: ""; }

.icon-cc:before {
  content: ""; }

.icon-ils:before {
  content: ""; }

.icon-shekel:before {
  content: ""; }

.icon-sheqel:before {
  content: ""; }

.icon-meanpath:before {
  content: ""; }

.icon-buysellads:before {
  content: ""; }

.icon-connectdevelop:before {
  content: ""; }

.icon-dashcube:before {
  content: ""; }

.icon-forumbee:before {
  content: ""; }

.icon-leanpub:before {
  content: ""; }

.icon-sellsy:before {
  content: ""; }

.icon-shirtsinbulk:before {
  content: ""; }

.icon-simplybuilt:before {
  content: ""; }

.icon-skyatlas:before {
  content: ""; }

.icon-cart-plus:before {
  content: ""; }

.icon-cart-arrow-down:before {
  content: ""; }

.icon-diamond:before {
  content: ""; }

.icon-ship:before {
  content: ""; }

.icon-user-secret:before {
  content: ""; }

.icon-motorcycle:before {
  content: ""; }

.icon-street-view:before {
  content: ""; }

.icon-heartbeat:before {
  content: ""; }

.icon-venus:before {
  content: ""; }

.icon-mars:before {
  content: ""; }

.icon-mercury:before {
  content: ""; }

.icon-intersex:before {
  content: ""; }

.icon-transgender:before {
  content: ""; }

.icon-transgender-alt:before {
  content: ""; }

.icon-venus-double:before {
  content: ""; }

.icon-mars-double:before {
  content: ""; }

.icon-venus-mars:before {
  content: ""; }

.icon-mars-stroke:before {
  content: ""; }

.icon-mars-stroke-v:before {
  content: ""; }

.icon-mars-stroke-h:before {
  content: ""; }

.icon-neuter:before {
  content: ""; }

.icon-genderless:before {
  content: ""; }

.icon-facebook-official:before {
  content: ""; }

.icon-pinterest-p:before {
  content: ""; }

.icon-whatsapp:before {
  content: ""; }

.icon-server:before {
  content: ""; }

.icon-user-plus:before {
  content: ""; }

.icon-user-times:before {
  content: ""; }

.icon-bed:before {
  content: ""; }

.icon-hotel:before {
  content: ""; }

.icon-viacoin:before {
  content: ""; }

.icon-train:before {
  content: ""; }

.icon-subway:before {
  content: ""; }

.icon-medium:before {
  content: ""; }

.icon-y-combinator:before {
  content: ""; }

.icon-yc:before {
  content: ""; }

.icon-optin-monster:before {
  content: ""; }

.icon-opencart:before {
  content: ""; }

.icon-expeditedssl:before {
  content: ""; }

.icon-battery:before {
  content: ""; }

.icon-battery-4:before {
  content: ""; }

.icon-battery-full:before {
  content: ""; }

.icon-battery-3:before {
  content: ""; }

.icon-battery-three-quarters:before {
  content: ""; }

.icon-battery-2:before {
  content: ""; }

.icon-battery-half:before {
  content: ""; }

.icon-battery-1:before {
  content: ""; }

.icon-battery-quarter:before {
  content: ""; }

.icon-battery-0:before {
  content: ""; }

.icon-battery-empty:before {
  content: ""; }

.icon-mouse-pointer:before {
  content: ""; }

.icon-i-cursor:before {
  content: ""; }

.icon-object-group:before {
  content: ""; }

.icon-object-ungroup:before {
  content: ""; }

.icon-sticky-note:before {
  content: ""; }

.icon-sticky-note-o:before {
  content: ""; }

.icon-cc-jcb:before {
  content: ""; }

.icon-cc-diners-club:before {
  content: ""; }

.icon-clone:before {
  content: ""; }

.icon-balance-scale:before {
  content: ""; }

.icon-hourglass-o:before {
  content: ""; }

.icon-hourglass-1:before {
  content: ""; }

.icon-hourglass-start:before {
  content: ""; }

.icon-hourglass-2:before {
  content: ""; }

.icon-hourglass-half:before {
  content: ""; }

.icon-hourglass-3:before {
  content: ""; }

.icon-hourglass-end:before {
  content: ""; }

.icon-hourglass:before {
  content: ""; }

.icon-hand-grab-o:before {
  content: ""; }

.icon-hand-rock-o:before {
  content: ""; }

.icon-hand-paper-o:before {
  content: ""; }

.icon-hand-stop-o:before {
  content: ""; }

.icon-hand-scissors-o:before {
  content: ""; }

.icon-hand-lizard-o:before {
  content: ""; }

.icon-hand-spock-o:before {
  content: ""; }

.icon-hand-pointer-o:before {
  content: ""; }

.icon-hand-peace-o:before {
  content: ""; }

.icon-trademark:before {
  content: ""; }

.icon-registered:before {
  content: ""; }

.icon-creative-commons:before {
  content: ""; }

.icon-gg:before {
  content: ""; }

.icon-gg-circle:before {
  content: ""; }

.icon-tripadvisor:before {
  content: ""; }

.icon-odnoklassniki:before {
  content: ""; }

.icon-odnoklassniki-square:before {
  content: ""; }

.icon-get-pocket:before {
  content: ""; }

.icon-wikipedia-w:before {
  content: ""; }

.icon-safari:before {
  content: ""; }

.icon-chrome:before {
  content: ""; }

.icon-firefox:before {
  content: ""; }

.icon-opera:before {
  content: ""; }

.icon-internet-explorer:before {
  content: ""; }

.icon-television:before {
  content: ""; }

.icon-tv:before {
  content: ""; }

.icon-contao:before {
  content: ""; }

.icon-500px:before {
  content: ""; }

.icon-amazon:before {
  content: ""; }

.icon-calendar-plus-o:before {
  content: ""; }

.icon-calendar-minus-o:before {
  content: ""; }

.icon-calendar-times-o:before {
  content: ""; }

.icon-calendar-check-o:before {
  content: ""; }

.icon-industry:before {
  content: ""; }

.icon-map-pin:before {
  content: ""; }

.icon-map-signs:before {
  content: ""; }

.icon-map-o:before {
  content: ""; }

.icon-map:before {
  content: ""; }

.icon-commenting:before {
  content: ""; }

.icon-commenting-o:before {
  content: ""; }

.icon-houzz:before {
  content: ""; }

.icon-vimeo:before {
  content: ""; }

.icon-black-tie:before {
  content: ""; }

.icon-fonticons:before {
  content: ""; }

.icon-reddit-alien:before {
  content: ""; }

.icon-edge:before {
  content: ""; }

.icon-credit-card-alt:before {
  content: ""; }

.icon-codiepie:before {
  content: ""; }

.icon-modx:before {
  content: ""; }

.icon-fort-awesome:before {
  content: ""; }

.icon-usb:before {
  content: ""; }

.icon-product-hunt:before {
  content: ""; }

.icon-mixcloud:before {
  content: ""; }

.icon-scribd:before {
  content: ""; }

.icon-pause-circle:before {
  content: ""; }

.icon-pause-circle-o:before {
  content: ""; }

.icon-stop-circle:before {
  content: ""; }

.icon-stop-circle-o:before {
  content: ""; }

.icon-shopping-bag:before {
  content: ""; }

.icon-shopping-basket:before {
  content: ""; }

.icon-hashtag:before {
  content: ""; }

.icon-bluetooth:before {
  content: ""; }

.icon-bluetooth-b:before {
  content: ""; }

.icon-percent:before {
  content: ""; }

.icon-gitlab:before {
  content: ""; }

.icon-wpbeginner:before {
  content: ""; }

.icon-wpforms:before {
  content: ""; }

.icon-envira:before {
  content: ""; }

.icon-universal-access:before {
  content: ""; }

.icon-wheelchair-alt:before {
  content: ""; }

.icon-question-circle-o:before {
  content: ""; }

.icon-blind:before {
  content: ""; }

.icon-audio-description:before {
  content: ""; }

.icon-volume-control-phone:before {
  content: ""; }

.icon-braille:before {
  content: ""; }

.icon-assistive-listening-systems:before {
  content: ""; }

.icon-american-sign-language-interpreting:before {
  content: ""; }

.icon-asl-interpreting:before {
  content: ""; }

.icon-deaf:before {
  content: ""; }

.icon-deafness:before {
  content: ""; }

.icon-hard-of-hearing:before {
  content: ""; }

.icon-glide:before {
  content: ""; }

.icon-glide-g:before {
  content: ""; }

.icon-sign-language:before {
  content: ""; }

.icon-signing:before {
  content: ""; }

.icon-low-vision:before {
  content: ""; }

.icon-viadeo:before {
  content: ""; }

.icon-viadeo-square:before {
  content: ""; }

.icon-snapchat:before {
  content: ""; }

.icon-snapchat-ghost:before {
  content: ""; }

.icon-snapchat-square:before {
  content: ""; }

.icon-pied-piper:before {
  content: ""; }

.icon-first-order:before {
  content: ""; }

.icon-yoast:before {
  content: ""; }

.icon-themeisle:before {
  content: ""; }

.icon-google-plus-circle:before {
  content: ""; }

.icon-google-plus-official:before {
  content: ""; }

.icon-fa:before {
  content: ""; }

.icon-font-awesome:before {
  content: ""; }

.icon-handshake-o:before {
  content: ""; }

.icon-envelope-open:before {
  content: ""; }

.icon-envelope-open-o:before {
  content: ""; }

.icon-linode:before {
  content: ""; }

.icon-address-book:before {
  content: ""; }

.icon-address-book-o:before {
  content: ""; }

.icon-address-card:before {
  content: ""; }

.icon-vcard:before {
  content: ""; }

.icon-address-card-o:before {
  content: ""; }

.icon-vcard-o:before {
  content: ""; }

.icon-user-circle:before {
  content: ""; }

.icon-user-circle-o:before {
  content: ""; }

.icon-user-o:before {
  content: ""; }

.icon-id-badge:before {
  content: ""; }

.icon-drivers-license:before {
  content: ""; }

.icon-id-card:before {
  content: ""; }

.icon-drivers-license-o:before {
  content: ""; }

.icon-id-card-o:before {
  content: ""; }

.icon-quora:before {
  content: ""; }

.icon-free-code-camp:before {
  content: ""; }

.icon-telegram:before {
  content: ""; }

.icon-thermometer:before {
  content: ""; }

.icon-thermometer-4:before {
  content: ""; }

.icon-thermometer-full:before {
  content: ""; }

.icon-thermometer-3:before {
  content: ""; }

.icon-thermometer-three-quarters:before {
  content: ""; }

.icon-thermometer-2:before {
  content: ""; }

.icon-thermometer-half:before {
  content: ""; }

.icon-thermometer-1:before {
  content: ""; }

.icon-thermometer-quarter:before {
  content: ""; }

.icon-thermometer-0:before {
  content: ""; }

.icon-thermometer-empty:before {
  content: ""; }

.icon-shower:before {
  content: ""; }

.icon-bath:before {
  content: ""; }

.icon-bathtub:before {
  content: ""; }

.icon-s15:before {
  content: ""; }

.icon-podcast:before {
  content: ""; }

.icon-window-maximize:before {
  content: ""; }

.icon-window-minimize:before {
  content: ""; }

.icon-window-restore:before {
  content: ""; }

.icon-times-rectangle:before {
  content: ""; }

.icon-window-close:before {
  content: ""; }

.icon-times-rectangle-o:before {
  content: ""; }

.icon-window-close-o:before {
  content: ""; }

.icon-bandcamp:before {
  content: ""; }

.icon-grav:before {
  content: ""; }

.icon-etsy:before {
  content: ""; }

.icon-imdb:before {
  content: ""; }

.icon-ravelry:before {
  content: ""; }

.icon-eercast:before {
  content: ""; }

.icon-microchip:before {
  content: ""; }

.icon-snowflake-o:before {
  content: ""; }

.icon-superpowers:before {
  content: ""; }

.icon-wpexplorer:before {
  content: ""; }

.icon-meetup:before {
  content: ""; }

@keyframes left-to-right {
  from {
    left: 0;
    right: auto; }
  to {
    left: 210%;
    right: auto; } }

@keyframes right-to-left {
  from {
    right: -210%;
    left: auto; }
  to {
    right: 0;
    left: auto; } }

@keyframes menu-scrolled-bottom {
  from {
    background: linear-gradient(0deg, rgba(27, 59, 87, 0) 10%, rgba(0, 33, 84, 0.87) 100%);
    transition: all .5s ease; }
  to {
    background: linear-gradient(0deg, #1b3b57 10%, rgba(0, 33, 84, 0.87) 100%);
    transition: all .5s ease; } }

@keyframes menu-scrolled-top {
  from {
    background: linear-gradient(0deg, #1b3b57 10%, rgba(0, 33, 84, 0.87) 100%);
    transition: all .5s ease; }
  to {
    background: linear-gradient(0deg, rgba(27, 59, 87, 0) 10%, rgba(0, 33, 84, 0.87) 100%);
    transition: all .5s ease; } }

.modal .modal-dialog .modal-content {
  border: 0;
  border-radius: 0; }
  .modal .modal-dialog .modal-content .modal-header {
    background: #f3f3f3;
    border-bottom: 1px solid #f3f3f3; }

.iyilik-butonu-tutorial {
  position: fixed;
  right: 1rem;
  top: 1rem;
  border-radius: 20px;
  box-shadow: 0 3px 15px #495057;
  z-index: 99;
  width: 100%;
  max-width: 300px;
  background-color: #fff;
  padding: 25px;
  overflow: hidden; }
  .iyilik-butonu-tutorial::after {
    content: "";
    position: absolute;
    left: -70px;
    top: -70px;
    width: 150px;
    height: 150px;
    z-index: 9999999;
    background-repeat: no-repeat;
    background-image: url(https://iyilikkazansin.com/assets/site/img/path_1.png);
    background-size: cover; }
  .iyilik-butonu-tutorial .iyilik-butonu-tutorial-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .iyilik-butonu-tutorial .iyilik-butonu-tutorial-wrap .btn-close {
      position: absolute;
      right: 1rem;
      top: 1rem;
      background: transparent;
      border: none;
      color: #ccc; }
      .iyilik-butonu-tutorial .iyilik-butonu-tutorial-wrap .btn-close i {
        font-weight: 300; }
      .iyilik-butonu-tutorial .iyilik-butonu-tutorial-wrap .btn-close:hover {
        color: #8a8a8a; }
    .iyilik-butonu-tutorial .iyilik-butonu-tutorial-wrap .icon-logo {
      font-size: 34px;
      margin-bottom: 1rem; }
    .iyilik-butonu-tutorial .iyilik-butonu-tutorial-wrap strong {
      color: #ff015b;
      font-size: 16px;
      margin-bottom: .5rem;
      font-weight: 500; }
    .iyilik-butonu-tutorial .iyilik-butonu-tutorial-wrap p {
      text-align: center;
      font-size: 14px;
      margin-bottom: 1rem; }
    .iyilik-butonu-tutorial .iyilik-butonu-tutorial-wrap img {
      margin-bottom: .5rem; }
      .iyilik-butonu-tutorial .iyilik-butonu-tutorial-wrap img.questionMark {
        margin: 0; }

.fade-down {
  animation: fade-down .8s ease-in-out 2s both; }

.fade-up {
  animation: fade-up .8s ease-in-out both; }

@-webkit-keyframes fade-down {
  0% {
    transform: translateY(-600px); }
  100% {
    transform: translateY(0); } }

@-webkit-keyframes fade-up {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(-600px); } }

form input {
  padding-left: 30px;
  border-radius: 0 !important;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: 5px center; }
  form input:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: #FCC50A !important; }
  form input.disable-bg {
    background-size: 0 0;
    background-position: 0 0;
    padding-left: 3px; }
  form input[type="text"], form input[type="password"], form input[type="email"], form input[type="tel"] {
    border: none;
    border: 1px solid #C7C7C7;
    font-size: 14px;
    font-weight: 300;
    color: #343a40;
    height: 50px; }
    form input[type="text"]::placeholder, form input[type="password"]::placeholder, form input[type="email"]::placeholder, form input[type="tel"]::placeholder {
      font-size: 14px;
      font-weight: 300;
      color: #C7C7C7; }
    form input[type="text"][readonly], form input[type="password"][readonly], form input[type="email"][readonly], form input[type="tel"][readonly] {
      background-color: transparent; }

form .form-row {
  width: 100%;
  justify-content: space-between;
  margin: 0;
  margin-bottom: 10px; }
  form .form-row .left, form .form-row .right {
    width: 48.5%; }
    form .form-row .left.right, form .form-row .right.right {
      margin-left: 3%; }
  @media (max-width: 767px) {
    form .form-row.three-row .left, form .form-row.three-row .center, form .form-row.three-row .right {
      width: 100% !important; }
      form .form-row.three-row .left.center, form .form-row.three-row .left.right, form .form-row.three-row .center.center, form .form-row.three-row .center.right, form .form-row.three-row .right.center, form .form-row.three-row .right.right {
        margin-left: 0 !important;
        margin-top: 15px; }
      form .form-row.three-row .left.right, form .form-row.three-row .center.right, form .form-row.three-row .right.right {
        margin-bottom: 5px; } }
  form .form-row.three-row .left, form .form-row.three-row .center, form .form-row.three-row .right {
    width: 31.3%; }
    form .form-row.three-row .left.center, form .form-row.three-row .left.right, form .form-row.three-row .center.center, form .form-row.three-row .center.right, form .form-row.three-row .right.center, form .form-row.three-row .right.right {
      margin-left: 2%; }

form textarea {
  border: none;
  border: 1px solid #C7C7C7;
  padding-left: 30px;
  border-radius: 0;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: 5px center;
  font-size: 14px;
  font-weight: 300;
  resize: none; }
  form textarea:focus, form textarea:active, form textarea:hover {
    border-color: #C7C7C7;
    outline: none;
    box-shadow: none; }
  form textarea.disable-bg {
    background-size: 0 0;
    background-position: 0 0;
    padding-left: 3px; }

form span.error-verify {
  font-size: 14px;
  font-weight: 300;
  color: #dc3545; }

input[type=radio] {
  display: none; }
  input[type=radio]:checked + span:after {
    opacity: 1; }
  input[type=radio]:checked + span:before {
    background: #FCC50A; }
  input[type=radio] + span {
    cursor: pointer;
    user-select: none;
    display: inline-block;
    position: relative;
    padding-left: 20px;
    vertical-align: middle; }
    input[type=radio] + span:after {
      transform: scale(0.5);
      content: '';
      display: block;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 5px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #fff;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
      transition: opacity ease-in 0.2s;
      transform: translateY(-50%); }
    input[type=radio] + span:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      width: 15px;
      height: 15px;
      border-radius: 25px;
      background: #FF015B;
      transition: ease-in 0.15s;
      transform: translateY(-50%); }

input[type="checkbox"] {
  display: none; }
  input[type="checkbox"]:checked + span:after {
    left: 25px; }
  input[type="checkbox"]:checked + span:before {
    background: #FCC50A; }
  input[type="checkbox"] + span {
    cursor: pointer;
    user-select: none;
    display: inline-block;
    position: relative;
    padding-left: 65px;
    padding-top: 5px;
    vertical-align: middle; }
    input[type="checkbox"] + span:after {
      transform: scale(0.7);
      content: '';
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #fff;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
      transition: ease-in 0.15s; }
    input[type="checkbox"] + span:before {
      content: '';
      display: block;
      position: absolute;
      top: 0px;
      left: 0;
      width: 55px;
      height: 30px;
      border-radius: 25px;
      background: #2D0A40;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3) inset;
      transition: ease-in 0.15s; }

input[type="file"] {
  display: none; }
  input[type="file"] + span {
    cursor: pointer;
    user-select: none;
    display: inline-block;
    position: relative;
    padding-left: 65px;
    padding-top: 5px;
    vertical-align: middle;
    background: #fff;
    width: 100%;
    border-radius: 100px;
    padding: 10px 15px;
    color: #495057; }

.choices__inner {
  border-radius: 100px !important;
  background: #fff !important;
  border: 0 !important; }

.iyilik-butonu-login-form {
  position: relative;
  z-index: 5;
  max-width: 570px;
  margin: 3rem auto;
  background-color: #fff;
  box-shadow: 0px 0px 15px rgba(73, 80, 87, 0.4);
  border-radius: 20px; }
  .iyilik-butonu-login-form form {
    background-color: #fff;
    position: relative;
    z-index: 5;
    border-radius: 20px;
    padding: 35px 60px; }
    .iyilik-butonu-login-form form .form-group::last-child {
      margin-bottom: 0; }
    .iyilik-butonu-login-form form .form-group .form-control {
      border-radius: 100px !important; }
    .iyilik-butonu-login-form form .form-group .text-muted {
      font-size: 16px; }
  .iyilik-butonu-login-form::before {
    content: '';
    position: absolute;
    left: -50px;
    top: -50px;
    width: 150px;
    height: 150px;
    background-image: url(https://iyilikkazansin.com/assets/site/img/path_1.png);
    background-size: cover;
    z-index: -1; }
  .iyilik-butonu-login-form::after {
    content: '';
    position: absolute;
    right: -50px;
    bottom: -50px;
    width: 150px;
    height: 150px;
    background-image: url(http://127.0.0.1:8000/assets/site/img/path_2.png);
    background-size: cover;
    z-index: -1; }

@media only screen and (max-width: 768px) {
  .iyilik-butonu-login-form form {
    padding: 20px; } }

.btn {
  padding: 5px 20px; }
  .btn-green {
    background: #a5f9d1;
    color: #076539; }
    .btn-green:hover {
      background: #0dc56e;
      color: white; }
  .btn-blue {
    background: #9bf1f7;
    color: #075459; }
    .btn-blue:hover {
      background: #0eadb8;
      color: white; }
  .btn-pink {
    background: #fcc6d9;
    color: #d00c53; }
    .btn-pink:hover {
      background: #f54d8a;
      color: white; }
  .btn-yellow {
    background: #ffeeb8;
    color: #846601; }
    .btn-yellow:hover {
      background: #eab401;
      color: white; }
  .btn-social {
    display: flex;
    background: transparant;
    border: 2px solid #4584EA;
    border-radius: 100px !important;
    color: #4584EA;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    padding: 9px; }
    .btn-social i {
      margin-right: .5rem; }
    .btn-social:hover {
      background-color: #4584EA;
      color: #fff; }
    .btn-social.btn-google {
      border-color: #DB4437;
      color: #DB4437; }
      .btn-social.btn-google:hover {
        background-color: #DB4437;
        color: #fff; }
    .btn-social.btn-register {
      border-color: #FF055B;
      color: #FF055B; }
      .btn-social.btn-register:hover {
        background-color: #FF055B;
        color: #fff; }

body[data-aos-duration='50'] [data-aos], [data-aos][data-aos][data-aos-duration='50'] {
  transition-duration: 50ms; }

body[data-aos-delay='50'] [data-aos], [data-aos][data-aos][data-aos-delay='50'] {
  transition-delay: 0; }
  body[data-aos-delay='50'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='50'].aos-animate {
    transition-delay: 50ms; }

body[data-aos-duration='100'] [data-aos], [data-aos][data-aos][data-aos-duration='100'] {
  transition-duration: 100ms; }

body[data-aos-delay='100'] [data-aos], [data-aos][data-aos][data-aos-delay='100'] {
  transition-delay: 0; }
  body[data-aos-delay='100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='100'].aos-animate {
    transition-delay: 100ms; }

body[data-aos-duration='150'] [data-aos], [data-aos][data-aos][data-aos-duration='150'] {
  transition-duration: 150ms; }

body[data-aos-delay='150'] [data-aos], [data-aos][data-aos][data-aos-delay='150'] {
  transition-delay: 0; }
  body[data-aos-delay='150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='150'].aos-animate {
    transition-delay: 150ms; }

body[data-aos-duration='200'] [data-aos], [data-aos][data-aos][data-aos-duration='200'] {
  transition-duration: 200ms; }

body[data-aos-delay='200'] [data-aos], [data-aos][data-aos][data-aos-delay='200'] {
  transition-delay: 0; }
  body[data-aos-delay='200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='200'].aos-animate {
    transition-delay: 200ms; }

body[data-aos-duration='250'] [data-aos], [data-aos][data-aos][data-aos-duration='250'] {
  transition-duration: 250ms; }

body[data-aos-delay='250'] [data-aos], [data-aos][data-aos][data-aos-delay='250'] {
  transition-delay: 0; }
  body[data-aos-delay='250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='250'].aos-animate {
    transition-delay: 250ms; }

body[data-aos-duration='300'] [data-aos], [data-aos][data-aos][data-aos-duration='300'] {
  transition-duration: 300ms; }

body[data-aos-delay='300'] [data-aos], [data-aos][data-aos][data-aos-delay='300'] {
  transition-delay: 0; }
  body[data-aos-delay='300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='300'].aos-animate {
    transition-delay: 300ms; }

body[data-aos-duration='350'] [data-aos], [data-aos][data-aos][data-aos-duration='350'] {
  transition-duration: 350ms; }

body[data-aos-delay='350'] [data-aos], [data-aos][data-aos][data-aos-delay='350'] {
  transition-delay: 0; }
  body[data-aos-delay='350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='350'].aos-animate {
    transition-delay: 350ms; }

body[data-aos-duration='400'] [data-aos], [data-aos][data-aos][data-aos-duration='400'] {
  transition-duration: 400ms; }

body[data-aos-delay='400'] [data-aos], [data-aos][data-aos][data-aos-delay='400'] {
  transition-delay: 0; }
  body[data-aos-delay='400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='400'].aos-animate {
    transition-delay: 400ms; }

body[data-aos-duration='450'] [data-aos], [data-aos][data-aos][data-aos-duration='450'] {
  transition-duration: 450ms; }

body[data-aos-delay='450'] [data-aos], [data-aos][data-aos][data-aos-delay='450'] {
  transition-delay: 0; }
  body[data-aos-delay='450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='450'].aos-animate {
    transition-delay: 450ms; }

body[data-aos-duration='500'] [data-aos], [data-aos][data-aos][data-aos-duration='500'] {
  transition-duration: 500ms; }

body[data-aos-delay='500'] [data-aos], [data-aos][data-aos][data-aos-delay='500'] {
  transition-delay: 0; }
  body[data-aos-delay='500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='500'].aos-animate {
    transition-delay: 500ms; }

body[data-aos-duration='550'] [data-aos], [data-aos][data-aos][data-aos-duration='550'] {
  transition-duration: 550ms; }

body[data-aos-delay='550'] [data-aos], [data-aos][data-aos][data-aos-delay='550'] {
  transition-delay: 0; }
  body[data-aos-delay='550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='550'].aos-animate {
    transition-delay: 550ms; }

body[data-aos-duration='600'] [data-aos], [data-aos][data-aos][data-aos-duration='600'] {
  transition-duration: 600ms; }

body[data-aos-delay='600'] [data-aos], [data-aos][data-aos][data-aos-delay='600'] {
  transition-delay: 0; }
  body[data-aos-delay='600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='600'].aos-animate {
    transition-delay: 600ms; }

body[data-aos-duration='650'] [data-aos], [data-aos][data-aos][data-aos-duration='650'] {
  transition-duration: 650ms; }

body[data-aos-delay='650'] [data-aos], [data-aos][data-aos][data-aos-delay='650'] {
  transition-delay: 0; }
  body[data-aos-delay='650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='650'].aos-animate {
    transition-delay: 650ms; }

body[data-aos-duration='700'] [data-aos], [data-aos][data-aos][data-aos-duration='700'] {
  transition-duration: 700ms; }

body[data-aos-delay='700'] [data-aos], [data-aos][data-aos][data-aos-delay='700'] {
  transition-delay: 0; }
  body[data-aos-delay='700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='700'].aos-animate {
    transition-delay: 700ms; }

body[data-aos-duration='750'] [data-aos], [data-aos][data-aos][data-aos-duration='750'] {
  transition-duration: 750ms; }

body[data-aos-delay='750'] [data-aos], [data-aos][data-aos][data-aos-delay='750'] {
  transition-delay: 0; }
  body[data-aos-delay='750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='750'].aos-animate {
    transition-delay: 750ms; }

body[data-aos-duration='800'] [data-aos], [data-aos][data-aos][data-aos-duration='800'] {
  transition-duration: 800ms; }

body[data-aos-delay='800'] [data-aos], [data-aos][data-aos][data-aos-delay='800'] {
  transition-delay: 0; }
  body[data-aos-delay='800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='800'].aos-animate {
    transition-delay: 800ms; }

body[data-aos-duration='850'] [data-aos], [data-aos][data-aos][data-aos-duration='850'] {
  transition-duration: 850ms; }

body[data-aos-delay='850'] [data-aos], [data-aos][data-aos][data-aos-delay='850'] {
  transition-delay: 0; }
  body[data-aos-delay='850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='850'].aos-animate {
    transition-delay: 850ms; }

body[data-aos-duration='900'] [data-aos], [data-aos][data-aos][data-aos-duration='900'] {
  transition-duration: 900ms; }

body[data-aos-delay='900'] [data-aos], [data-aos][data-aos][data-aos-delay='900'] {
  transition-delay: 0; }
  body[data-aos-delay='900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='900'].aos-animate {
    transition-delay: 900ms; }

body[data-aos-duration='950'] [data-aos], [data-aos][data-aos][data-aos-duration='950'] {
  transition-duration: 950ms; }

body[data-aos-delay='950'] [data-aos], [data-aos][data-aos][data-aos-delay='950'] {
  transition-delay: 0; }
  body[data-aos-delay='950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='950'].aos-animate {
    transition-delay: 950ms; }

body[data-aos-duration='1000'] [data-aos], [data-aos][data-aos][data-aos-duration='1000'] {
  transition-duration: 1000ms; }

body[data-aos-delay='1000'] [data-aos], [data-aos][data-aos][data-aos-delay='1000'] {
  transition-delay: 0; }
  body[data-aos-delay='1000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1000'].aos-animate {
    transition-delay: 1000ms; }

body[data-aos-duration='1050'] [data-aos], [data-aos][data-aos][data-aos-duration='1050'] {
  transition-duration: 1050ms; }

body[data-aos-delay='1050'] [data-aos], [data-aos][data-aos][data-aos-delay='1050'] {
  transition-delay: 0; }
  body[data-aos-delay='1050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1050'].aos-animate {
    transition-delay: 1050ms; }

body[data-aos-duration='1100'] [data-aos], [data-aos][data-aos][data-aos-duration='1100'] {
  transition-duration: 1100ms; }

body[data-aos-delay='1100'] [data-aos], [data-aos][data-aos][data-aos-delay='1100'] {
  transition-delay: 0; }
  body[data-aos-delay='1100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1100'].aos-animate {
    transition-delay: 1100ms; }

body[data-aos-duration='1150'] [data-aos], [data-aos][data-aos][data-aos-duration='1150'] {
  transition-duration: 1150ms; }

body[data-aos-delay='1150'] [data-aos], [data-aos][data-aos][data-aos-delay='1150'] {
  transition-delay: 0; }
  body[data-aos-delay='1150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1150'].aos-animate {
    transition-delay: 1150ms; }

body[data-aos-duration='1200'] [data-aos], [data-aos][data-aos][data-aos-duration='1200'] {
  transition-duration: 1200ms; }

body[data-aos-delay='1200'] [data-aos], [data-aos][data-aos][data-aos-delay='1200'] {
  transition-delay: 0; }
  body[data-aos-delay='1200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1200'].aos-animate {
    transition-delay: 1200ms; }

body[data-aos-duration='1250'] [data-aos], [data-aos][data-aos][data-aos-duration='1250'] {
  transition-duration: 1250ms; }

body[data-aos-delay='1250'] [data-aos], [data-aos][data-aos][data-aos-delay='1250'] {
  transition-delay: 0; }
  body[data-aos-delay='1250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1250'].aos-animate {
    transition-delay: 1250ms; }

body[data-aos-duration='1300'] [data-aos], [data-aos][data-aos][data-aos-duration='1300'] {
  transition-duration: 1300ms; }

body[data-aos-delay='1300'] [data-aos], [data-aos][data-aos][data-aos-delay='1300'] {
  transition-delay: 0; }
  body[data-aos-delay='1300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1300'].aos-animate {
    transition-delay: 1300ms; }

body[data-aos-duration='1350'] [data-aos], [data-aos][data-aos][data-aos-duration='1350'] {
  transition-duration: 1350ms; }

body[data-aos-delay='1350'] [data-aos], [data-aos][data-aos][data-aos-delay='1350'] {
  transition-delay: 0; }
  body[data-aos-delay='1350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1350'].aos-animate {
    transition-delay: 1350ms; }

body[data-aos-duration='1400'] [data-aos], [data-aos][data-aos][data-aos-duration='1400'] {
  transition-duration: 1400ms; }

body[data-aos-delay='1400'] [data-aos], [data-aos][data-aos][data-aos-delay='1400'] {
  transition-delay: 0; }
  body[data-aos-delay='1400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1400'].aos-animate {
    transition-delay: 1400ms; }

body[data-aos-duration='1450'] [data-aos], [data-aos][data-aos][data-aos-duration='1450'] {
  transition-duration: 1450ms; }

body[data-aos-delay='1450'] [data-aos], [data-aos][data-aos][data-aos-delay='1450'] {
  transition-delay: 0; }
  body[data-aos-delay='1450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1450'].aos-animate {
    transition-delay: 1450ms; }

body[data-aos-duration='1500'] [data-aos], [data-aos][data-aos][data-aos-duration='1500'] {
  transition-duration: 1500ms; }

body[data-aos-delay='1500'] [data-aos], [data-aos][data-aos][data-aos-delay='1500'] {
  transition-delay: 0; }
  body[data-aos-delay='1500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1500'].aos-animate {
    transition-delay: 1500ms; }

body[data-aos-duration='1550'] [data-aos], [data-aos][data-aos][data-aos-duration='1550'] {
  transition-duration: 1550ms; }

body[data-aos-delay='1550'] [data-aos], [data-aos][data-aos][data-aos-delay='1550'] {
  transition-delay: 0; }
  body[data-aos-delay='1550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1550'].aos-animate {
    transition-delay: 1550ms; }

body[data-aos-duration='1600'] [data-aos], [data-aos][data-aos][data-aos-duration='1600'] {
  transition-duration: 1600ms; }

body[data-aos-delay='1600'] [data-aos], [data-aos][data-aos][data-aos-delay='1600'] {
  transition-delay: 0; }
  body[data-aos-delay='1600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1600'].aos-animate {
    transition-delay: 1600ms; }

body[data-aos-duration='1650'] [data-aos], [data-aos][data-aos][data-aos-duration='1650'] {
  transition-duration: 1650ms; }

body[data-aos-delay='1650'] [data-aos], [data-aos][data-aos][data-aos-delay='1650'] {
  transition-delay: 0; }
  body[data-aos-delay='1650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1650'].aos-animate {
    transition-delay: 1650ms; }

body[data-aos-duration='1700'] [data-aos], [data-aos][data-aos][data-aos-duration='1700'] {
  transition-duration: 1700ms; }

body[data-aos-delay='1700'] [data-aos], [data-aos][data-aos][data-aos-delay='1700'] {
  transition-delay: 0; }
  body[data-aos-delay='1700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1700'].aos-animate {
    transition-delay: 1700ms; }

body[data-aos-duration='1750'] [data-aos], [data-aos][data-aos][data-aos-duration='1750'] {
  transition-duration: 1750ms; }

body[data-aos-delay='1750'] [data-aos], [data-aos][data-aos][data-aos-delay='1750'] {
  transition-delay: 0; }
  body[data-aos-delay='1750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1750'].aos-animate {
    transition-delay: 1750ms; }

body[data-aos-duration='1800'] [data-aos], [data-aos][data-aos][data-aos-duration='1800'] {
  transition-duration: 1800ms; }

body[data-aos-delay='1800'] [data-aos], [data-aos][data-aos][data-aos-delay='1800'] {
  transition-delay: 0; }
  body[data-aos-delay='1800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1800'].aos-animate {
    transition-delay: 1800ms; }

body[data-aos-duration='1850'] [data-aos], [data-aos][data-aos][data-aos-duration='1850'] {
  transition-duration: 1850ms; }

body[data-aos-delay='1850'] [data-aos], [data-aos][data-aos][data-aos-delay='1850'] {
  transition-delay: 0; }
  body[data-aos-delay='1850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1850'].aos-animate {
    transition-delay: 1850ms; }

body[data-aos-duration='1900'] [data-aos], [data-aos][data-aos][data-aos-duration='1900'] {
  transition-duration: 1900ms; }

body[data-aos-delay='1900'] [data-aos], [data-aos][data-aos][data-aos-delay='1900'] {
  transition-delay: 0; }
  body[data-aos-delay='1900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1900'].aos-animate {
    transition-delay: 1900ms; }

body[data-aos-duration='1950'] [data-aos], [data-aos][data-aos][data-aos-duration='1950'] {
  transition-duration: 1950ms; }

body[data-aos-delay='1950'] [data-aos], [data-aos][data-aos][data-aos-delay='1950'] {
  transition-delay: 0; }
  body[data-aos-delay='1950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1950'].aos-animate {
    transition-delay: 1950ms; }

body[data-aos-duration='2000'] [data-aos], [data-aos][data-aos][data-aos-duration='2000'] {
  transition-duration: 2000ms; }

body[data-aos-delay='2000'] [data-aos], [data-aos][data-aos][data-aos-delay='2000'] {
  transition-delay: 0; }
  body[data-aos-delay='2000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2000'].aos-animate {
    transition-delay: 2000ms; }

body[data-aos-duration='2050'] [data-aos], [data-aos][data-aos][data-aos-duration='2050'] {
  transition-duration: 2050ms; }

body[data-aos-delay='2050'] [data-aos], [data-aos][data-aos][data-aos-delay='2050'] {
  transition-delay: 0; }
  body[data-aos-delay='2050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2050'].aos-animate {
    transition-delay: 2050ms; }

body[data-aos-duration='2100'] [data-aos], [data-aos][data-aos][data-aos-duration='2100'] {
  transition-duration: 2100ms; }

body[data-aos-delay='2100'] [data-aos], [data-aos][data-aos][data-aos-delay='2100'] {
  transition-delay: 0; }
  body[data-aos-delay='2100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2100'].aos-animate {
    transition-delay: 2100ms; }

body[data-aos-duration='2150'] [data-aos], [data-aos][data-aos][data-aos-duration='2150'] {
  transition-duration: 2150ms; }

body[data-aos-delay='2150'] [data-aos], [data-aos][data-aos][data-aos-delay='2150'] {
  transition-delay: 0; }
  body[data-aos-delay='2150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2150'].aos-animate {
    transition-delay: 2150ms; }

body[data-aos-duration='2200'] [data-aos], [data-aos][data-aos][data-aos-duration='2200'] {
  transition-duration: 2200ms; }

body[data-aos-delay='2200'] [data-aos], [data-aos][data-aos][data-aos-delay='2200'] {
  transition-delay: 0; }
  body[data-aos-delay='2200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2200'].aos-animate {
    transition-delay: 2200ms; }

body[data-aos-duration='2250'] [data-aos], [data-aos][data-aos][data-aos-duration='2250'] {
  transition-duration: 2250ms; }

body[data-aos-delay='2250'] [data-aos], [data-aos][data-aos][data-aos-delay='2250'] {
  transition-delay: 0; }
  body[data-aos-delay='2250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2250'].aos-animate {
    transition-delay: 2250ms; }

body[data-aos-duration='2300'] [data-aos], [data-aos][data-aos][data-aos-duration='2300'] {
  transition-duration: 2300ms; }

body[data-aos-delay='2300'] [data-aos], [data-aos][data-aos][data-aos-delay='2300'] {
  transition-delay: 0; }
  body[data-aos-delay='2300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2300'].aos-animate {
    transition-delay: 2300ms; }

body[data-aos-duration='2350'] [data-aos], [data-aos][data-aos][data-aos-duration='2350'] {
  transition-duration: 2350ms; }

body[data-aos-delay='2350'] [data-aos], [data-aos][data-aos][data-aos-delay='2350'] {
  transition-delay: 0; }
  body[data-aos-delay='2350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2350'].aos-animate {
    transition-delay: 2350ms; }

body[data-aos-duration='2400'] [data-aos], [data-aos][data-aos][data-aos-duration='2400'] {
  transition-duration: 2400ms; }

body[data-aos-delay='2400'] [data-aos], [data-aos][data-aos][data-aos-delay='2400'] {
  transition-delay: 0; }
  body[data-aos-delay='2400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2400'].aos-animate {
    transition-delay: 2400ms; }

body[data-aos-duration='2450'] [data-aos], [data-aos][data-aos][data-aos-duration='2450'] {
  transition-duration: 2450ms; }

body[data-aos-delay='2450'] [data-aos], [data-aos][data-aos][data-aos-delay='2450'] {
  transition-delay: 0; }
  body[data-aos-delay='2450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2450'].aos-animate {
    transition-delay: 2450ms; }

body[data-aos-duration='2500'] [data-aos], [data-aos][data-aos][data-aos-duration='2500'] {
  transition-duration: 2500ms; }

body[data-aos-delay='2500'] [data-aos], [data-aos][data-aos][data-aos-delay='2500'] {
  transition-delay: 0; }
  body[data-aos-delay='2500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2500'].aos-animate {
    transition-delay: 2500ms; }

body[data-aos-duration='2550'] [data-aos], [data-aos][data-aos][data-aos-duration='2550'] {
  transition-duration: 2550ms; }

body[data-aos-delay='2550'] [data-aos], [data-aos][data-aos][data-aos-delay='2550'] {
  transition-delay: 0; }
  body[data-aos-delay='2550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2550'].aos-animate {
    transition-delay: 2550ms; }

body[data-aos-duration='2600'] [data-aos], [data-aos][data-aos][data-aos-duration='2600'] {
  transition-duration: 2600ms; }

body[data-aos-delay='2600'] [data-aos], [data-aos][data-aos][data-aos-delay='2600'] {
  transition-delay: 0; }
  body[data-aos-delay='2600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2600'].aos-animate {
    transition-delay: 2600ms; }

body[data-aos-duration='2650'] [data-aos], [data-aos][data-aos][data-aos-duration='2650'] {
  transition-duration: 2650ms; }

body[data-aos-delay='2650'] [data-aos], [data-aos][data-aos][data-aos-delay='2650'] {
  transition-delay: 0; }
  body[data-aos-delay='2650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2650'].aos-animate {
    transition-delay: 2650ms; }

body[data-aos-duration='2700'] [data-aos], [data-aos][data-aos][data-aos-duration='2700'] {
  transition-duration: 2700ms; }

body[data-aos-delay='2700'] [data-aos], [data-aos][data-aos][data-aos-delay='2700'] {
  transition-delay: 0; }
  body[data-aos-delay='2700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2700'].aos-animate {
    transition-delay: 2700ms; }

body[data-aos-duration='2750'] [data-aos], [data-aos][data-aos][data-aos-duration='2750'] {
  transition-duration: 2750ms; }

body[data-aos-delay='2750'] [data-aos], [data-aos][data-aos][data-aos-delay='2750'] {
  transition-delay: 0; }
  body[data-aos-delay='2750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2750'].aos-animate {
    transition-delay: 2750ms; }

body[data-aos-duration='2800'] [data-aos], [data-aos][data-aos][data-aos-duration='2800'] {
  transition-duration: 2800ms; }

body[data-aos-delay='2800'] [data-aos], [data-aos][data-aos][data-aos-delay='2800'] {
  transition-delay: 0; }
  body[data-aos-delay='2800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2800'].aos-animate {
    transition-delay: 2800ms; }

body[data-aos-duration='2850'] [data-aos], [data-aos][data-aos][data-aos-duration='2850'] {
  transition-duration: 2850ms; }

body[data-aos-delay='2850'] [data-aos], [data-aos][data-aos][data-aos-delay='2850'] {
  transition-delay: 0; }
  body[data-aos-delay='2850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2850'].aos-animate {
    transition-delay: 2850ms; }

body[data-aos-duration='2900'] [data-aos], [data-aos][data-aos][data-aos-duration='2900'] {
  transition-duration: 2900ms; }

body[data-aos-delay='2900'] [data-aos], [data-aos][data-aos][data-aos-delay='2900'] {
  transition-delay: 0; }
  body[data-aos-delay='2900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2900'].aos-animate {
    transition-delay: 2900ms; }

body[data-aos-duration='2950'] [data-aos], [data-aos][data-aos][data-aos-duration='2950'] {
  transition-duration: 2950ms; }

body[data-aos-delay='2950'] [data-aos], [data-aos][data-aos][data-aos-delay='2950'] {
  transition-delay: 0; }
  body[data-aos-delay='2950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2950'].aos-animate {
    transition-delay: 2950ms; }

body[data-aos-duration='3000'] [data-aos], [data-aos][data-aos][data-aos-duration='3000'] {
  transition-duration: 3000ms; }

body[data-aos-delay='3000'] [data-aos], [data-aos][data-aos][data-aos-delay='3000'] {
  transition-delay: 0; }
  body[data-aos-delay='3000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='3000'].aos-animate {
    transition-delay: 3000ms; }

body[data-aos-easing="linear"] [data-aos], [data-aos][data-aos][data-aos-easing="linear"] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); }

body[data-aos-easing="ease-in"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in"] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1); }

body[data-aos-easing="ease-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out"] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1); }

body[data-aos-easing="ease-in-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out"] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1); }

body[data-aos-easing="ease-in-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

body[data-aos-easing="ease-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

body[data-aos-easing="ease-in-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

body[data-aos-easing="ease-in-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

body[data-aos-easing="ease-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

body[data-aos-easing="ease-in-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

body[data-aos-easing="ease-in-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

/**
 * Fade animations:
 * fade
 * fade-up, fade-down, fade-left, fade-right
 * fade-up-right, fade-up-left, fade-down-right, fade-down-left
 */
[data-aos^='fade'][data-aos^='fade'] {
  opacity: 0;
  transition-property: opacity, transform; }
  [data-aos^='fade'][data-aos^='fade'].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0); }

[data-aos='fade-up'] {
  transform: translate3d(0, 100px, 0); }

[data-aos='fade-down'] {
  transform: translate3d(0, -100px, 0); }

[data-aos='fade-right'] {
  transform: translate3d(-100px, 0, 0); }

[data-aos='fade-left'] {
  transform: translate3d(100px, 0, 0); }

[data-aos='fade-up-right'] {
  transform: translate3d(-100px, 100px, 0); }

[data-aos='fade-up-left'] {
  transform: translate3d(100px, 100px, 0); }

[data-aos='fade-down-right'] {
  transform: translate3d(-100px, -100px, 0); }

[data-aos='fade-down-left'] {
  transform: translate3d(100px, -100px, 0); }

/**
 * Zoom animations:
 * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
 * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
 */
[data-aos^='zoom'][data-aos^='zoom'] {
  opacity: 0;
  transition-property: opacity, transform; }
  [data-aos^='zoom'][data-aos^='zoom'].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1); }

[data-aos='zoom-in'] {
  transform: scale(0.6); }

[data-aos='zoom-in-up'] {
  transform: translate3d(0, 100px, 0) scale(0.6); }

[data-aos='zoom-in-down'] {
  transform: translate3d(0, -100px, 0) scale(0.6); }

[data-aos='zoom-in-right'] {
  transform: translate3d(-100px, 0, 0) scale(0.6); }

[data-aos='zoom-in-left'] {
  transform: translate3d(100px, 0, 0) scale(0.6); }

[data-aos='zoom-out'] {
  transform: scale(1.2); }

[data-aos='zoom-out-up'] {
  transform: translate3d(0, 100px, 0) scale(1.2); }

[data-aos='zoom-out-down'] {
  transform: translate3d(0, -100px, 0) scale(1.2); }

[data-aos='zoom-out-right'] {
  transform: translate3d(-100px, 0, 0) scale(1.2); }

[data-aos='zoom-out-left'] {
  transform: translate3d(100px, 0, 0) scale(1.2); }

/**
 * Slide animations
 */
[data-aos^='slide'][data-aos^='slide'] {
  transition-property: transform; }
  [data-aos^='slide'][data-aos^='slide'].aos-animate {
    transform: translate3d(0, 0, 0); }

[data-aos='slide-up'] {
  transform: translate3d(0, 100%, 0); }

[data-aos='slide-down'] {
  transform: translate3d(0, -100%, 0); }

[data-aos='slide-right'] {
  transform: translate3d(-100%, 0, 0); }

[data-aos='slide-left'] {
  transform: translate3d(100%, 0, 0); }

/**
 * Flip animations:
 * flip-left, flip-right, flip-up, flip-down
 */
[data-aos^='flip'][data-aos^='flip'] {
  backface-visibility: hidden;
  transition-property: transform; }

[data-aos='flip-left'] {
  transform: perspective(2500px) rotateY(-100deg); }
  [data-aos='flip-left'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-right'] {
  transform: perspective(2500px) rotateY(100deg); }
  [data-aos='flip-right'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-up'] {
  transform: perspective(2500px) rotateX(-100deg); }
  [data-aos='flip-up'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

[data-aos='flip-down'] {
  transform: perspective(2500px) rotateX(100deg); }
  [data-aos='flip-down'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  /* Fix of Webkit flickering */
  z-index: 1; }

.swiper-container-no-flexbox .swiper-slide {
  float: left; }

.swiper-container-vertical > .swiper-wrapper {
  flex-direction: column; }

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box; }

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0); }

.swiper-container-multirow > .swiper-wrapper {
  flex-wrap: wrap; }

.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto; }

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform; }

.swiper-slide-invisible-blank {
  visibility: hidden; }

/* Auto Height */
.swiper-container-autoheight, .swiper-container-autoheight .swiper-slide {
  height: auto; }

.swiper-container-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform, height; }

/* 3D Effects */
.swiper-container-3d {
  perspective: 1200px; }
  .swiper-container-3d .swiper-wrapper, .swiper-container-3d .swiper-slide, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-cube-shadow {
    transform-style: preserve-3d; }
  .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-slide-shadow-bottom {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10; }
  .swiper-container-3d .swiper-slide-shadow-left {
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
  .swiper-container-3d .swiper-slide-shadow-right {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
  .swiper-container-3d .swiper-slide-shadow-top {
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
  .swiper-container-3d .swiper-slide-shadow-bottom {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

/* IE10 Windows Phone 8 Fixes */
.swiper-container-wp8-horizontal, .swiper-container-wp8-horizontal > .swiper-wrapper {
  touch-action: pan-y; }

.swiper-container-wp8-vertical, .swiper-container-wp8-vertical > .swiper-wrapper {
  touch-action: pan-x; }

.dp-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.75);
  z-index: 9; }

.dp {
  position: relative;
  background: #FFF;
  box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.25);
  line-height: 1.4;
  border-radius: 4px;
  max-height: 400px;
  z-index: 1000;
  padding-top: 6px;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent; }

.dp:before {
  content: ' ';
  height: 6px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #3B99FC;
  background: linear-gradient(-90deg, #3B99FC 0%, #8AEFC8 100%); }

.dp-permanent .dp {
  padding-top: 0;
  border: 1px solid #EEE;
  box-shadow: none; }

.dp-permanent .dp:before {
  display: none; }

.dp-cal {
  min-height: 300px; }

.dp-below {
  position: absolute;
  font-size: 0.8em;
  width: 400px;
  max-width: 100vw; }

.dp-permanent {
  position: relative;
  font-size: 0.8em;
  width: 400px;
  max-width: 100vw; }

.dp-permanent .dp {
  z-index: 0; }

.dp-modal .dp {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 600px;
  width: calc(100% - 4em);
  transform: translate(-50%, -50%);
  animation: slide-up 0.3s forwards; }

.dp-months {
  padding: 24px; }

.dp-years {
  box-sizing: border-box;
  max-height: 400px;
  padding: 8px 0;
  overflow: auto !important;
  /* HACK for Chrome on Android */ }

.dp-cal-month,
.dp-cal-year,
.dp-day,
.dp-month,
.dp-year {
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  position: relative;
  color: #3B404D;
  border-radius: 2px;
  border: 0;
  background: transparent; }

.dp-cal-header {
  position: relative;
  text-align: center;
  padding-bottom: 16px;
  background: #f5f5f5; }

.dp-next,
.dp-prev {
  position: absolute;
  width: 30px;
  height: 30px;
  overflow: hidden;
  top: 14px;
  color: #777;
  border-radius: 2px;
  border: 0;
  background: transparent; }

.dp-next:focus,
.dp-prev:focus,
.dp-next:hover,
.dp-prev:hover {
  outline: none;
  color: inherit; }

.dp-prev {
  left: 24px; }

.dp-next {
  right: 24px; }

.dp-prev:before,
.dp-next:before {
  content: '';
  border: 2px solid;
  width: 10px;
  height: 10px;
  display: inline-block;
  transform: rotate(-45deg);
  transition: border-color 0.2s;
  margin: 9px 0 40px 4px; }

.dp-prev:before {
  border-right: 0;
  border-bottom: 0; }

.dp-next:before {
  border-left: 0;
  border-top: 0;
  margin-left: 0;
  margin-right: 4px; }

.dp-cal-month,
.dp-cal-year {
  display: inline-block;
  font-size: 1.4em;
  padding: 16px 8px 8px;
  outline: none; }

.dp-cal-footer {
  text-align: center;
  background: #f5f5f5;
  margin: 0; }

.dp-day-today:after {
  content: '';
  height: 0;
  width: 0;
  border: 7px solid #227BD7;
  border-bottom-color: transparent;
  border-left-color: transparent;
  position: absolute;
  top: 0;
  right: 0; }

.dp-close,
.dp-clear,
.dp-today {
  box-sizing: border-box;
  display: inline-block;
  width: 33%;
  padding: 8px;
  text-decoration: none;
  color: inherit;
  border: 0;
  background: transparent; }

.dp-permanent .dp-close,
.dp-permanent .dp-clear {
  display: none; }

.dp-close:active,
.dp-clear:active,
.dp-today:active,
.dp-next:active,
.dp-prev:active,
.dp-cal-month:active,
.dp-cal-year:active {
  background: #75BCFC;
  color: white; }

@media screen and (min-device-width: 1200px) {
  .dp-close:hover,
  .dp-close:focus,
  .dp-clear:hover,
  .dp-clear:focus,
  .dp-today:hover,
  .dp-today:focus,
  .dp-next:hover,
  .dp-next:focus,
  .dp-prev:hover,
  .dp-prev:focus,
  .dp-cal-month:focus,
  .dp-cal-month:hover,
  .dp-cal-year:hover,
  .dp-cal-year:focus {
    background: #75BCFC;
    color: white; } }

.dp-col-header,
.dp-day {
  width: 14.28571429%;
  display: inline-block;
  padding: 8px;
  text-align: center; }

.dp-col-header {
  color: #AAA;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 0.8em;
  padding: 8px 0; }

.dp-month {
  width: 33%;
  display: inline-block;
  padding: 8px; }

.dp-year {
  display: block;
  padding: 8px 40px;
  width: 100%; }

.dp-edge-day {
  color: #AAA; }

.dp-day:hover,
.dp-month:hover,
.dp-year:hover,
.dp-current:focus,
.dp-current,
.dp-day:focus,
.dp-month:focus,
.dp-year:focus {
  outline: none;
  background: #75BCFC;
  color: white; }

.dp-selected:hover,
.dp-selected:focus,
.dp-selected {
  background: #3B99FC;
  color: #FFF; }

.dp-day-disabled {
  background: transparent;
  color: #DDD; }

.dp-day-disabled:focus,
.dp-day-disabled:hover {
  background: #DDD; }

.dp-focuser {
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%; }

/* Responsive overrides */
@media (max-width: 480px), (max-height: 480px) {
  .dp-modal .dp {
    font-size: 0.9em;
    width: auto;
    width: 100%; }
  .dp-day-of-week,
  .dp-day {
    padding: 8px; } }

@keyframes slide-up {
  0% {
    transform: translate(-50%, 100%); }
  100% {
    transform: translate(-50%, -50%); } }

/*===============================
=            Choices            =
===============================*/
.choices {
  position: relative;
  margin-bottom: 24px;
  font-size: 16px; }
  .choices:focus {
    outline: none; }
  .choices:last-child {
    margin-bottom: 0; }
  .choices.is-disabled .choices__inner,
  .choices.is-disabled .choices__input {
    background-color: #eaeaea;
    cursor: not-allowed;
    user-select: none; }
  .choices.is-disabled .choices__item {
    cursor: not-allowed; }
  .choices [hidden] {
    display: none !important; }

.choices[data-type*='select-one'] {
  cursor: pointer; }
  .choices[data-type*='select-one'] .choices__inner {
    padding-bottom: 7.5px; }
  .choices[data-type*='select-one'] .choices__input {
    display: block;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #dddddd;
    background-color: #ffffff;
    margin: 0; }
  .choices[data-type*='select-one'] .choices__button {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
    padding: 0;
    background-size: 8px;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -10px;
    margin-right: 25px;
    height: 20px;
    width: 20px;
    border-radius: 10em;
    opacity: 0.5; }
    .choices[data-type*='select-one'] .choices__button:hover, .choices[data-type*='select-one'] .choices__button:focus {
      opacity: 1; }
    .choices[data-type*='select-one'] .choices__button:focus {
      box-shadow: 0px 0px 0px 2px #00bcd4; }
  .choices[data-type*='select-one'] .choices__item[data-value=''] .choices__button {
    display: none; }
  .choices[data-type*='select-one']:after {
    content: '';
    height: 0;
    width: 0;
    border-style: solid;
    border-color: #333333 transparent transparent transparent;
    border-width: 5px;
    position: absolute;
    right: 11.5px;
    top: 50%;
    margin-top: -2.5px;
    pointer-events: none; }
  .choices[data-type*='select-one'].is-open:after {
    border-color: transparent transparent #333333 transparent;
    margin-top: -7.5px; }
  .choices[data-type*='select-one'][dir='rtl']:after {
    left: 11.5px;
    right: auto; }
  .choices[data-type*='select-one'][dir='rtl'] .choices__button {
    right: auto;
    left: 0;
    margin-left: 25px;
    margin-right: 0; }

.choices[data-type*='select-multiple'] .choices__inner,
.choices[data-type*='text'] .choices__inner {
  cursor: text; }

.choices[data-type*='select-multiple'] .choices__button,
.choices[data-type*='text'] .choices__button {
  position: relative;
  display: inline-block;
  margin-top: 0;
  margin-right: -4px;
  margin-bottom: 0;
  margin-left: 8px;
  padding-left: 16px;
  border-left: 1px solid #008fa1;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 8px;
  width: 8px;
  line-height: 1;
  opacity: 0.75;
  border-radius: 0; }
  .choices[data-type*='select-multiple'] .choices__button:hover, .choices[data-type*='select-multiple'] .choices__button:focus,
  .choices[data-type*='text'] .choices__button:hover,
  .choices[data-type*='text'] .choices__button:focus {
    opacity: 1; }

.choices__inner {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background-color: #f9f9f9;
  padding: 7.5px 7.5px 3.75px;
  border: 1px solid #dddddd;
  border-radius: 2.5px;
  font-size: 14px;
  min-height: 44px;
  overflow: hidden; }
  .is-focused .choices__inner,
  .is-open .choices__inner {
    border-color: #b7b7b7; }
  .is-open .choices__inner {
    border-radius: 2.5px 2.5px 0 0; }
  .is-flipped.is-open .choices__inner {
    border-radius: 0 0 2.5px 2.5px; }

.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none; }

.choices__list--single {
  display: inline-block;
  padding: 4px 16px 4px 4px;
  width: 100%; }
  [dir='rtl'] .choices__list--single {
    padding-right: 4px;
    padding-left: 16px; }
  .choices__list--single .choices__item {
    width: 100%; }

.choices__list--multiple {
  display: inline; }
  .choices__list--multiple .choices__item {
    display: inline-block;
    vertical-align: middle;
    border-radius: 20px;
    padding: 4px 10px;
    font-size: 12px;
    font-weight: 500;
    margin-right: 3.75px;
    margin-bottom: 3.75px;
    background-color: #00bcd4;
    border: 1px solid #00a5bb;
    color: #ffffff;
    word-break: break-all;
    box-sizing: border-box; }
    .choices__list--multiple .choices__item[data-deletable] {
      padding-right: 5px; }
    [dir='rtl'] .choices__list--multiple .choices__item {
      margin-right: 0;
      margin-left: 3.75px; }
    .choices__list--multiple .choices__item.is-highlighted {
      background-color: #00a5bb;
      border: 1px solid #008fa1; }
    .is-disabled .choices__list--multiple .choices__item {
      background-color: #aaaaaa;
      border: 1px solid #919191; }

.choices__list--dropdown {
  visibility: hidden;
  z-index: 1;
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  top: 100%;
  margin-top: -1px;
  border-bottom-left-radius: 2.5px;
  border-bottom-right-radius: 2.5px;
  overflow: hidden;
  word-break: break-all;
  will-change: visibility; }
  .choices__list--dropdown.is-active {
    visibility: visible; }
  .is-open .choices__list--dropdown {
    border-color: #b7b7b7; }
  .is-flipped .choices__list--dropdown {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: -1px;
    border-radius: 0.25rem 0.25rem 0 0; }
  .choices__list--dropdown .choices__list {
    position: relative;
    max-height: 300px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    will-change: scroll-position; }
  .choices__list--dropdown .choices__item {
    position: relative;
    padding: 10px;
    font-size: 14px; }
    [dir='rtl'] .choices__list--dropdown .choices__item {
      text-align: right; }
  @media (min-width: 640px) {
    .choices__list--dropdown .choices__item--selectable {
      padding-right: 100px; }
      .choices__list--dropdown .choices__item--selectable:after {
        content: attr(data-select-text);
        font-size: 12px;
        opacity: 0;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%); }
      [dir='rtl'] .choices__list--dropdown .choices__item--selectable {
        text-align: right;
        padding-left: 100px;
        padding-right: 10px; }
        [dir='rtl'] .choices__list--dropdown .choices__item--selectable:after {
          right: auto;
          left: 10px; } }
  .choices__list--dropdown .choices__item--selectable.is-highlighted {
    background-color: #f2f2f2; }
    .choices__list--dropdown .choices__item--selectable.is-highlighted:after {
      opacity: 0.5; }

.choices__item {
  cursor: default; }

.choices__item--selectable {
  cursor: pointer; }

.choices__item--disabled {
  cursor: not-allowed;
  user-select: none;
  opacity: 0.5; }

.choices__heading {
  font-weight: 600;
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid #f7f7f7;
  color: gray; }

.choices__button {
  text-indent: -9999px;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer; }
  .choices__button:focus {
    outline: none; }

.choices__input {
  display: inline-block;
  vertical-align: baseline;
  background-color: #f9f9f9;
  font-size: 14px;
  margin-bottom: 5px;
  border: 0;
  border-radius: 0;
  max-width: 100%;
  padding: 4px 0 4px 2px; }
  .choices__input:focus {
    outline: 0; }
  [dir='rtl'] .choices__input {
    padding-right: 2px;
    padding-left: 0; }

.choices__placeholder {
  opacity: 0.5; }

/*=====  End of Choices  ======*/
.menu-mobile {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 15px;
  display: none;
  top: 22px;
  padding: 5px; }
  .menu-mobile span {
    position: absolute;
    width: 75%;
    height: 2px;
    background: #ff005b;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.5s ease all; }
    .menu-mobile span:before {
      width: 100%;
      height: 2px;
      position: absolute;
      top: -7px;
      content: '';
      transition: 0.5s ease all;
      background: #ff005b; }
    .menu-mobile span:after {
      width: 100%;
      height: 2px;
      position: absolute;
      top: 7px;
      content: '';
      transition: 0.5s ease all;
      background: #ff005b; }
  .menu-mobile.active span {
    transform: rotate(45deg); }
    .menu-mobile.active span:before {
      transform: rotate(-90deg);
      top: 1px; }
    .menu-mobile.active span:after {
      opacity: 0; }

header {
  padding: 10px 0;
  float: left;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1; }
  header .logo {
    width: 217px;
    float: left;
    position: relative;
    margin-right: 15px; }
    header .logo .header-bg {
      width: 482px;
      height: 416px;
      position: absolute;
      top: -167px;
      left: -175px;
      background-repeat: no-repeat;
      /* transform: rotate(20deg); */
      z-index: 0;
      background-size: 449px; }
    header .logo a {
      font-size: 20px;
      position: relative;
      z-index: 1;
      height: 50px;
      overflow: hidden;
      display: inline-block;
      width: 100%; }
      header .logo a img {
        margin-top: 0px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%; }
      header .logo a i {
        font-size: 40px;
        margin-top: 6px;
        display: inline-block; }
  header .main-menu {
    position: relative;
    z-index: 1; }
    header .main-menu ul li {
      float: left; }
      header .main-menu ul li a {
        float: left;
        padding: 15px 10px;
        color: #2D0A40; }
        header .main-menu ul li a:hover {
          text-decoration: none;
          color: #FCC50A; }

.login-menu {
  padding: 8px 0;
  position: relative;
  z-index: 1; }
  .login-menu.login-mobile {
    display: none; }
  .login-menu a:hover {
    color: #FCC50A; }
  .login-menu a.login-button:hover {
    color: #FF015B;
    border: 2px solid #FF015B;
    background: transparent !important;
    transform: scale(0.9); }

.req {
  position: absolute;
  top: 0;
  z-index: 1;
  background-color: #FF015B;
  color: #fff;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  font-size: 11px; }

@media only screen and (max-width: 991px) {
  .menu-mobile {
    display: block;
    background: #fff;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.28); }
  header .main-menu {
    display: none;
    position: absolute;
    width: 200px;
    right: 0;
    top: 65px;
    background: #FFF;
    box-shadow: -4px 5px 18px 0px rgba(0, 0, 0, 0.15); }
    header .main-menu.active {
      display: block; }
      header .main-menu.active li {
        width: 100%; } }

@media only screen and (max-width: 768px) {
  header {
    z-index: 3; }
    header .logo {
      width: 150px !important; }
      header .logo img {
        left: 0 !important;
        transform: translate(0%, -50%) !important; }
    header .header-bg {
      height: 227px !important; }
  .login-menu {
    margin-right: 50px; } }

@media only screen and (max-width: 420px) {
  header {
    padding: 13px 0;
    position: fixed;
    background: #FFF;
    box-shadow: 0px 0px 34px 0px rgba(0, 93, 255, 0.14); }
    header .header-bg {
      height: 227px !important;
      /* display: none; */
      background-position-y: -152px;
      background-position-x: -26px; }
    header .menu-mobile {
      top: 16px;
      border-radius: 12px; }
    header .main-menu {
      top: 83px; }
  /* .login-menu a {
		color: #FFF;
		border: 0 !important;
	} */
  .login-menu {
    position: absolute;
    top: 0px;
    width: 100%;
    left: 0px;
    padding: 0;
    display: none; }
    .login-menu.login-mobile {
      display: block;
      text-align: center;
      margin: 0; }
      .login-menu.login-mobile a {
        display: inline-block;
        float: none;
        margin-bottom: 15px; } }

.home header {
  position: relative;
  padding: 0;
  background: #fff;
  transition: all 0.5s ease; }
  .home header.fixed {
    position: fixed;
    top: -66px; }
  .home header .header-top {
    width: 100%;
    padding: 15px 0;
    background: #fff; }
  .home header .header-bottom {
    width: 100%;
    background: #fff; }
  .home header .logo {
    width: 154px; }
  .home header .search-box {
    min-width: 300px;
    padding: 8px 0;
    display: inline-block; }
    .home header .search-box .search-form {
      position: relative; }
      .home header .search-box .search-form input {
        border-radius: 100px !important;
        background: #e9ecef;
        border: 0;
        height: 35px; }
      .home header .search-box .search-form button {
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        width: 40px;
        text-align: center;
        box-shadow: 0 0 0 0;
        background: none;
        color: #2D0A40;
        border: 0; }
  .home header .login-menu {
    padding: 5px 0; }
    .home header .login-menu .login-box {
      text-align: center;
      display: flex;
      border: 2px solid #FF015B;
      padding: 7px 15px;
      margin-left: 10px;
      color: #FF015B;
      height: 40px;
      border-radius: 100px;
      float: left; }
      .home header .login-menu .login-box .profile-img {
        width: 30px;
        height: 30px;
        overflow: hidden;
        border-radius: 100%;
        position: relative;
        margin-top: -4px;
        margin-left: -7px; }
        .home header .login-menu .login-box .profile-img img {
          height: 100%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%); }
      .home header .login-menu .login-box .dropdown-menu {
        right: 0 !important;
        left: inherit !important; }
      .home header .login-menu .login-box:after {
        top: 10px;
        position: relative; }
      .home header .login-menu .login-box p {
        color: #FF015B;
        font-weight: 600;
        margin-left: 5px; }
      .home header .login-menu .login-box.total-donate {
        display: inline-block;
        border-color: #e9ecef;
        text-align: right;
        width: 128px; }
        .home header .login-menu .login-box.total-donate small {
          font-size: 10px;
          color: #2D0A40;
          margin-top: -5px;
          float: left;
          font-weight: 0;
          width: 100%; }
        .home header .login-menu .login-box.total-donate p {
          float: left;
          width: 100%;
          margin-top: -5px; }
  .home header .collapse-menu {
    width: 100%;
    border-top: 2px solid #e9ecef;
    display: flex;
    max-width: 1110px; }
    .home header .collapse-menu .category-menu {
      position: relative;
      width: 30px;
      height: 40px;
      display: none; }
      .home header .collapse-menu .category-menu #category-menu {
        height: 40px;
        display: inline-block; }
        .home header .collapse-menu .category-menu #category-menu span {
          position: absolute;
          width: 100%;
          height: 2px;
          background: #212529;
          top: 50%;
          border-radius: 100px;
          width: 100%;
          transition: 0.5s ease all; }
          .home header .collapse-menu .category-menu #category-menu span:before {
            content: '';
            position: absolute;
            width: 50%;
            height: 2px;
            background: #212529;
            top: -5px;
            transition: 0.5s ease all;
            left: 0; }
          .home header .collapse-menu .category-menu #category-menu span:after {
            content: '';
            position: absolute;
            width: 50%;
            height: 2px;
            background: #212529;
            top: 5px;
            transition: 0.5s ease all;
            right: 0; }
        .home header .collapse-menu .category-menu #category-menu.active span {
          transform: rotate(45deg); }
          .home header .collapse-menu .category-menu #category-menu.active span:before {
            transform: rotate(90deg);
            left: 8px;
            top: -7px; }
          .home header .collapse-menu .category-menu #category-menu.active span:after {
            transform: rotate(90deg);
            right: 7px;
            top: 7px; }
    .home header .collapse-menu ul {
      margin: 0;
      width: 100%;
      text-align: center; }
      .home header .collapse-menu ul li {
        position: relative;
        display: inline-block; }
        .home header .collapse-menu ul li a {
          font-size: 0.999rem;
          float: left;
          padding: 10px 8px;
          border-top: 2px solid transparent;
          color: #212529;
          margin-top: -2px;
          font-weight: 500; }
          .home header .collapse-menu ul li a.dropdown-toggle {
            padding: 0 !important;
            width: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%); }
            .home header .collapse-menu ul li a.dropdown-toggle:after {
              display: none; }
          .home header .collapse-menu ul li a:hover {
            border-top: 2px solid #FF015B;
            color: #FF015B; }
        .home header .collapse-menu ul li ul {
          position: absolute;
          width: 200px;
          background: #fff;
          box-shadow: 4px 7px 26px 0px rgba(0, 0, 0, 0.13);
          border-radius: 20px;
          top: 38px;
          left: 50%;
          transform: translateX(-50%);
          text-align: center;
          display: none; }
          .home header .collapse-menu ul li ul:after {
            content: '';
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid transparent;
            border-bottom: 10px solid #fff;
            position: absolute;
            top: -20px;
            left: 50%;
            transform: translateX(-50%); }
          .home header .collapse-menu ul li ul li a {
            border-top: 0 !important; }
            .home header .collapse-menu ul li ul li a:hover {
              border-top: 0 !important;
              border-bottom: 2px solid #FF015B; }
          .home header .collapse-menu ul li ul li:last-child a:hover {
            border-bottom: 0; }
        .home header .collapse-menu ul li:hover ul {
          display: block; }
          .home header .collapse-menu ul li:hover ul li {
            width: 100%; }
            .home header .collapse-menu ul li:hover ul li a {
              width: 100%; }

.dropdown-menu {
  box-shadow: 4px 7px 26px 0px rgba(0, 0, 0, 0.13);
  border: 0;
  padding: 0;
  background-color: #fff; }
  .dropdown-menu:after {
    content: '';
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid transparent;
    border-bottom: 10px solid #fff;
    position: absolute;
    top: -20px;
    left: 30px;
    z-index: 2; }
  .dropdown-menu li {
    border-bottom: 1px solid #e9ecef;
    padding: 15px 0; }
    .dropdown-menu li a {
      padding: 0px 10px; }
      .dropdown-menu li a i {
        width: 30px;
        display: inline-block;
        text-align: center;
        background: linear-gradient(#FDC50B, #FFA700);
        height: 30px;
        border-radius: 100%;
        padding: 6px;
        color: #fff;
        margin-right: 10px; }
    .dropdown-menu li:last-child {
      border: 0; }

#overlay {
  display: none; }
  #overlay.active {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.35);
    width: 100%;
    height: 100%;
    display: block; }

#collapsed-items {
  position: absolute;
  left: -17px;
  width: 300px;
  top: 40px;
  background: #fff;
  z-index: 5;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  display: none;
  opacity: 0;
  transition: 0.5s ease all; }
  #collapsed-items.hidden {
    display: block;
    opacity: 1;
    visibility: inherit;
    box-shadow: 4px 7px 26px 0px rgba(0, 0, 0, 0.13); }
  #collapsed-items li {
    width: 100%;
    display: inline-block; }
    #collapsed-items li a {
      font-size: 18px;
      padding: 16px;
      border-bottom: 1px solid #e9ecef;
      width: 100%;
      display: inline-block; }

@media only screen and (max-width: 1199px) {
  .collapse-menu .category-menu {
    display: block !important; }
  .collapse-menu ul {
    display: none; }
  .collapse-menu.active ul {
    display: block !important;
    position: absolute;
    top: 123px;
    background: #FFF;
    left: 0;
    width: 100%; } }

@media only screen and (max-width: 991px) {
  .home header .header-top .login-menu {
    margin-right: 0; }
    .home header .header-top .login-menu .dropdown-menu {
      z-index: 99999999; }
  .home header .header-top .login-box {
    width: 50px;
    display: inline-block; }
    .home header .header-top .login-box .profile-img {
      margin-top: -4px; }
    .home header .header-top .login-box p {
      display: none; }
    .home header .header-top .login-box.total-donate p {
      display: block; }
  .home header .search-box {
    min-width: 200px; } }

@media only screen and (max-width: 768px) {
  .home header .container {
    position: relative; }
  .home header .search-box {
    position: absolute;
    top: 52px;
    right: 15px;
    z-index: 9999999; }
  .home header .logo {
    width: 164px !important; }
  #collapsed-items li a {
    font-size: 1rem; }
  .collapse-menu #auto-nav {
    margin: 0;
    width: 100%;
    left: 0;
    top: 42px; }
    .collapse-menu #auto-nav li {
      position: relative;
      width: 100%;
      display: inline-block;
      border-bottom: 1px solid #e9ecef; } }

@media only screen and (max-width: 767px) {
  .home header .container {
    position: relative; }
  .home header .header-bottom {
    float: left; }
  .home header .login-menu {
    position: static;
    width: auto; }
    .home header .login-menu .total-donate {
      display: none !important; } }

footer {
  background-color: #fff;
  position: relative;
  margin-top: 0px; }
  footer .footer-bg {
    height: 386px;
    width: 100%;
    background-size: 32%;
    background-repeat: no-repeat;
    background-position: bottom right;
    position: absolute;
    top: -366px; }
  footer .footer-box {
    padding: 40px 0;
    color: black; }
    footer .footer-box .title {
      margin-bottom: 23px;
      color: #FF015B; }
    footer .footer-box .footer-logo {
      margin-bottom: 15px; }
      footer .footer-box .footer-logo a img {
        width: 100%; }
      footer .footer-box .footer-logo a i {
        font-size: 60px;
        color: #2D0A40; }
    footer .footer-box p {
      color: black; }
    footer .footer-box span {
      font-size: 13px; }
    footer .footer-box a {
      color: #fff; }
    footer .footer-box ul li {
      padding-bottom: 10px; }
      footer .footer-box ul li a {
        color: #000;
        font-size: 14px; }
    footer .footer-box ul.social-media {
      margin-top: 15px; }
      footer .footer-box ul.social-media li {
        display: inline-block; }
        footer .footer-box ul.social-media li a {
          color: #FFF;
          font-size: 22px;
          padding-right: 30px; }
          footer .footer-box ul.social-media li a:hover {
            color: #2D0A40; }

@media only screen and (max-width: 991px) {
  footer .footer-box {
    padding: 15px 0; }
    footer .footer-box ul {
      margin: 0; }
    footer .footer-box .footer-logo {
      width: 200px; }
      footer .footer-box .footer-logo i {
        font-size: 30px !important; } }

@media only screen and (max-width: 540px) {
  footer {
    margin-top: 109px; }
    footer .footer-bg {
      background-size: 100%; } }

.hidden {
  display: none; }

.section {
  padding: 100px 0;
  width: 100%;
  position: relative; }
  .section .container {
    position: relative;
    z-index: 2; }
  .section .section-header {
    text-align: center;
    position: relative; }
    .section .section-header .title {
      font-weight: 700; }
    .section .section-header:before {
      content: '';
      width: 50px;
      height: 5px;
      border-radius: 100px;
      background: #FF015B;
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translateX(-50%); }
  .section.main-section {
    text-align: center;
    /* min-height: 100vh; */
    padding-top: 196px;
    overflow: hidden; }
    .section.main-section h1 {
      text-align: center;
      font-weight: 700; }
      .section.main-section h1 .mobile-text {
        display: inline-block;
        margin-left: -8px;
        margin-right: 8px; }
      .section.main-section h1 span {
        color: #FF015B;
        border-bottom: 4px solid #2D0A40; }
        .section.main-section h1 span.typed-cursor {
          color: #676767;
          font-weight: 300; }
          .section.main-section h1 span.typed-cursor.typed-cursor--blink {
            animation: blink 0.5s infinite !important; }

@keyframes blink {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }
    .section.main-section p {
      font-size: 19px;
      margin-top: 36px; }
      .section.main-section p.free-text {
        font-size: 20px;
        background-image: url("/assets/site/img/scribble.png") !important; }
    .section.main-section .btn-custom {
      margin-top: 16px; }
    .section.main-section .total-donation {
      display: inline-block;
      margin-top: 40px;
      height: 43px;
      border: 2px solid #EBEBEB;
      padding: 0 74px;
      border-radius: 15px; }
      .section.main-section .total-donation p {
        margin-top: -18px;
        background-color: #fff;
        padding: 0 19px;
        margin-bottom: 0; }
      .section.main-section .total-donation h1 {
        color: #FF015B;
        margin-top: 1px;
        display: inline-block;
        background: #FFF;
        padding: 0 20px; }
    .section.main-section .companies-container {
      width: 100%;
      margin-top: 268px; }
      .section.main-section .companies-container a {
        width: 100%;
        /* filter: grayscale(100%); */
        -webkit-transition: .3s ease-in-out all;
        transition: .3s ease-in-out all;
        background: #fff;
        height: 69px;
        margin-right: 19px;
        border-radius: 15px;
        box-shadow: 0px 0px 34px 0px rgba(0, 93, 255, 0.14);
        position: relative; }
        .section.main-section .companies-container a img {
          /* opacity: 0.2; */
          max-height: 50%;
          transition: .3s ease-in-out all;
          max-width: 50%;
          top: 50%;
          position: absolute;
          transform: translate(-50%, -50%);
          left: 50%; }
        .section.main-section .companies-container a:hover {
          filter: grayscale(0%); }
          .section.main-section .companies-container a:hover img {
            opacity: 1; }
    .section.main-section .button-content {
      position: relative;
      margin-top: 52px;
      margin-bottom: 46px; }
    .section.main-section .free-text {
      left: -8px;
      position: absolute;
      background-repeat: no-repeat;
      background-position: bottom center;
      background-color: transparent !important;
      width: 100px;
      height: 139px;
      color: #fdc508;
      top: 0;
      font-weight: 600; }
  .section.iyilik-butonu-main .companies-container {
    margin-top: 5rem !important; }
  .section.aboutus {
    padding: 0; }
  .section.support {
    padding: 100px 0 0; }
  .section.count-section {
    padding: 55px 0;
    background-position: center; }
    .section.count-section .category-bg .path_1 {
      width: 200px;
      height: 200px; }
    .section.count-section .category-bg .path_2 {
      width: 200px;
      height: 200px; }

.twitter-typeahead {
  width: 100%; }

.highlight {
  position: relative;
  z-index: 1;
  border-bottom: 1px solid #2d0840;
  color: #ff005b;
  display: inline-block; }
  .highlight span {
    position: relative;
    z-index: 1; }

.btn {
  border-radius: 100px; }
  .btn-custom {
    color: #FF015B;
    border: 2px solid #FF015B;
    font-size: 24px;
    padding: 15px 20px;
    font-weight: 600; }
  .btn-danger {
    background: #FF015B;
    border-color: #FF015B;
    padding: 9px 32px;
    font-weight: 600;
    color: #fff;
    box-shadow: 4px 7px 26px 0px rgba(0, 0, 0, 0.24); }
    .btn-danger:hover {
      background: #e70052;
      border-color: #e70052;
      box-shadow: 0px 0px 0px 0px; }
    .btn-danger:disabled:hover {
      background: #dc3545;
      border-color: #dc3545;
      box-shadow: 4px 7px 26px 0px rgba(0, 0, 0, 0.24); }
  .btn-gradient-yellow {
    background: linear-gradient(#FDC50B, #FFA700);
    color: #FFF; }

.fade.in {
  opacity: 1; }

.section-container {
  text-align: center;
  padding: 15px 0;
  width: 100%;
  z-index: 1; }
  .section-container .tab-container {
    max-width: 800px;
    width: 100%;
    display: inline-block; }
    .section-container .tab-container .nav li {
      width: 25%; }
      .section-container .tab-container .nav li a {
        border-bottom: 1px solid #D8D8D8;
        float: left;
        width: 100%; }
        .section-container .tab-container .nav li a i {
          font-size: 50px; }
        .section-container .tab-container .nav li a p {
          font-weight: 600;
          margin-top: 15px;
          font-size: 14px;
          opacity: 0.5; }
        .section-container .tab-container .nav li a:hover, .section-container .tab-container .nav li a.active {
          border-bottom: 4px solid #2D0A40;
          color: #2D0A40;
          border-radius: 3px; }
          .section-container .tab-container .nav li a:hover p, .section-container .tab-container .nav li a.active p {
            opacity: 1; }
    .section-container .tab-container .tab-content {
      padding: 5px 0;
      text-align: left; }
      .section-container .tab-container .tab-content ul {
        margin-top: 15px; }
        .section-container .tab-container .tab-content ul li {
          position: relative;
          margin-bottom: 15px;
          font-size: 14px; }
          .section-container .tab-container .tab-content ul li span {
            width: 17px;
            height: 17px;
            background-color: #b1f5f8;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 100%;
            left: -26px;
            font-size: 11px;
            text-align: center; }
      .section-container .tab-container .tab-content .tab-img {
        text-align: center;
        height: 362px;
        position: relative; }
        .section-container .tab-container .tab-content .tab-img img {
          max-width: 54%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 7px; }
  .section-container .tab-supports input[type=radio] + span {
    border: 1px solid #2D0A40;
    color: #2D0A40;
    padding: 7px 13px;
    border-radius: 10px;
    margin-right: 5px; }
    .section-container .tab-supports input[type=radio] + span:before {
      width: 0;
      height: 100%;
      left: 0; }
  .section-container .tab-supports input[type=radio]:checked + span {
    color: #FFF;
    border: 1px solid #FF015B;
    background: #FF015B; }
    .section-container .tab-supports input[type=radio]:checked + span:after {
      display: none;
      background: #FF015B;
      width: 100%;
      left: 0;
      height: 100%; }
  .section-container .tab-supports a {
    margin-bottom: 5px; }
  .section-container .tab-supports-companies {
    padding: 30px 0; }
    .section-container .tab-supports-companies .companies-box {
      height: 96px;
      background: #eaeaea;
      margin-bottom: 30px;
      border-radius: 10px;
      position: relative;
      transition: .3s ease-in-out all; }
      .section-container .tab-supports-companies .companies-box img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        filter: grayscale(100%);
        -webkit-transition: .3s ease-in-out all;
        transition: .3s ease-in-out all;
        max-height: 52px;
        max-width: 72px; }
    .section-container .tab-supports-companies .supports-logo {
      transition: all 0.5s ease;
      transition: all 0.5s ease;
      width: 100%;
      flex: none;
      max-width: 12%; }
      .section-container .tab-supports-companies .supports-logo.active {
        transform: scale(1.15); }
        .section-container .tab-supports-companies .supports-logo.active .companies-box {
          transition: .3s ease-in-out all;
          background: #FFF;
          box-shadow: 3px 4px 6px 0px rgba(0, 0, 0, 0.14); }
          .section-container .tab-supports-companies .supports-logo.active .companies-box img {
            filter: grayscale(0%);
            -webkit-transition: .3s ease-in-out all;
            transition: .3s ease-in-out all; }
  .section-container.counter-container {
    background-color: #fff;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
    padding: 50px 0;
    border-radius: 15px;
    position: relative;
    z-index: 2; }
  .section-container .counter-box {
    text-align: center; }
    .section-container .counter-box h1 {
      font-weight: 700;
      color: #FF015B; }

#aboutus {
  text-align: center; }

blockquote.blockquote {
  position: relative;
  max-width: 750px;
  display: inline-block;
  margin-top: 91px; }
  blockquote.blockquote .quotes {
    position: absolute;
    font-size: 62px;
    color: #CCC;
    z-index: 0; }
    blockquote.blockquote .quotes#left {
      top: -79px;
      left: 0; }
    blockquote.blockquote .quotes#right {
      bottom: -77px;
      right: -56px; }
  blockquote.blockquote p {
    padding: 0 100px;
    text-align: left;
    color: #495057; }
  blockquote.blockquote .quote-name {
    text-align: right;
    color: #495057;
    margin-right: 30px; }
    blockquote.blockquote .quote-name h1 {
      margin-bottom: 0; }
    blockquote.blockquote .quote-name small {
      color: #2D0A40; }

.tab-btn a i {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: #D8D8D8;
  padding: 20px 0;
  font-size: 26px !important;
  display: inline-block;
  text-align: center; }
  .tab-btn a i.icon-iyilikkazansin {
    text-align: left;
    padding-left: 17px; }

.tab-btn-blue a i {
  background: #b2f5f9;
  color: #0eadb8; }

.tab-btn-green a i {
  background: #bdfbdd;
  color: #0dc56e; }

.tab-btn-pink a i {
  background: #fddee9;
  color: #f54d8a; }

.tab-btn-yellow a i {
  background: #fff4d2;
  color: #EAB401; }

.button-custom {
  margin-top: 30px; }
  .button-custom .btn {
    font-size: 18px;
    padding: 15px 50px; }
  .button-custom .free-text {
    margin-top: 15px; }
    .button-custom .free-text a {
      font-weight: 600; }

.team-box {
  margin-bottom: 15px; }
  .team-box .team-image {
    position: relative;
    height: 373px;
    overflow: hidden;
    border-radius: 15px; }
    .team-box .team-image img {
      transition: all .5s ease;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .team-box .team-image figure {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(247, 189, 0, 0.815);
      visibility: hidden;
      opacity: 0;
      transition: 0.5s ease all; }
      .team-box .team-image figure figcaption {
        position: absolute;
        bottom: 15px;
        width: 100%;
        color: #fff; }
        .team-box .team-image figure figcaption p {
          color: #fff; }
        .team-box .team-image figure figcaption a {
          color: #fff;
          font-size: 24px; }
  .team-box:hover .team-image figure {
    visibility: visible;
    opacity: 1; }
  .team-box p {
    font-weight: 700;
    color: #000;
    font-size: 20px; }

@media only screen and (max-width: 991px) {
  #aboutus {
    padding-top: 103px; }
  section.main-section h1 .word {
    width: 100% !important;
    display: inline-block; }
  section.main-section h1 .typed-cursor {
    display: none; }
  section.main-section .total-donation {
    text-align: center; }
    section.main-section .total-donation p {
      display: inline-block;
      float: none !important; }
  section.main-section .companies-container a {
    width: 49% !important;
    display: inline-block;
    margin-bottom: 15px;
    margin-right: 10px !important; }
    section.main-section .companies-container a img {
      width: 100px; }
  section.main-section div {
    position: relative;
    z-index: 4; }
  section .tab-container .nav li a {
    padding: 15px; }
  section .tab-container .tab-content h2 {
    margin-top: 15px; }
  section .tab-container .tab-content ul li:before {
    display: none !important; }
  .section-container .tab-supports-companies .supports-logo {
    max-width: 20%; }
  .supporters-box-company {
    width: 100px !important; }
  .supporters-box-img {
    height: 100px !important; } }

@media only screen and (max-width: 767px) {
  #perc-percent {
    display: none; }
  section .section-header h2 {
    font-size: 24px;
    padding: 0 !important; }
  section.section {
    /* overflow: hidden; */
    /* .section-header{
				&:before{
					top:0;
				}
			} */ }
  section.main-section {
    padding-top: 157px !important;
    padding-bottom: 40px; }
    section.main-section h1 .word {
      width: 100% !important;
      display: inline-block; }
    section.main-section h1 .mobile-text {
      display: none !important; }
    section.main-section h1 .typed-cursor {
      display: none; }
    section.main-section p {
      font-size: 1.1rem !important; }
    section.main-section .total-donation {
      text-align: center; }
      section.main-section .total-donation p {
        display: inline-block;
        float: none !important; }
      section.main-section .total-donation .free-text {
        background-image: none !important;
        background-color: transparent !important;
        width: 100%;
        margin-top: 2px; }
        section.main-section .total-donation .free-text a {
          font-weight: 700; }
    section.main-section .companies-container {
      margin-top: 166px !important; }
      section.main-section .companies-container a {
        width: 47% !important;
        display: inline-block;
        margin-bottom: 15px;
        margin-right: 2% !important; }
        section.main-section .companies-container a:nth-child(7), section.main-section .companies-container a:nth-child(6), section.main-section .companies-container a:nth-child(5) {
          display: none; }
        section.main-section .companies-container a img {
          width: 100px; }
    section.main-section .btn-custom {
      margin-top: 0 !important; }
    section.main-section .total-donation {
      height: 60px !important;
      padding: 0 !important; }
    section.main-section .button-content {
      margin-top: 34px !important;
      margin-bottom: 15px !important; }
      section.main-section .button-content .free-text {
        display: none; }
  section.support {
    padding: 56px 0 0 !important; }
  section.count-section {
    padding: 0 !important;
    background-position: -265px;
    background-color: #fff6d1; }
  section#supporters {
    padding-bottom: 0 !important; }
    section#supporters .supporters-container:nth-child(12), section#supporters .supporters-container:nth-child(11), section#supporters .supporters-container:nth-child(10), section#supporters .supporters-container:nth-child(9), section#supporters .supporters-container:nth-child(8), section#supporters .supporters-container:nth-child(7), section#supporters .supporters-container:nth-child(6), section#supporters .supporters-container:nth-child(5) {
      display: none !important; }
  section .tab-container .nav li a {
    padding: 15px; }
    section .tab-container .nav li a i.icon-iyilikkazansin {
      padding-left: 13px !important; }
    section .tab-container .nav li a p {
      display: none !important; }
  section .tab-container .tab-content h2 {
    margin-top: 15px; }
  section .tab-container .tab-content ul li:before {
    display: none !important; }
  .section-container .tab-container .nav li a i {
    width: 50px !important;
    height: 50px !important;
    font-size: 19px !important;
    padding: 15px 0 !important; }
  .section-container .tab-supports-companies .supports-logo {
    max-width: 48%;
    display: none; }
    .section-container .tab-supports-companies .supports-logo.active {
      display: block; }
  .section-container {
    padding: 15px !important; }
  .btn-custom {
    font-size: 17px;
    padding: 9px 11px; }
  .btn-danger {
    background: #FF015B !important;
    border-color: #FF015B !important; }
  blockquote.blockquote .quotes#left {
    top: -125px; }
  blockquote.blockquote .quotes#right {
    right: 15px; }
  blockquote.blockquote p {
    padding: 0; } }

.fixed {
  position: fixed;
  width: 100%; }

.home section.section {
  padding: 0;
  z-index: 0; }
  .home section.section .section-header {
    padding: 30px 0 0; }
    .home section.section .section-header:before {
      display: none; }
    .home section.section .section-header h2 {
      font-weight: 600; }
  .home section.section.category .section-header {
    position: relative;
    width: 100%;
    height: 75px;
    z-index: 0; }
    .home section.section.category .section-header h2 {
      background: #fff;
      padding-right: 30px;
      position: relative;
      z-index: 1; }
    .home section.section.category .section-header hr {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%; }
    .home section.section.category .section-header .btn {
      padding-left: 30px;
      background: #fff;
      position: relative;
      z-index: 1; }
      .home section.section.category .section-header .btn:hover {
        color: #FF015B; }
  .home section.section .section-box {
    background: #f8f9fa;
    border-radius: 20px;
    width: calc(100% + 30px);
    padding: 15px;
    margin-left: -15px;
    height: 390px;
    margin-bottom: 100px; }
  .home section.section .section-container {
    position: relative; }
  .home section.section #main-slider {
    width: 100%;
    margin-top: 15px; }
    .home section.section #main-slider .swiper-slide {
      border-radius: 20px;
      overflow: hidden; }
      .home section.section #main-slider .swiper-slide img {
        width: 100%; }
    .home section.section #main-slider #main-pagination {
      position: absolute;
      top: 50%;
      right: 15px;
      display: inline-block;
      width: 15px;
      z-index: 2;
      transform: translateY(-50%); }
      .home section.section #main-slider #main-pagination .slide-pagination {
        width: 10px;
        height: 10px;
        display: inline-block;
        background-color: #e9ecef;
        border-radius: 100%; }
        .home section.section #main-slider #main-pagination .slide-pagination.active {
          background-color: #FF015B; }
  .home section.section #best-slider {
    position: relative;
    width: calc(100% + 30px);
    margin-left: -15px; }
    .home section.section #best-slider.swiper-container {
      /* overflow: initial; */
      /* .swiper-slide-active, .swiper-slide-next {
						opacity:1 !important;	
					}
					.swiper-slide {
						opacity: 0;
					} */ }
    .home section.section #best-slider .swiper-slide {
      padding: 14px 15px; }
      .home section.section #best-slider .swiper-slide .slider-box {
        background: #fff;
        box-shadow: 4px 7px 14px 0px rgba(0, 0, 0, 0.05);
        border-radius: 30px;
        overflow: hidden;
        text-align: left; }
        .home section.section #best-slider .swiper-slide .slider-box .tab-img {
          height: 275px;
          position: relative;
          overflow: hidden; }
          .home section.section #best-slider .swiper-slide .slider-box .tab-img img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%); }
        .home section.section #best-slider .swiper-slide .slider-box .slide-img {
          width: 100px;
          margin-bottom: 15px;
          height: 64px;
          position: relative;
          overflow: hidden; }
          .home section.section #best-slider .swiper-slide .slider-box .slide-img img {
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%); }
        .home section.section #best-slider .swiper-slide .slider-box .slide-description h3 {
          color: #FCC50A;
          margin-bottom: 15px; }
        .home section.section #best-slider .swiper-slide .slider-box .slide-description p {
          font-size: 14px;
          height: 64px; }
  .home section.section .swiper-button-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    border: 1px solid #ced4da;
    color: #ced4da;
    left: -60px;
    padding: 4px;
    border-radius: 100%;
    font-size: 28px;
    z-index: 2; }
    .home section.section .swiper-button-prev:hover {
      color: #FF015B;
      border-color: #FF015B; }
  .home section.section .swiper-button-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    border: 1px solid #ced4da;
    color: #ced4da;
    right: -60px;
    padding: 4px;
    border-radius: 100%;
    font-size: 28px;
    z-index: 2; }
    .home section.section .swiper-button-next:hover {
      color: #FF015B;
      border-color: #FF015B; }

.btn-outline-red {
  color: #FF015B;
  border-color: #FF015B;
  border-width: 2px; }
  .btn-outline-red:hover {
    background: #FF015B;
    color: #fff;
    box-shadow: 4px 7px 26px 0px rgba(0, 0, 0, 0.24); }

.card {
  background: #fff;
  box-shadow: 4px 7px 26px 0px rgba(0, 0, 0, 0.13);
  border: 0;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 15px; }
  .card .card-header {
    position: relative;
    background: #fff;
    border: 0; }
    .card .card-header .card-img {
      height: 105px;
      position: relative; }
      .card .card-header .card-img img {
        position: absolute;
        max-height: 126%;
        max-width: 78%;
        top: 63%;
        left: 50%;
        transform: translate(-50%, -50%); }
    .card .card-header .badge {
      position: absolute;
      top: 15px;
      right: 0;
      background: #FF015B;
      color: #fff;
      padding: 8px 20px;
      border-radius: 0; }
      .card .card-header .badge:before {
        content: '';
        width: 0px;
        height: 0px;
        border-left: 16px solid #fff;
        border-right: 8px solid #FF015B;
        border-top: 14px solid #FF015B;
        border-bottom: 14px solid #FF015B;
        position: absolute;
        left: -22px;
        top: 50%;
        transform: translateY(-50%); }
  .card .card-body h4 {
    color: #FCC50A; }
  .card .card-body p {
    font-size: 14px;
    height: 43px; }
  .card .card-footer {
    padding: 0;
    border: 0; }
    .card .card-footer .btn {
      border-radius: 0;
      padding: 15px 0;
      border: 0; }
      .card .card-footer .btn:hover {
        background: #FF015B;
        color: #fff; }

.category-slider {
  width: 100%; }

#best-pagination {
  width: 100%;
  text-align: center; }
  #best-pagination .slide-pagination {
    width: 10px;
    height: 10px;
    background: #e9ecef;
    display: inline-block;
    border-radius: 100%;
    margin: 0 2px;
    cursor: pointer; }
    #best-pagination .slide-pagination.active {
      background: #FF015B; }

.popular-slider {
  padding: 0 15px;
  width: 100%; }

.popular-button-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  border: 1px solid #ced4da;
  color: #ced4da;
  left: -60px;
  padding: 4px;
  border-radius: 100%;
  font-size: 28px;
  z-index: 2; }
  .popular-button-prev:hover {
    color: #FF015B;
    border-color: #FF015B; }

.popular-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  border: 1px solid #ced4da;
  color: #ced4da;
  right: -60px;
  padding: 4px;
  border-radius: 100%;
  font-size: 28px;
  z-index: 2; }
  .popular-button-next:hover {
    color: #FF015B;
    border-color: #FF015B; }

@media only screen and (max-width: 992px) {
  section .card {
    padding: 0 !important; }
  .section-box {
    height: auto !important;
    margin-bottom: 15px !important; } }

@media only screen and (max-width: 768px) {
  .home section.section #main-slider #main-pagination .slide-pagination {
    display: block;
    margin: 3px 0; }
  .home section.section #best-slider.swiper-container {
    /* overflow: initial; */
    /* .swiper-slide-active, .swiper-slide-next {
							opacity:1 !important;	
						}
						.swiper-slide {
							opacity: 0;
						} */ }
  .slider-box {
    text-align: center; }
    .slider-box .tab-img {
      position: relative;
      height: 159px !important;
      overflow: hidden; }
      .slider-box .tab-img img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 100% !important;
        width: auto !important; }
    .slider-box .slide-img {
      display: inline-block;
      width: 100% !important;
      text-align: center;
      margin-top: 15px;
      height: 40px !important;
      margin-bottom: 0 !important; }
      .slider-box .slide-img img {
        max-width: 30%; }
    .slider-box .slide-description {
      text-align: center; }
    .slider-box .btn {
      display: inline-block;
      width: 100%;
      margin-bottom: 15px; }
  .home .swiper-button-prev {
    display: none; }
  .home .swiper-button-next {
    display: none; }
  .home .card .card-header .badge {
    display: none; }
  .swiper-pagination {
    width: 100%;
    text-align: center; }
    .swiper-pagination .slide-pagination {
      width: 10px;
      height: 10px;
      background: #e9ecef;
      display: inline-block;
      border-radius: 100%; }
      .swiper-pagination .slide-pagination.active {
        background: #FF015B; }
  #main-pagination, #best-pagination, .category-pagination {
    width: 100%;
    text-align: center; }
    #main-pagination .slide-pagination, #best-pagination .slide-pagination, .category-pagination .slide-pagination {
      width: 10px;
      height: 10px;
      background: #e9ecef;
      display: inline-block;
      border-radius: 100%;
      margin: 0 2px;
      cursor: pointer; }
      #main-pagination .slide-pagination.active, #best-pagination .slide-pagination.active, .category-pagination .slide-pagination.active {
        background: #FF015B; }
  .category-slider .swiper-slide {
    flex: none;
    max-width: inherit; } }

.subpage-header {
  float: left;
  width: 100%;
  margin-top: 15px;
  text-align: center; }
  .subpage-header .subpage-head-box {
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px;
    color: #fff; }
    .subpage-header .subpage-head-box h1 {
      font-weight: 700;
      text-shadow: 0px 0px 30px #000; }
    .subpage-header .subpage-head-box p {
      font-weight: 700;
      text-shadow: 0px 0px 30px #000;
      color: #fff; }

.subpage-container {
  margin-top: 30px;
  display: inline-block; }
  .subpage-container .section-order:nth-child(3n+1) .slider-box .tab-img {
    order: 2;
    padding: 0; }
  .subpage-container .section-order:nth-child(5) .slider-box .tab-img {
    order: 2;
    padding: 0; }
  .subpage-container .slider-box {
    background: #fff;
    box-shadow: 4px 7px 14px 0px rgba(0, 0, 0, 0.05);
    border-radius: 30px;
    overflow: hidden;
    text-align: left;
    margin-bottom: 15px; }
    .subpage-container .slider-box .tab-img {
      overflow: hidden;
      height: 300px;
      position: relative; }
      .subpage-container .slider-box .tab-img img {
        max-width: auto;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
    .subpage-container .slider-box .slide-img {
      width: 127px;
      margin-bottom: 15px;
      height: 100px; }
      .subpage-container .slider-box .slide-img img {
        max-width: 100%;
        max-height: 100%; }
    .subpage-container .slider-box .slide-description h3 {
      color: #FCC50A;
      margin-bottom: 15px; }
    .subpage-container .slider-box .slide-description p {
      font-size: 14px;
      height: 54px; }

.category-bg {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0; }
  .category-bg .path_1 {
    width: 300px;
    height: 300px;
    position: absolute;
    left: -95px;
    background-size: 100%;
    top: 56px; }
  .category-bg .path_2 {
    width: 300px;
    height: 300px;
    position: absolute;
    right: -95px;
    background-size: 100%;
    bottom: 56px; }

.stores {
  margin-top: 279px; }

@media only screen and (max-width: 768px) {
  .subpage-container .card {
    padding: 0 !important; } }

.subpage-header-menu {
  width: 100%;
  background: #b2f5f9;
  border-radius: 20px;
  margin-top: 30px; }
  .subpage-header-menu ul {
    margin: 0; }
    .subpage-header-menu ul li {
      display: inline-block;
      border: 0 !important; }
      .subpage-header-menu ul li a {
        padding: 15px;
        display: inline-block;
        color: #0eadb8 !important; }
        .subpage-header-menu ul li a.active, .subpage-header-menu ul li a:hover {
          border: 0 !important;
          background: transparent !important;
          border-bottom: 2px solid #0eadb8 !important;
          font-weight: 600; }

.subpage-left, .subpage-right {
  margin-top: 30px;
  margin-bottom: 100px; }

.profile-card {
  background: #fff;
  box-shadow: 4px 7px 26px 0px rgba(0, 0, 0, 0.13);
  border-radius: 20px;
  text-align: center;
  margin-top: 30px;
  padding: 15px;
  margin-bottom: 15px; }
  .profile-card .profile-img {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 100%;
    display: inline-block;
    margin-top: -80px;
    position: relative; }
    .profile-card .profile-img img {
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .profile-card .profile-img .img-add-button {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      left: 0;
      top: 0; }
      .profile-card .profile-img .img-add-button input[type=file] + span {
        background: none;
        color: #FFF;
        font-size: 35px;
        padding: 42px 0; }
  .profile-card .profile-body h4 {
    margin-bottom: 15px; }
  .profile-card .profile-body .total-donate {
    margin-top: 15px;
    margin-bottom: 15px; }
    .profile-card .profile-body .total-donate h3 {
      color: #FF015B; }

.subpage-card .card-header {
  border: 0ch;
  background: transparent;
  display: inline-block;
  width: 100%; }
  .subpage-card .card-header h4 {
    font-weight: 700;
    margin-bottom: 0; }
  .subpage-card .card-header a {
    padding: 0; }

.subpage-card .card-body {
  background: #fff;
  box-shadow: 4px 7px 26px 0px rgba(0, 0, 0, 0.13);
  border-radius: 20px; }
  .subpage-card .card-body .donation-companies {
    height: 100px;
    text-align: center;
    position: relative; }
    .subpage-card .card-body .donation-companies img {
      max-width: 70%;
      max-height: 70%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }

.social-media-content {
  background: #f8f9fa;
  border-radius: 20px;
  padding: 15px;
  margin-bottom: 15px; }
  .social-media-content p {
    margin-bottom: 0; }
  .social-media-content h3 {
    color: #FF015B;
    font-weight: 700; }
  .social-media-content ul {
    margin: 0; }
    .social-media-content ul li {
      display: inline-block; }
      .social-media-content ul li a {
        font-size: 30px;
        margin-left: 15px; }
        .social-media-content ul li a:hover {
          color: #FF015B; }

.subpage-list-img {
  height: 75px;
  background: #fff;
  box-shadow: 4px 7px 26px 0px rgba(0, 0, 0, 0.13);
  position: relative;
  border-radius: 20px;
  overflow: hidden; }
  .subpage-list-img img {
    position: absolute;
    max-width: 70%;
    max-height: 70%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.subpage-list-body {
  height: 265px;
  overflow: auto; }
  .subpage-list-body ul li {
    margin-bottom: 15px; }

.subpage-list-desc p {
  font-weight: 700;
  margin-bottom: 0; }

.subpage-list-desc small {
  display: inline-block;
  width: 100%;
  color: #FF015B; }

.subpage-list .donate {
  background: #ffeeb8;
  width: 75px;
  height: 75px;
  border-radius: 100%;
  text-align: center;
  padding: 11px 0; }
  .subpage-list .donate p {
    margin: 0;
    font-size: 21px;
    font-weight: 700;
    color: #FF015B; }
  .subpage-list .donate small {
    color: #FF015B; }

.subpage-list .list-content-img {
  width: 100px;
  display: inline-block; }

.subpage-list .list-content-price {
  width: 75px; }

.subpage-list .list-content-desc {
  width: calc(100% - 175px);
  padding-left: 15px !important;
  display: inline-block; }

.shopping-form-wrap {
  background: #ffe89f;
  border-radius: 20px;
  padding: 30px;
  position: relative;
  margin-top: 30px; }
  .shopping-form-wrap .shopping-bg {
    height: 100%;
    position: absolute;
    width: 100%;
    background-size: 96%;
    background-position: bottom;
    background-repeat: no-repeat;
    right: -15px;
    bottom: 0; }
  .shopping-form-wrap input {
    border-radius: 100px !important;
    border: 0 !important; }

@media only screen and (max-width: 991px) {
  .social-media-content ul li a {
    font-size: 16px; }
  .profile-card .profile-img {
    width: 75px;
    height: 75px; }
    .profile-card .profile-img input[type=file] + span {
      color: #fff !important;
      font-size: 21px !important;
      padding: 22px 0 !important; }
    .profile-card .profile-img img {
      height: 100%; }
  .shopping-form-wrap {
    padding: 10px; }
    .shopping-form-wrap p {
      font-size: 14px; }
  .subpage-list-body {
    background: #f8f9fa;
    border-radius: 20px;
    padding: 15px 0; }
  .subpage-list .list-content-img {
    display: none; }
  .subpage-list .list-content-desc {
    width: calc(100% - 75px); }
  .subpage-list .donate {
    width: 60px;
    height: 60px; }
    .subpage-list .donate p {
      font-size: 16px; }
  .subpage-list.shopping-list .list-content-img {
    display: inline-block; }
  .profile-card .profile-body h4 {
    font-size: 14px; }
  .profile-card .profile-body .total-donate h3 {
    font-size: 16px; }
  .profile-card .profile-body .btn {
    font-size: 11px; }
  .subpage-card .card-header h4 {
    font-size: 14px; }
  .subpage-card .card-header a {
    font-size: 14px; } }

@media only screen and (max-width: 767px) {
  .subpage-header-menu {
    margin-top: 0;
    border-radius: 0; }
  .profile-card {
    margin-top: 85px; }
  .subpage-list {
    margin-bottom: 20px; }
    .subpage-list-body {
      max-height: 265px;
      height: auto; }
  .social-media-content p {
    font-size: 15px; }
  .social-media-content h3 {
    font-size: 21px; } }

.register {
  overflow: hidden;
  background-position: top center; }

.register-wizard.login .card {
  padding: 50px 90px; }

.register-wizard {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.74);
  overflow: hidden; }
  .register-wizard .card {
    padding: 50px 100px;
    text-align: center;
    box-shadow: 0 0 0 0; }
    .register-wizard .card .card-header {
      background: transparent;
      border: 0; }
      .register-wizard .card .card-header .logo {
        width: 200px;
        display: inline-block;
        margin-bottom: 15px;
        padding: 15px 0;
        border-bottom: 2px solid #CCC; }
    .register-wizard .card .socialmedia-register {
      margin-bottom: 30px; }
      .register-wizard .card .socialmedia-register .btn {
        color: #fff;
        width: 100%;
        font-size: 14px;
        height: 40px; }
        .register-wizard .card .socialmedia-register .btn-facebook {
          background: #4584EA;
          margin-right: 5px; }
        .register-wizard .card .socialmedia-register .btn-google {
          background: #DB4437; }
    .register-wizard .card .form-register input {
      border-radius: 100px !important; }
    .register-wizard .card .text a {
      font-weight: 600; }
    .register-wizard .card .login p {
      display: inline-block; }

.stepwizard {
  width: 100%;
  position: absolute; }

.form-wizard {
  color: #888888;
  padding: 30px; }
  .form-wizard .wizard-form-radio {
    display: inline-block;
    margin-left: 5px;
    position: relative; }
    .form-wizard .wizard-form-radio input[type="radio"] {
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      -o-appearance: none;
      appearance: none;
      background-color: #dddddd;
      height: 25px;
      width: 25px;
      display: inline-block;
      vertical-align: middle;
      border-radius: 50%;
      position: relative;
      cursor: pointer; }
      .form-wizard .wizard-form-radio input[type="radio"]:focus {
        outline: 0; }
      .form-wizard .wizard-form-radio input[type="radio"]:checked {
        background-color: #fb1647; }
        .form-wizard .wizard-form-radio input[type="radio"]:checked::before {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          display: inline-block;
          background-color: #ffffff;
          border-radius: 50%;
          left: 1px;
          right: 0;
          margin: 0 auto;
          top: 8px; }
        .form-wizard .wizard-form-radio input[type="radio"]:checked::after {
          content: "";
          display: inline-block;
          webkit-animation: click-radio-wave 0.65s;
          -moz-animation: click-radio-wave 0.65s;
          animation: click-radio-wave 0.65s;
          background: #000000;
          content: '';
          display: block;
          position: relative;
          z-index: 100;
          border-radius: 50%; }
      .form-wizard .wizard-form-radio input[type="radio"] ~ label {
        padding-left: 10px;
        cursor: pointer; }
  .form-wizard .form-wizard-header {
    text-align: center;
    position: absolute;
    top: 98px;
    width: 200px; }
  .form-wizard .form-wizard-next-btn, .form-wizard .form-wizard-previous-btn, .form-wizard .form-wizard-submit {
    background-color: #d65470;
    color: #ffffff;
    display: inline-block;
    min-width: 100px;
    min-width: 120px;
    padding: 10px;
    text-align: center; }
    .form-wizard .form-wizard-next-btn:hover, .form-wizard .form-wizard-next-btn:focus, .form-wizard .form-wizard-previous-btn:hover, .form-wizard .form-wizard-previous-btn:focus, .form-wizard .form-wizard-submit:hover, .form-wizard .form-wizard-submit:focus {
      color: #ffffff;
      opacity: 0.6;
      text-decoration: none; }
  .form-wizard .form-radio {
    width: 12.2868%;
    margin: 0 1%; }
  .form-wizard .button-previous {
    position: absolute;
    top: 50px;
    z-index: 2;
    font-size: 22px;
    left: 50px;
    color: #FCC50A; }
  .form-wizard .form-title {
    margin-top: 41px;
    margin-bottom: 15px; }
    .form-wizard .form-title h4 {
      font-weight: 700;
      margin-bottom: 0px;
      color: #2D0A40; }
  .form-wizard .wizard-fieldset {
    transition: 0.5s ease all; }
    .form-wizard .wizard-fieldset:nth-child(2n+1) {
      position: absolute;
      max-width: 1382px;
      transform: translateY(-50%) scale(0.5);
      top: 50%;
      right: -789px;
      opacity: 0.5; }
    .form-wizard .wizard-fieldset:nth-child(2n+0) {
      position: absolute;
      max-width: 595px;
      top: 50%;
      transform: translateY(-50%) scale(0.8);
      left: -375px;
      opacity: 0.5; }
    .form-wizard .wizard-fieldset.active {
      opacity: 1;
      left: 50%;
      transform: translate(-50%, -50%) scale(1); }
  .form-wizard .wizard-form-error {
    display: none;
    /* background-color: #d70b0b; */
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50px;
    width: 100%;
    z-index: 0;
    border-radius: 100px;
    border-bottom: 2px solid #ff0000;
    top: 5px; }
  .form-wizard .form-group {
    position: relative;
    margin: 25px 0; }
    .form-wizard .form-group .form-control {
      font-weight: 300;
      height: auto !important;
      padding: 15px;
      color: #888888;
      background-color: #f1f1f1;
      border: none;
      position: relative;
      z-index: 3; }
      .form-wizard .form-group .form-control:focus {
        box-shadow: none; }
  .form-wizard .wizard-form-text-label {
    position: absolute;
    left: 10px;
    top: 16px;
    transition: 0.2s linear all; }
  .form-wizard .focus-input .wizard-form-text-label {
    color: #d65470;
    top: -18px;
    transition: 0.2s linear all;
    font-size: 12px; }
  .form-wizard .form-wizard-steps {
    margin: 30px 0;
    text-align: center; }
    .form-wizard .form-wizard-steps li {
      width: 49%;
      float: none;
      position: relative;
      display: inline-block; }
      .form-wizard .form-wizard-steps li a {
        background-color: #dddddd;
        border-radius: 50%;
        display: inline-block;
        height: 15px;
        line-height: 15px;
        position: relative;
        text-align: center;
        width: 15px;
        z-index: 1; }
        .form-wizard .form-wizard-steps li a::after {
          background-color: #f3f3f3;
          content: "";
          height: 2px;
          left: 0;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 52px;
          border-bottom: 1px solid #dddddd;
          border-top: 1px solid #dddddd; }
        .form-wizard .form-wizard-steps li a.active, .form-wizard .form-wizard-steps li a.activated {
          background-color: #FF015B;
          color: #ffffff; }
          .form-wizard .form-wizard-steps li a.active:after, .form-wizard .form-wizard-steps li a.activated:after {
            background-color: #FF015B;
            left: 100%;
            border-color: #FF015B; }
      .form-wizard .form-wizard-steps li:last-child a:after {
        left: -41px; }
  .form-wizard .wizard-password-eye {
    position: absolute;
    right: 32px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer; }
  .form-wizard label {
    width: 100%; }
  .form-wizard input[type="radio"] {
    display: none; }
    .form-wizard input[type="radio"]:checked + span {
      box-shadow: 3px 4px 6px 0px rgba(0, 0, 0, 0.14);
      background: #FFF;
      transform: scale(1.1); }
      .form-wizard input[type="radio"]:checked + span:after {
        left: 25px; }
      .form-wizard input[type="radio"]:checked + span:before {
        background: #FCC50A; }
      .form-wizard input[type="radio"]:checked + span .img img {
        filter: grayscale(0%);
        -webkit-transition: .3s ease-in-out all;
        transition: .3s ease-in-out all; }
    .form-wizard input[type="radio"] + span {
      cursor: pointer;
      user-select: none;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      background: linear-gradient(#F2F2F2, #FFF);
      width: 100%;
      padding: 0;
      width: 100%;
      margin-bottom: 15px;
      border-radius: 5px;
      height: 16vh;
      border-radius: 30px;
      transition: .3s ease-in-out all; }
      .form-wizard input[type="radio"] + span .img {
        position: relative;
        height: 8vh; }
        .form-wizard input[type="radio"] + span .img img {
          max-width: 60%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          filter: grayscale(100%);
          -webkit-transition: .3s ease-in-out all;
          transition: .3s ease-in-out all;
          max-height: 80%; }
      .form-wizard input[type="radio"] + span .title {
        position: relative;
        height: 20px;
        width: 100%;
        text-align: center; }
        .form-wizard input[type="radio"] + span .title p {
          width: 100%;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          font-size: 1.3vh;
          font-weight: 600; }
      .form-wizard input[type="radio"] + span:after {
        display: none; }
      .form-wizard input[type="radio"] + span:before {
        display: none; }

@keyframes click-radio-wave {
  0% {
    width: 25px;
    height: 25px;
    opacity: 0.35;
    position: relative; }
  100% {
    width: 60px;
    height: 60px;
    margin-left: -15px;
    margin-top: -15px;
    opacity: 0.0; } }

.register-wizard .card .card-header .logo img {
  max-height: 9vh;
  max-width: 19vh; }

@media only screen and (max-width: 991px) {
  .wizard-fieldset {
    width: 100%;
    flex: inherit; } }

@media screen and (max-width: 767px) {
  .wizard-content-left {
    height: auto; } }

@media only screen and (max-width: 1441px) {
  .form-wizard-header {
    top: 74px !important; } }

@media only screen and (max-width: 1280px) {
  .form-wizard-header {
    top: 27px !important; } }

@media only screen and (max-width: 992px) {
  .register {
    overflow: inherit; }
  .register-wizard {
    padding-bottom: 15px;
    height: auto; }
    .register-wizard .form-wizard {
      padding: 0; }
      .register-wizard .form-wizard .form-wizard-header {
        top: 15px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2; }
    .register-wizard .wizard-fieldset:nth-child(2n+0) {
      position: static;
      transform: translate(0);
      margin-top: 30px; }
    .register-wizard .wizard-fieldset:nth-child(2n+1) {
      position: static;
      transform: translate(0);
      margin-top: 30px; }
    .register-wizard .wizard-fieldset {
      display: none; }
      .register-wizard .wizard-fieldset.active {
        display: block; }
    .register-wizard .card {
      padding: 15px;
      border: 0; }
    .register-wizard .form-radio {
      width: 48%; } }

@media only screen and (max-width: 768px) {
  .register {
    background-image: none !important; }
    .register .register-wizard {
      background: transparent; }
    .register .container {
      padding: 0; }
    .register .card {
      padding: 13vh 15px; }
  .fixed-button {
    position: fixed !important;
    bottom: 0;
    background: white;
    width: 100%;
    padding: 21px;
    text-align: center;
    margin-bottom: 0 !important;
    left: 0;
    display: none;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.29); }
  .form-scroll {
    padding: 0 18px; }
  .register-wizard .wizard-fieldset:nth-child(2n+0) {
    margin-top: 0; }
  .register-wizard .wizard-fieldset:nth-child(2n+1) {
    margin-top: 0; }
  .register-wizard .card .socialmedia-register {
    margin-bottom: 0; }
  .register-wizard .card .socialmedia-register .btn {
    font-size: 13px; }
  .form-wizard input[type="radio"]:checked + span {
    box-shadow: 3px 4px 6px 0px rgba(0, 0, 0, 0.14);
    background: #FFF;
    transform: scale(1.1);
    position: relative;
    border: 1px solid #FCC50A;
    z-index: 1; } }

@media only screen and (max-width: 420px) {
  .card {
    padding: 6vh 15px !important; } }

@media only screen and (max-width: 320px) {
  .card .socialmedia-register .btn {
    font-size: 11px !important; } }

.supporters-box {
  position: relative;
  border-radius: 20px;
  transition: all 0.5s ease;
  box-shadow: 4px 7px 26px 0px rgba(0, 0, 0, 0.13);
  overflow: hidden;
  text-align: center;
  margin-bottom: 30px; }
  .supporters-box-img {
    height: 175px;
    overflow: hidden;
    border-radius: 20px;
    position: relative; }
    .supporters-box-img img {
      width: 100%;
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .supporters-box-company {
    width: 160px;
    height: 60px;
    background: #fff;
    text-align: center;
    display: inline-block;
    background: #fff;
    border-radius: 20px;
    box-shadow: 4px 7px 26px 0px rgba(0, 0, 0, 0.13);
    position: relative;
    top: -30px; }
    .supporters-box-company img {
      max-height: 50px;
      max-width: 75px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .supporters-box-detail {
    position: relative;
    top: -15px; }
    .supporters-box-detail h3 {
      font-weight: 800;
      margin: 0; }

.dropdown-menu {
  width: 180px; }

.form-scroll {
  height: 58vh;
  overflow-y: scroll;
  overflow-x: hidden; }

.rellax-container {
  position: absolute;
  width: 700px;
  height: 100%;
  z-index: 0; }
  .rellax-container.right-center {
    right: -400px; }
  .rellax-container.left-center {
    left: -400px; }
  .rellax-container .rellax {
    position: absolute;
    width: 700px;
    height: 201%;
    background-repeat: no-repeat; }
    .rellax-container .rellax.bg-1 {
      width: 700px;
      height: 100%; }
    .rellax-container .rellax.bg-2 {
      top: 72px;
      left: 58px; }
    .rellax-container .rellax.bg-3 {
      top: -79px;
      left: -149px; }
    .rellax-container .rellax.bg-4 {
      top: 0;
      right: 84px; }
    .rellax-container .rellax.bg-5 {
      top: 240px;
      right: -90px; }
    .rellax-container .rellax.bg-6 {
      top: 230px;
      right: -506px; }
    .rellax-container .rellax.bg-7 {
      top: -1106px;
      left: -54px; }
    .rellax-container .rellax.bg-8 {
      top: 346px;
      right: -7px; }
    .rellax-container .rellax.bg-9 {
      top: -647px;
      right: -203px; }
    .rellax-container .rellax.bg-10 {
      top: -801px;
      right: 56px; }

@media only screen and (max-width: 768px) {
  .rellax-container {
    display: none; } }

.anadoluefesgonulluleri.hummel section {
  color: #000000; }
  .anadoluefesgonulluleri.hummel section .section-header {
    border-bottom: 2px solid #000000; }

.anadoluefesgonulluleri.hummel footer {
  background: #000000; }

.anadoluefesgonulluleri header {
  top: 50px;
  z-index: 3; }
  .anadoluefesgonulluleri header .logo a img {
    width: auto;
    height: 100%;
    left: 0;
    transform: translate(0, -50%); }
  .anadoluefesgonulluleri header p {
    color: #fff;
    margin-bottom: 0;
    margin-top: 7px;
    display: inline-block;
    font-size: 20px; }

.anadoluefesgonulluleri .section-header {
  border-bottom: 2px solid #213557;
  text-align: left;
  height: 32px;
  margin-bottom: 25px; }
  .anadoluefesgonulluleri .section-header:before {
    display: none; }
  .anadoluefesgonulluleri .section-header .title {
    text-align: left;
    width: auto;
    background: #fff;
    margin-bottom: -5px;
    display: inline-block;
    padding: 0px 10px 0 0;
    font-weight: 400; }

.anadoluefesgonulluleri .section-body .section-img {
  height: 400px;
  position: relative; }
  .anadoluefesgonulluleri .section-body .section-img img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 69%; }

.anadoluefesgonulluleri .section-body .section-detail {
  height: 400px;
  padding: 116px 120px;
  color: #fff;
  background-size: 100%; }
  .anadoluefesgonulluleri .section-body .section-detail .desc {
    color: #fff; }

.anadoluefesgonulluleri .section-body .sec-title {
  color: #b19959; }

.anadoluefesgonulluleri .section-body .companies-logos .company-logo {
  height: 150px;
  width: 100%;
  position: relative;
  transition: 0.5s ease all;
  margin-bottom: 15px; }
  .anadoluefesgonulluleri .section-body .companies-logos .company-logo img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .anadoluefesgonulluleri .section-body .companies-logos .company-logo:hover {
    opacity: 1; }

.anadoluefesgonulluleri .section-main {
  display: inline-block;
  padding: 0; }
  .anadoluefesgonulluleri .section-main .section-bg img {
    width: 100%; }
  .anadoluefesgonulluleri .section-main .main-inner {
    position: absolute;
    top: 0%;
    width: 100%;
    height: 100%; }
    .anadoluefesgonulluleri .section-main .main-inner .main-detail {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0%; }
      .anadoluefesgonulluleri .section-main .main-inner .main-detail .title {
        color: #fff;
        font-weight: 400;
        font-size: 4vh; }
      .anadoluefesgonulluleri .section-main .main-inner .main-detail .desc {
        color: #fff; }
    .anadoluefesgonulluleri .section-main .main-inner img {
      width: 245px;
      position: absolute;
      right: 0;
      bottom: 76px; }
  .anadoluefesgonulluleri .section-main .main-shape {
    position: absolute;
    bottom: -142px;
    height: 367px;
    width: 100%;
    background-size: auto 100%;
    left: 0;
    right: 0;
    background-repeat: no-repeat; }

.anadoluefesgonulluleri .section-platform .title {
  color: #b19959;
  margin-top: -92px; }

.anadoluefesgonulluleri .section-platform img {
  margin-right: -20px;
  float: right;
  width: 136%; }

.anadoluefesgonulluleri .btn {
  padding: 10px 15px; }
  .anadoluefesgonulluleri .btn.btn-black {
    background: #000000;
    color: #fff; }
  .anadoluefesgonulluleri .btn-outline-white {
    color: #fff;
    border: 1px solid #fff;
    margin-left: 15px; }
    .anadoluefesgonulluleri .btn-outline-white:hover {
      background: #fff;
      color: #213557; }
  .anadoluefesgonulluleri .btn-darkblue {
    background: #213557;
    color: #b19959;
    box-shadow: 4px 7px 26px 0 rgba(33, 53, 87, 0.27); }

.anadoluefesgonulluleri footer {
  background: #002e79; }
  .anadoluefesgonulluleri footer .footer-box .footer-logo a i {
    color: #fff; }
  .anadoluefesgonulluleri footer .footer-box p,
  .anadoluefesgonulluleri footer .footer-box span {
    margin: 0;
    color: #fff; }
  .anadoluefesgonulluleri footer .footer-box a {
    color: #fff; }

.anadoluefesgonulluleri.hummel section p {
  color: #000000; }

.anadoluefesgonulluleri.hummel .section-platform .title {
  color: #000000; }

.akenerji .section-platform .title {
  color: #203e7b; }

.akenerji .btn-darkblue {
  color: #fff;
  background-color: #203e7b; }

.akenerji .section-header {
  border-color: 2px solid #203e7b; }
  .akenerji .section-header .title {
    color: #203e7b; }

.akenerji .section-body .section-detail {
  background-size: auto 100%;
  background-position: center;
  position: relative; }
  .akenerji .section-body .section-detail:before {
    content: '';
    position: absolute;
    width: 100%;
    background-color: rgba(233, 14, 140, 0.8);
    height: 100%;
    top: 0%;
    left: 0; }
  .akenerji .section-body .section-detail .title {
    position: relative;
    z-index: 1; }
  .akenerji .section-body .section-detail .desc {
    position: relative;
    z-index: 1; }

.akenerji .section-body .section-img img {
  width: 100%; }

.pfizer .section-platform .title {
  color: #0088ce; }

.pfizer .btn-darkblue {
  color: #fff;
  background-color: #0088ce; }

.pfizer .section-header {
  border-color: 2px solid #0088ce; }
  .pfizer .section-header .title {
    color: #0088ce; }

.pfizer .section-body .section-detail {
  background-size: auto 100%;
  background-position: center;
  position: relative; }
  .pfizer .section-body .section-detail:before {
    content: '';
    position: absolute;
    width: 100%;
    background-color: rgba(83, 83, 81, 0.8);
    height: 100%;
    top: 0%;
    left: 0; }
  .pfizer .section-body .section-detail .title {
    position: relative;
    z-index: 1; }
  .pfizer .section-body .section-detail .desc {
    position: relative;
    z-index: 1; }

.pfizer .section-body .section-img img {
  width: 100%; }

.borusan .section-platform .title {
  color: #f26727; }

.borusan .btn-darkblue {
  color: #fff;
  background-color: #f26727; }

.borusan .section-header {
  border-color: 2px solid #f26727; }
  .borusan .section-header .title {
    color: #f26727; }

.borusan .section-body .section-detail:before {
  background-color: rgba(9, 78, 163, 0.8); }

.borusan .section-body .section-img img {
  width: 80%; }

.kale p {
  color: #000; }

.kale.hummel section {
  color: #000000; }
  .kale.hummel section .section-header {
    border-bottom: 2px solid #000000; }

.kale.hummel footer {
  background: #000000; }

.kale header {
  top: 50px;
  z-index: 3; }
  .kale header .logo a {
    height: 73px;
    margin-top: -27px; }
    .kale header .logo a img {
      width: 134px;
      left: 0;
      transform: translateY(-54%);
      max-width: inherit;
      max-height: inherit; }
  .kale header p {
    color: #fff;
    margin-bottom: 0;
    margin-top: 7px;
    display: inline-block;
    font-size: 20px; }

.kale .section-header {
  border-bottom: 2px solid #04252e;
  text-align: left;
  height: 32px;
  margin-bottom: 25px; }
  .kale .section-header:before {
    display: none; }
  .kale .section-header .title {
    text-align: left;
    width: auto;
    background: #fff;
    margin-bottom: -5px;
    display: inline-block;
    padding: 0px 10px 0 0;
    font-weight: 400;
    color: #04252e; }

.kale .section-body .section-img {
  height: 400px;
  position: relative; }
  .kale .section-body .section-img img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 69%; }

.kale .section-body .section-detail {
  height: 400px;
  padding: 116px 120px;
  color: #fff;
  background-size: 100%; }
  .kale .section-body .section-detail .desc {
    color: #fff; }

.kale .section-body .sec-title {
  color: #b19959; }

.kale .section-body .companies-logos .company-logo {
  height: 150px;
  width: 100%;
  position: relative;
  transition: 0.5s ease all;
  margin-bottom: 15px; }
  .kale .section-body .companies-logos .company-logo img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .kale .section-body .companies-logos .company-logo:hover {
    opacity: 1; }

.kale .section-main {
  display: inline-block;
  padding: 0; }
  .kale .section-main .section-bg img {
    width: 100%; }
  .kale .section-main .main-inner {
    position: absolute;
    top: 0%;
    width: 100%;
    height: 100%; }
    .kale .section-main .main-inner .main-detail {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0%; }
      .kale .section-main .main-inner .main-detail .title {
        color: #fff;
        font-weight: 400;
        font-size: 4vh; }
      .kale .section-main .main-inner .main-detail .desc {
        color: #fff; }
    .kale .section-main .main-inner img {
      width: 245px;
      position: absolute;
      right: 0;
      bottom: 76px; }
  .kale .section-main .main-shape {
    position: absolute;
    bottom: -142px;
    height: 367px;
    width: 100%;
    background-size: auto 100%;
    left: 0;
    right: 0;
    background-repeat: no-repeat; }

.kale .section-platform .title {
  color: #ed4636;
  margin-top: -92px; }

.kale .section-platform img {
  margin-right: -20px;
  float: right;
  width: 136%; }

.kale .btn {
  padding: 10px 15px; }
  .kale .btn.btn-black {
    background: #000000;
    color: #fff; }
  .kale .btn-outline-white {
    color: #fff;
    border: 1px solid #fff;
    margin-left: 15px; }
    .kale .btn-outline-white:hover {
      background: #fff;
      color: #213557; }
  .kale .btn-darkblue {
    background: #ed4636;
    color: #FFF;
    box-shadow: 4px 7px 26px 0 rgba(33, 53, 87, 0.27); }

.kale footer {
  background: #04252e; }
  .kale footer .footer-box .title {
    color: #ed4636; }
  .kale footer .footer-box .footer-logo a i {
    color: #fff; }
  .kale footer .footer-box p,
  .kale footer .footer-box span {
    margin: 0;
    color: #fff; }
  .kale footer .footer-box a {
    color: #fff; }

.kale.hummel section p {
  color: #000000; }

.kale.hummel .section-platform .title {
  color: #000000; }

@media only screen and (max-width: 992px) {
  .anadoluefesgonulluleri header {
    top: 0;
    background: transparent;
    position: absolute; }
    .anadoluefesgonulluleri header .logo {
      width: 113px !important; }
      .anadoluefesgonulluleri header .logo img {
        width: 100%; }
  .anadoluefesgonulluleri .section {
    padding: 67px 0; }
    .anadoluefesgonulluleri .section-body .section-detail {
      height: auto;
      background-size: auto 100%;
      padding: 15px; }
    .anadoluefesgonulluleri .section-main {
      padding: 0 !important; }
      .anadoluefesgonulluleri .section-main .section-bg {
        height: 400px;
        overflow: hidden; }
        .anadoluefesgonulluleri .section-main .section-bg img {
          width: auto;
          height: 100%; }
      .anadoluefesgonulluleri .section-main .main-inner .main-detail {
        padding: 0 15px; }
    .anadoluefesgonulluleri .section-platform .title {
      margin-top: -47px; }
  .kale header {
    top: 0;
    background: transparent;
    position: absolute; }
    .kale header .logo {
      width: 113px !important; }
      .kale header .logo a {
        margin-top: -12px; }
      .kale header .logo img {
        width: 100% !important; }
  .kale .section {
    padding: 67px 0; }
    .kale .section-body .section-detail {
      height: auto;
      background-size: auto 100%;
      padding: 15px; }
    .kale .section-main {
      padding: 0 !important; }
      .kale .section-main .section-bg {
        height: 400px;
        overflow: hidden; }
        .kale .section-main .section-bg img {
          width: auto;
          height: 100%; }
      .kale .section-main .main-inner .main-detail {
        padding: 0 15px; }
    .kale .section-platform .title {
      margin-top: -47px; }
  .pfizer header {
    top: 0;
    background: transparent;
    position: absolute; }
    .pfizer header .logo {
      width: 113px !important; }
      .pfizer header .logo img {
        width: 100%; }
  .pfizer .section {
    padding: 67px 0; }
    .pfizer .section-body .section-detail {
      height: auto;
      background-size: auto 100%;
      padding: 15px; }
    .pfizer .section-main {
      padding: 0 !important; }
      .pfizer .section-main .section-bg {
        height: 303px;
        overflow: hidden; }
        .pfizer .section-main .section-bg img {
          width: 181%;
          height: auto;
          position: absolute;
          right: 0; }
      .pfizer .section-main .main-inner .main-detail {
        padding: 0 15px; }
    .pfizer .section-platform .title {
      margin-top: -47px; }
  .pfizer header p {
    color: #fff;
    margin-bottom: 0;
    margin-top: 7px;
    display: inline-block;
    font-size: 20px;
    position: absolute;
    top: 62px;
    left: 15px; } }

.kurumsal_iyilik_platformu {
  position: relative;
  z-index: 0; }
  .kurumsal_iyilik_platformu header .logo .header-bg {
    background-image: none !important; }
  .kurumsal_iyilik_platformu header .main-menu {
    display: inline-block;
    width: auto;
    text-align: left;
    float: right; }
    .kurumsal_iyilik_platformu header .main-menu ul {
      text-align: right; }
      .kurumsal_iyilik_platformu header .main-menu ul li {
        display: inline-block;
        float: none; }
  .kurumsal_iyilik_platformu header .login-menu {
    float: right; }
  .kurumsal_iyilik_platformu .section-header {
    text-align: center; }
    .kurumsal_iyilik_platformu .section-header:before {
      display: none; }
    .kurumsal_iyilik_platformu .section-header .title {
      font-weight: 600; }
    .kurumsal_iyilik_platformu .section-header .description {
      display: inline-block;
      max-width: 600px;
      float: none;
      color: #495057; }
  .kurumsal_iyilik_platformu .section-body {
    margin-top: 30px; }
  .kurumsal_iyilik_platformu .section-main {
    height: 100vh;
    padding: 0; }
  .kurumsal_iyilik_platformu .section .img {
    position: absolute;
    height: 100vh;
    right: 0;
    top: 50%;
    transform: translateY(-50%); }
    .kurumsal_iyilik_platformu .section .img video {
      right: 0;
      position: absolute;
      top: 0; }
    .kurumsal_iyilik_platformu .section .img img {
      float: right;
      position: absolute;
      right: 0px;
      top: 62px; }
  .kurumsal_iyilik_platformu .section .section-inner {
    position: relative;
    z-index: 9; }
    .kurumsal_iyilik_platformu .section .section-inner .title {
      font-size: 5.5vh;
      font-weight: 700;
      margin-bottom: 3vh; }
    .kurumsal_iyilik_platformu .section .section-inner .desc {
      margin-bottom: 9vh;
      max-width: 500px; }
    .kurumsal_iyilik_platformu .section .section-inner .desc-2 {
      max-width: 500px; }
    .kurumsal_iyilik_platformu .section .section-inner .btn {
      margin-bottom: 5vh;
      font-size: 2.5vh; }
  .kurumsal_iyilik_platformu .companies-container {
    width: 100%; }
    .kurumsal_iyilik_platformu .companies-container a {
      width: 20%;
      transition: all .3s ease-in-out;
      background: #fff;
      height: 69px;
      margin-right: 19px;
      border-radius: 15px;
      box-shadow: 0 0 34px 0 rgba(0, 93, 255, 0.14);
      position: relative;
      display: inline-block; }
      .kurumsal_iyilik_platformu .companies-container a img {
        max-height: 50%;
        transition: all .3s ease-in-out;
        max-width: 50%;
        top: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%; }
  .kurumsal_iyilik_platformu .partner-box {
    width: 100%;
    transition: all .3s ease-in-out;
    background: #fff;
    height: 200px;
    margin-right: 19px;
    border-radius: 15px;
    box-shadow: 0 0 34px 0 rgba(0, 93, 255, 0.14);
    position: relative; }
    .kurumsal_iyilik_platformu .partner-box img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 175px;
      max-height: 175px; }
  .kurumsal_iyilik_platformu .other-box {
    width: 100%;
    transition: all .3s ease-in-out;
    background: #fff;
    margin-right: 19px;
    border-radius: 15px;
    box-shadow: 0 0 34px 0 rgba(0, 93, 255, 0.14);
    position: relative;
    padding: 30px 15px;
    text-align: center;
    margin-bottom: 30px;
    height: 248px; }
    .kurumsal_iyilik_platformu .other-box .icon {
      height: 75px;
      position: relative;
      margin-bottom: 15px; }
      .kurumsal_iyilik_platformu .other-box .icon img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 100%; }
    .kurumsal_iyilik_platformu .other-box .title {
      font-weight: 600;
      font-size: 18px; }
  .kurumsal_iyilik_platformu footer {
    background-color: #F6BC00;
    padding: 30px 0; }
    .kurumsal_iyilik_platformu footer .logo {
      display: inline-block; }
      .kurumsal_iyilik_platformu footer .logo a {
        padding: 0 !important; }
    .kurumsal_iyilik_platformu footer i {
      font-size: 60px; }
    .kurumsal_iyilik_platformu footer a {
      padding: 21px 0;
      display: block; }

.ik-header {
  text-align: center; }
  .ik-header i {
    font-size: 55px; }

#exampleModalLong-backdrop {
  background: #fff; }
  #exampleModalLong-backdrop.show {
    opacity: 0.8; }

#exampleModalLong .close {
  opacity: 1;
  position: absolute;
  top: 15px;
  left: 15px;
  text-shadow: 0px 0px 0px;
  font-size: 20px; }
  #exampleModalLong .close span {
    font-size: 37px;
    font-weight: 100; }

#exampleModalLong .form label {
  background: #FFF;
  padding: 0 10px;
  margin-left: 15px; }

#exampleModalLong .form input {
  margin-top: -19px;
  border-radius: 10px !important;
  height: 59px; }

#exampleModalLong .modal-dialog {
  max-width: 656px !important; }

#exampleModalLong .modal-content {
  box-shadow: 6px 18px 24px 0 rgba(0, 0, 0, 0.11);
  border-radius: 10px !important; }

#exampleModalLong .modal-body {
  padding: 50px 75px;
  background-repeat: no-repeat;
  background-position: bottom right; }

.modal-backdrop {
  background: #fff; }
  .modal-backdrop.show {
    opacity: 0.8; }

@media only screen and (max-width: 1500px) {
  .kurumsal_iyilik_platformu .section .section-inner {
    margin-top: 20vh; } }

@media only screen and (max-width: 1300px) {
  .kurumsal_iyilik_platformu .section-main .img img {
    width: 138%; }
  .kurumsal_iyilik_platformu .section .section-inner {
    margin-top: 20vh; } }

@media only screen and (max-width: 992px) {
  .kurumsal_iyilik_platformu .header .login-menu {
    display: block;
    width: auto !important;
    left: inherit;
    right: 12px;
    top: 23px; }
  .kurumsal_iyilik_platformu .header .main-menu {
    display: none; }
    .kurumsal_iyilik_platformu .header .main-menu.active {
      display: block; }
  .kurumsal_iyilik_platformu .section-main .img {
    position: static;
    /* margin-top: 228px; */
    height: auto; }
    .kurumsal_iyilik_platformu .section-main .img video {
      position: static;
      width: 100%; }
  .kurumsal_iyilik_platformu .modal-body {
    padding: 30px 30px; }
  .kurumsal_iyilik_platformu .companies-container {
    width: 100%; }
    .kurumsal_iyilik_platformu .companies-container a {
      width: 44%;
      transition: all .3s ease-in-out;
      background: #fff;
      height: 69px;
      margin-right: 19px;
      border-radius: 15px;
      box-shadow: 0 0 34px 0 rgba(0, 93, 255, 0.14);
      position: relative;
      display: inline-block;
      margin-bottom: 15px; }
      .kurumsal_iyilik_platformu .companies-container a img {
        max-height: 50%;
        transition: all .3s ease-in-out;
        max-width: 50%;
        top: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%; } }

.navigation {
  display: flex;
  align-items: center; }

.login-btn {
  border: 3px solid #ffb700;
  width: 164px;
  height: 45px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  color: #ffb700;
  margin-right: 40px; }

.htw .uyeol-btn {
  margin: 0 auto;
  margin-top: 40px;
  width: 270px;
  height: 50px;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  color: #fff; }

.htw .uyeol-btn:hover {
  text-decoration: none; }

.login-btn:hover {
  text-decoration: none;
  color: white;
  background: #ffcc00 !important;
  /* Old browsers */
  background: -moz-linear-gradient(left, #ffcc00 0%, #ff8d00 100%) !important;
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #ffcc00 0%, #ff8d00 100%) !important;
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(-137deg, #ffcc00 0%, #ff8d00 100%) !important;
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffcc00', endColorstr='#ff8d00',GradientType=1 );
  /* IE6-9 */
  border: none; }

.register-btn {
  border: 3px solid #ffb700;
  width: 164px;
  height: 45px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  margin-left: 30px;
  color: #ffb700; }

.register-btn:hover {
  text-decoration: none;
  color: white;
  background: #ffcc00 !important;
  /* Old browsers */
  background: -moz-linear-gradient(left, #ffcc00 0%, #ff8d00 100%) !important;
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #ffcc00 0%, #ff8d00 100%) !important;
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(-137deg, #ffcc00 0%, #ff8d00 100%) !important;
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffcc00', endColorstr='#ff8d00',GradientType=1 );
  /* IE6-9 */
  border: none; }

.mobile-nav-toggler {
  position: absolute;
  right: 30px; }

.map-outer {
  position: relative;
  width: 100%;
  margin-top: 109px;
  z-index: 2; }

.shadow {
  background-image: url(../img/contact-shadow.png);
  width: 100%;
  height: 417px;
  position: absolute;
  top: 0;
  z-index: 99; }

.contact-outer {
  padding-bottom: 80px;
  padding-top: 70px; }

.info {
  margin-bottom: 50px; }

.info .title {
  font-size: 25px;
  color: #483d60;
  margin-bottom: 15px; }

.info .subtitle {
  font-size: 14px;
  color: #878294; }

.info ul {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.info ul li {
  margin-bottom: 15px;
  display: flex;
  align-items: center; }

.info ul li img {
  margin-right: 7px; }

.info ul li span {
  font-size: 14px;
  color: #483e60; }

.form .title {
  font-size: 25px;
  color: #483d60;
  margin-bottom: 15px; }

.form .input-group {
  margin-bottom: 15px; }

.form .input-group .input-group-prepend {
  display: flex;
  align-items: center;
  border: 1px solid #e0e3e5;
  border-right: 0;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  height: 35px;
  padding-left: 10px;
  padding-right: 10px; }

.form .input-group .input-group-prepend.f {
  height: 120px;
  align-items: flex-start;
  padding-top: 10px; }

.form .input-group input, textarea {
  height: 35px;
  border: 1px solid #e0e3e5;
  border-left: 0;
  -webkit-border-top-left-radius: 0px;
  -webkit-border-bottom-left-radius: 0px;
  -moz-border-radius-topleft: 0px;
  -moz-border-radius-bottomleft: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-weight: 400;
  font-size: 15px;
  padding-top: 10px;
  color: #929699; }

.form .input-group input:focus {
  box-shadow: none;
  outline: none; }

.form .input-group textarea:focus {
  box-shadow: none;
  outline: none; }

.form .input-group textarea {
  height: 120px;
  width: 100%;
  resize: none; }

.form .send-btn {
  width: 120px;
  height: 50px;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  color: #fff; }

.form .send-btn:hover {
  text-decoration: none; }

.form .form-footer {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.form .form-footer .success-message {
  display: flex;
  align-items: center;
  opacity: 0; }

.form .form-footer .success-message img {
  margin-right: 5px; }

.form .form-footer .success-message p {
  margin-bottom: 0;
  line-height: 20px;
  font-weight: 500;
  color: #32ba7c;
  font-size: 14px; }

.mobile-header {
  display: none;
  height: 82px;
  position: relative;
  z-index: 9999;
  width: 100vw;
  top: 0; }

.mobile-header .scroll-m {
  display: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 35px;
  padding-right: 35px; }

.zero-m {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; }

.scroll-mh {
  background: white;
  -webkit-box-shadow: 0px 10px 17px -8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 10px 17px -8px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 17px -8px rgba(0, 0, 0, 0.2); }

.mobile-header .scroll-m .nav-link {
  border: 3px solid #ffc400;
  padding-right: 35px !important;
  padding-left: 35px !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  border-radius: 50px !important;
  height: 43px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 16px; }

.mobile-header .scroll-m .nav-itemt {
  position: inherit; }

.mobile-header .scroll-m .logo {
  position: inherit; }

.mobile-header .scroll-m .mobile-logo {
  box-shadow: none !important; }

.mobile-header .logo {
  position: absolute;
  top: 38px;
  z-index: 2; }

.mobile-header .nav-itemt {
  position: absolute;
  right: 30px; }

.nav-itemt .dropdown-toggle:after {
  content: none; }

.nav-itemt .dropdown-menu {
  left: auto !important;
  right: 0 !important;
  top: 20px !important;
  border: 0;
  background-color: #f8f9fd;
  text-align: right;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0; }

.nav-itemt .dropdown-menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-right: 20px; }

.nav-itemt .dropdown-menu ul li a {
  color: #847f92;
  font-size: 16px; }

.nav-itemt .dropdown-menu.show {
  max-height: 300px;
  opacity: 1; }

.nav-itemt .dropdown-menu li {
  margin-bottom: 10px; }

.nav-itemt .dropdown-menu a:hover {
  text-decoration: none; }

@media only screen and (max-width: 768px) {
  .mobile-header {
    display: flex; } }

.navigation {
  display: flex;
  align-items: center; }

.login-btn {
  border: 3px solid #ffb700;
  width: 164px;
  height: 45px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  color: #ffb700;
  margin-right: 40px; }

.htw .uyeol-btn {
  margin: 0 auto;
  margin-top: 40px;
  width: 270px;
  height: 50px;
  display: flex;
  font-weight: 700;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  color: #fff; }

.htw .uyeol-btn:hover {
  text-decoration: none; }

.login-btn:hover {
  text-decoration: none;
  color: white;
  background: #ffcc00 !important;
  /* Old browsers */
  background: -moz-linear-gradient(left, #ffcc00 0%, #ff8d00 100%) !important;
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #ffcc00 0%, #ff8d00 100%) !important;
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(-137deg, #ffcc00 0%, #ff8d00 100%) !important;
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffcc00', endColorstr='#ff8d00',GradientType=1 );
  /* IE6-9 */
  border: none; }

.register-btn {
  border: 3px solid #ffb700;
  width: 164px;
  height: 45px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  margin-left: 30px;
  color: #ffb700; }

.register-btn:hover {
  text-decoration: none;
  color: white;
  background: #ffcc00 !important;
  /* Old browsers */
  background: -moz-linear-gradient(left, #ffcc00 0%, #ff8d00 100%) !important;
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #ffcc00 0%, #ff8d00 100%) !important;
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(-137deg, #ffcc00 0%, #ff8d00 100%) !important;
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffcc00', endColorstr='#ff8d00',GradientType=1 );
  /* IE6-9 */
  border: none; }

.content {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 70px; }

.content .title {
  font-weight: 700;
  font-size: 30px;
  color: #42375b;
  margin-bottom: 20px; }

.content .text {
  font-weight: 400;
  font-size: 14px;
  color: #808b96; }

.assocs {
  margin-top: 80px;
  margin-bottom: 100px; }

.assocs .assoc {
  display: flex;
  align-items: center;
  border-bottom: 3px solid #f1f0f3;
  border-right: 3px solid #f1f0f3;
  padding: 30px; }

.assocs .assoc.no-border {
  border-right: 0; }

.assocs .assoc .logo {
  width: 30vw;
  padding: 20px;
  padding-left: 0;
  border-right: 1px solid #f1f0f3;
  margin-right: 20px; }

.assocs .assoc .logo img {
  width: 100%;
  height: auto; }

.assocs .assoc .info {
  width: 70vw; }

.assocs .assoc .info h2 {
  font-weight: 700;
  font-size: 16px;
  color: #42375b; }

.assocs .assoc .info p {
  font-weight: 400;
  font-size: 13px;
  color: #808b96;
  margin-top: 20px;
  height: 150px; }

.assocs .assoc .info .site-btn {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-weight: 700;
  font-size: 15px;
  color: #42375b;
  cursor: pointer; }

.assocs .assoc .info .site-btn:hover {
  text-decoration: none; }

.mobile-nav-toggler {
  position: absolute;
  right: 30px; }

.assoc_cover {
  width: 100%;
  height: 597px;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-position: left center;
  padding-left: 10.5%; }

.assoc_cover.mobile {
  display: none; }

.assoc_cover .cover_content {
  display: flex;
  flex-direction: column; }

.assoc_cover .cover_content .short_name {
  font-weight: 700;
  color: white;
  font-size: 43px;
  line-height: 45px; }

.assoc_cover .cover_content .name {
  font-weight: 500;
  color: white;
  font-size: 41px;
  line-height: 45px; }

.assoc_cover .cover_content .desc {
  font-weight: 400;
  color: white;
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: 30px; }

.htw {
  width: 100%;
  padding-top: 80px;
  text-align: center;
  padding-bottom: 60px; }

.htw .row {
  margin-top: 50px; }

.htw .title {
  color: #42375b;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  line-height: 42px; }

.htw .title span {
  font-size: 35px;
  font-weight: 400; }

.htw .step {
  text-align: center; }

.htw .step .s-title {
  font-weight: 600;
  font-size: 18px;
  color: #42375b;
  margin-bottom: 0;
  margin-top: 40px; }

.htw .step .desc {
  font-weight: 300;
  font-size: 16px;
  color: #808b96;
  margin-top: 15px; }

.htw .uyeol-btn {
  margin-top: 40px; }

.testimonials {
  background-color: #42375b;
  height: 630px;
  padding-top: 40px; }

.testimonials .title {
  font-weight: 700;
  color: white;
  font-size: 30px;
  text-align: center; }

.testimonials .subtitle {
  text-align: center;
  font-weight: 300;
  color: white;
  font-size: 16px; }

.testimonials .comments {
  margin-top: 70px; }

.testimonials .comments .comment .inner {
  width: 100%;
  height: 365px;
  border: 2px solid #ffffff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-left: 23px;
  padding-right: 23px;
  text-align: center; }

.testimonials .comments .comment .inner .message {
  font-size: 16px;
  color: white;
  font-weight: 400; }

.testimonials .comments .comment .inner .name {
  font-size: 16px;
  color: white;
  font-weight: 700; }

.brands {
  width: 100%;
  height: 306px;
  background-image: url(../img/assoc-brands-bg.jpg);
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

.brands .title {
  font-size: 30px;
  color: #42375b;
  font-weight: 700; }

.brands .subtitle {
  font-size: 30px;
  color: #42375b;
  font-weight: 400; }

.brands .site-btn {
  width: 215px;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-weight: 700;
  font-size: 15px;
  color: white;
  cursor: pointer; }

.brands .site-btn:hover {
  text-decoration: none; }

.mobile-header {
  display: none;
  height: 82px;
  position: relative;
  z-index: 9999;
  width: 100vw;
  top: 0; }

.mobile-header .scroll-m {
  display: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 35px;
  padding-right: 35px; }

.zero-m {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; }

.scroll-mh {
  background: white;
  -webkit-box-shadow: 0px 10px 17px -8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 10px 17px -8px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 17px -8px rgba(0, 0, 0, 0.2); }

.mobile-header .scroll-m .nav-link {
  border: 3px solid #ffc400;
  padding-right: 35px !important;
  padding-left: 35px !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  border-radius: 50px !important;
  height: 43px;
  font-weight: 700;
  font-size: 16px; }

.mobile-header .scroll-m .nav-itemt {
  position: inherit; }

.mobile-header .scroll-m .logo {
  position: inherit; }

.mobile-header .scroll-m .mobile-logo {
  box-shadow: none !important; }

.mobile-header .logo {
  position: absolute;
  top: 38px;
  z-index: 2; }

.mobile-header .nav-itemt {
  position: absolute;
  right: 30px; }

.nav-itemt .dropdown-toggle:after {
  content: none; }

.nav-itemt .dropdown-menu {
  left: auto !important;
  right: 0 !important;
  top: 20px !important;
  border: 0;
  background-color: #f8f9fd;
  text-align: right;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0; }

.nav-itemt .dropdown-menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-right: 20px; }

.nav-itemt .dropdown-menu ul li a {
  color: #847f92;
  font-size: 16px; }

.nav-itemt .dropdown-menu.show {
  max-height: 300px;
  opacity: 1; }

.nav-itemt .dropdown-menu li {
  margin-bottom: 10px; }

.nav-itemt .dropdown-menu a:hover {
  text-decoration: none; }

@media only screen and (max-width: 1200px) {
  .assoc_cover {
    background-position: center center; } }

@media only screen and (max-width: 768px) {
  .assoc_cover {
    margin-top: 0px; }
  .testimonials .comments .comment .inner {
    margin-bottom: 30px; }
  .testimonials {
    padding-bottom: 40px;
    height: auto; }
  .mobile-header {
    display: flex; } }

@media only screen and (max-width: 480px) {
  .content .title {
    font-size: 22px; }
  .content .text {
    font-size: 13px; }
  .assocs .assoc .info span {
    font-size: 15px; }
  .assocs .assoc .info p {
    font-size: 13px; }
  .assoc_cover.desktop {
    display: none; }
  .assoc_cover.mobile {
    display: flex;
    height: 490px;
    background-repeat: no-repeat;
    margin-top: 0;
    padding-left: 0;
    align-items: flex-end; }
  .assoc_cover.mobile .cover_content {
    margin-top: 0; }
  .assoc_cover .cover_content .short_name {
    font-size: 25px;
    text-shadow: 1.414px 1.414px 6.02px rgba(8, 0, 64, 0.5); }
  .assoc_cover .cover_content .name {
    font-size: 18px;
    text-shadow: 1.414px 1.414px 6.02px rgba(8, 0, 64, 0.5);
    line-height: 20px; }
  .assoc_cover .cover_content .desc {
    font-size: 13px;
    text-shadow: 1.414px 1.414px 6.02px rgba(8, 0, 64, 0.5);
    font-weight: 600; }
  .brands .subtitle {
    font-size: 18px;
    text-align: center; }
  .brands {
    padding-left: 20px;
    padding-right: 20px; }
  .assocs .assoc .info p {
    overflow: hidden; }
  .assocs .assoc {
    border: 0; } }

.sss {
  margin-bottom: 90px; }

.sss .title {
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 26px;
  color: #fcb50e; }

.sss .card {
  border: 0;
  background-color: #f7f7f7;
  border-radius: 10px;
  margin-bottom: 10px; }

.sss .card .card-header {
  border: 0;
  background-color: #f7f7f7;
  border-radius: 10px; }

.sss .card .card-header h2 {
  align-items: center;
  display: flex; }

.sss .card .card-header h2 button {
  font-weight: 500;
  color: #847f92;
  font-size: 17px;
  padding-left: 0;
  border: 0;
  border-bottom: 1px solid #e7e7e7;
  text-align: left;
  width: 100%;
  text-align: left;
  padding-bottom: 10px;
  text-decoration: none !important; }

.sss .card .card-header h2 button.collapsed {
  border: 0;
  padding-bottom: 0; }

.sss .card .card-header h2 button:hover {
  text-decoration: none; }

.sss .card .card-header h2 button:focus {
  outline: none !important; }

.sss .card .card-body {
  font-weight: 400;
  color: #847f92;
  font-size: 14px;
  padding-top: 0;
  padding-left: 33px;
  padding-right: 33px; }

.content .title {
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 26px;
  color: #fcb50e; }

.content p {
  font-weight: 400;
  margin-bottom: 27px;
  color: #847f92;
  font-size: 15px; }

.section-ticimax {
  position: relative;
  height: 79vh;
  padding: 13vh ​0; }
  .section-ticimax .section-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat; }
  .section-ticimax h4 {
    font-size: 4.6vh; }
  .section-ticimax h1 {
    font-size: 7.8vh;
    font-weight: 900; }
  .section-ticimax p, .section-ticimax a {
    /* ont-size: 2.5vh; */
    margin-top: 3vh; }
  .section-ticimax img {
    height: 50vh; }

.section-companies {
  background: #f8f9fa;
  padding: 20px 0; }
  .section-companies .companies-container a {
    width: 20%;
    height: 69px;
    position: relative;
    display: inline-block; }
    .section-companies .companies-container a img {
      position: absolute;
      max-width: 50%;
      min-width: 50%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }

.section-about-tici p strong {
  font-size: 18px; }

.section-about-tici img {
  width: 59% !important; }

.section-form .img {
  height: 386px; }
  .section-form .img img {
    height: 100%; }

.section.mobile {
  display: none; }

.form-tici textarea {
  border-radius: 10px !important; }

.form-tici .form-control {
  border: 1px solid #FF015B;
  border-radius: 10px !important; }

.form-tici .btn {
  font-size: 18px; }

.form-tici small {
  font-size: 19px; }

.form-header {
  margin-bottom: 30px; }

.footer-tici {
  padding: 0 !important; }
  .footer-tici .footer-box .footer-logo a i {
    color: #FFF !important; }
  .footer-tici p, .footer-tici span {
    color: #FFF !important;
    margin-top: 15px; }
  .footer-tici .footer-box ul li a {
    color: #FFF; }

@media only screen and (max-width: 767px) {
  .section.mobile {
    display: block; }
  .section.desktop {
    display: none; }
  .section-ticimax {
    height: auto; }
    .section-ticimax h4 {
      font-size: 4.6vh; }
    .section-ticimax h1 {
      font-size: 7.8vh; }
    .section-ticimax p, .section-ticimax a {
      font-size: 2.5vh;
      margin-top: 3vh; }
  .section-companies {
    display: none; }
    .section-companies .companies-container a {
      width: 100%; }
  .section-about-tici {
    padding: 0; }
  .section-form .img {
    height: auto; }
    .section-form .img img {
      height: auto;
      width: 100%; }
  .box-tici {
    margin-bottom: 30px;
    border-bottom: 1px solid #CCC; } }

.wrapper {
  overflow: hidden; }

/* .container{
	max-width: 1300px;
} */
.choices__item--selectable::after {
  display: none !important; }

.choices__item--selectable {
  padding-right: 10px !important; }

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 2rem; }
  p {
    font-size: 1.1rem; } }

.tog-stk {
  height: 72px;
  margin-top: -17px; }

.tog-stk img {
  height: 100%; }

.sub-stk img {
  height: 200px; }

.sub-stk .donation-companies {
  height: 150px !important; }

.sub-stk .card-body .donation-companies img {
  max-width: 100%;
  max-height: 100%; }

@media only screen and (max-width: 767px) {
  .tog-stk {
    display: none; } }
