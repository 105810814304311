.home{	
	header{
		position: relative;
		padding: 0;
		background: $white;
		transition: all 0.5s ease;
		&.fixed {
			position: fixed;
			top: -66px;
		}
		.header-top{
			width: 100%;
			padding: 15px 0;
			background: $white;

		}
		.header-bottom{
			width: 100%;
			background: $white;
		}
		.logo{
			width: 154px;
		}
		.search-box{
			min-width: 300px;
			padding: 8px 0;
			display: inline-block;
			.search-form{
				position: relative;
				input{
					border-radius: 100px !important;
					background:$gray-200;
					border: 0;
					height: 35px;
				}
				button {
					position: absolute;
					top: 50%;
					right: 5px;
					transform: translateY(-50%);
					width: 40px;
					text-align: center;
					box-shadow: 0 0 0 0;
					background: none;
					color: $defaultTextColor;
					border: 0;
				}
			}
		}
		.login-menu{
			padding: 5px 0;
			.login-box{
				text-align: center;
				display: flex;
				border: 2px solid $defaultButtonColor;
				padding: 7px 15px;
				margin-left: 10px;
				color: $defaultButtonColor;
				height: 40px;
				border-radius: 100px;
				float: left;
				.profile-img {
					width: 30px;
					height: 30px;
					overflow: hidden;
					border-radius: 100%;
					position: relative;
					margin-top: -4px;
					margin-left: -7px;
					img {
						height: 100%;
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
					}
				}
				.dropdown-menu{
					right: 0 !important;
					left: inherit !important;
				}
				&:after{
					top: 10px;
					position: relative;
				}
				p{
					color: $defaultButtonColor;
					font-weight: 600;
					margin-left: 5px;
				}
				&.total-donate{
					display: inline-block;
					border-color: $gray-200;
					text-align: right;
					width: 128px;
					small{
						font-size: 10px;
						color: $defaultTextColor;
						margin-top: -5px;
						float: left;
						font-weight: 0;
						width: 100%;
					}
					p{
						float: left;
						width: 100%;
						margin-top: -5px;
					}
				}
			}
		}
		.collapse-menu{
			width: 100%;
			border-top: 2px solid $gray-200;
			display: flex;
			max-width: 1110px;
			.category-menu{
				position: relative;
				width: 30px;
				height: 40px;
				display: none;
				#category-menu{
					height: 40px;
					display: inline-block;
					span{
						position: absolute;
						width: 100%;
						height: 2px;
						background: $gray-900;
						top: 50%;
						border-radius: 100px;
						width: 100%;
						transition: 0.5s ease all;
						&:before{
							content: '';
							position: absolute;
							width: 50%;
							height: 2px;
							background: $gray-900;
							top: -5px;
							transition: 0.5s ease all;
							left: 0;
						}
						&:after{
							content: '';
							position: absolute;
							width: 50%;
							height: 2px;
							background: $gray-900;
							top: 5px;
							transition: 0.5s ease all;
							right: 0;
						}
					}
					&.active{
						span{
							transform: rotate(45deg);
							&:before{
								transform: rotate(90deg);
								left: 8px;
								top: -7px;
							}
							&:after{
								transform: rotate(90deg);
								right: 7px;
								top: 7px;
							}
						}
					}
				}
				
				
			}
			ul{
				margin: 0;
				width:100%;
				text-align: center;
				li{
					position: relative;
					display: inline-block;
					a{
						font-size: 0.999rem;
						&.dropdown-toggle{
							padding: 0 !important;
							width: 100%;
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							
							&:after{
								display: none;
							}
						}
						float: left;
						padding: 10px 8px;
						border-top: 2px solid transparent;
						color: $gray-900;
						margin-top: -2px;
						font-weight: 500;
						&:hover{
							border-top: 2px solid $defaultButtonColor;
							color: $defaultButtonColor;
						}
					}
					ul {
						position: absolute;
						width: 200px;
						background: $white;
						box-shadow: $boxShadow;
						border-radius: 20px;
						top: 38px;
						left: 50%;
						transform: translateX(-50%);
						text-align: center;
						display: none;
						&:after{
							content: '';
							border-left: 10px solid transparent;
							border-right: 10px solid transparent;
							border-top: 10px solid transparent;
							border-bottom: 10px solid $white;
							position: absolute;
							top: -20px;
							left: 50%;
							transform: translateX(-50%);
						}
						li{
							a{
								border-top: 0 !important;
								&:hover{
									border-top: 0 !important;
									border-bottom: 2px solid $defaultButtonColor;
								}
							}
							&:last-child{
								a{
									&:hover{
										border-bottom: 0;
									}
								}
							}
						}
					}
					&:hover{
						ul{
							display: block;
							li{
								width: 100%;
								a{
									width: 100%;
								}
							}
						}
					}
				}
				
			}
		}
	}
}

.dropdown-menu{
	box-shadow: $boxShadow;
	border: 0;
	padding: 0;
	background-color: $white;
	&:after{
		content: '';
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-top: 10px solid transparent;
		border-bottom: 10px solid $white;
		position: absolute;
		top: -20px;
		left: 30px;
		z-index: 2;
	}
	li{
		border-bottom: 1px solid $gray-200;
		padding: 15px 0;
		a{
			padding: 0px 10px;
			i{
				width: 30px;
				display: inline-block;
				text-align: center;
				background: linear-gradient(#FDC50B, #FFA700);
				height: 30px;
				border-radius: 100%;
				padding: 6px;
				color: $white;
				margin-right: 10px;
			}
		}
		&:last-child{
			border: 0;
		}
	}
}
#overlay {
	display: none;
	&.active {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(0, 0, 0, 0.35);
		width: 100%;
		height: 100%;
		display: block;
	}
}

#collapsed-items {
	position: absolute;
    left: -17px;
    width: 300px;
    top: 40px;
    background: #fff;
    z-index: 5;
    -webkit-transition: all .5s ease;
	transition: all .5s ease;
	display: none;
	opacity: 0;
	&.hidden {
		display: block;
		opacity: 1;
		visibility: inherit;
		box-shadow: $boxShadow;
	}
	transition: 0.5s ease all;
	li {
		width: 100%;
		display: inline-block;
		a {
			font-size: 18px;
			padding: 16px;
			border-bottom: 1px solid $gray-200;
			width: 100%;
			display: inline-block;
		}
	}
}


@media only screen and (max-width:1199px){
	.collapse-menu{
		.category-menu{
			display: block !important;
		}
		ul{
			display: none ;
			li{

			}
		}
		&.active{
			ul{
				display: block!important;
				position: absolute;
				top: 123px;
				background: #FFF;
				left: 0;
				width: 100%;
				li{

				}
			}
		}
	}
}

@media only screen and (max-width:991px){
	.home{
		header{
			.header-top{
				.login-menu{
					margin-right: 0;
					.dropdown-menu{
						z-index: 99999999;
					}
				}
				.login-box{
					width: 50px;
					display: inline-block;
					.profile-img{
						margin-top: -4px;
					}
					p{
						display: none;
					}
					&.total-donate{
						p{
							display: block;
						}
					}
				}
			}
			.search-box{
				min-width: 200px;
				input{
				}
			}
		}
	}
}

@media only screen and (max-width:768px){
	.home{
		header{
			.container{
				position: relative;
			}
			.search-box{
				position: absolute;
				top: 52px;
				right: 15px;
				z-index: 9999999;
				input{
				}
			}
			.logo{
				width: 164px !important;
			}
		}
	}
	
	#collapsed-items{
		&.hidden{

		}
		li{
			a{
				font-size: 1rem;
			}
		}
	}
	.collapse-menu {
		#auto-nav{
			margin: 0;
			width: 100%;
			left: 0;
			top: 42px;
			li{
				position: relative;
				width: 100%;
				display: inline-block;
				border-bottom: 1px solid $gray-200;
			}
		}
	}
}

@media only screen and (max-width:767px){
	.home{
		header{
			.container{
				position: relative;
			}
			.header-bottom{
				float: left;
			}
			.login-menu{
				position: static;
				width: auto;
				.total-donate{
					display: none !important;
				}
			}
		}
	}
}