.subpage-header-menu {
    width: 100%;
    background: lighten($blue, 45%);
	border-radius: 20px;
	margin-top: 30px;
	ul{
		margin: 0;
		li{
			display: inline-block;
			border: 0 !important;
			a{
				padding: 15px;
				display: inline-block;
				color: $blue !important;
				
				&.active, &:hover{
					border: 0 !important;
					background: transparent !important;
					border-bottom: 2px solid $blue !important;
					font-weight: 600;
				}
			}
			
		}
	}
}

.subpage-left, .subpage-right{
	margin-top: 30px;
	margin-bottom: 100px;
}
.profile-card{
	background: $white;
	box-shadow: $boxShadow;
	border-radius: 20px;
	text-align: center;
	margin-top: 30px;
	padding: 15px;
	margin-bottom: 15px;
	.profile-img{
		width: 150px;
		height: 150px;
		overflow: hidden;
		border-radius: 100%;
		display: inline-block;
		margin-top: -80px;
		position: relative;
		img{
			height: 100%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		.img-add-button {
			position: absolute;
			width: 100%;
			height: 100%;
			// background: rgba(255, 1, 91, 0.439);
			z-index: 1;
			left: 0;
			top: 0;
			input[type=file]+span {
				background: none;
				color: #FFF;
				font-size: 35px;
				padding: 42px 0;
			}
		}
		
		
	}
	.profile-body{
		h4{
			margin-bottom: 15px;
		}
		.total-donate{
			margin-top: 15px;
			margin-bottom: 15px;
			h3{
				color: $defaultButtonColor;
			}
		}
	}
}

.subpage-card{
	.card-header{
		border: 0ch;
		background: transparent;
		display: inline-block;
		width: 100%;
		h4{
			font-weight: 700;
			margin-bottom: 0;
		}
		a{
			padding: 0;
		}
	}
	.card-body{
		background: $white;
		box-shadow: $boxShadow;
		border-radius: 20px;
		.donation-companies{
			height: 100px;
			text-align: center;
			position: relative;
			img{
				max-width: 70%;
				max-height: 70%;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}
}

.social-media-content{
	background: $gray-100;
	border-radius: 20px;
	padding: 15px;
	margin-bottom: 15px;
	p{
		margin-bottom: 0;
	}
	h3{
		color: $defaultButtonColor;
		font-weight: 700;
	}
	ul{
		margin: 0;
		li{
			display: inline-block;
			a{
				font-size: 30px;
				margin-left: 15px;
				&:hover{
					color: $defaultButtonColor;
				}
			}
		}
	}
}

.subpage-list{
	&-header{

	}
	&-img{
		height: 75px;
		background: $white;
		box-shadow: $boxShadow;
		position: relative;
		border-radius: 20px;
		overflow: hidden;
		img{
			position: absolute;
			max-width: 70%;
			max-height: 70%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	&-body{
		height: 265px;
		overflow: auto;
		ul{
			li{
				margin-bottom: 15px;
			}
		}
	}
	&-desc{
		p{
			font-weight: 700;
			margin-bottom: 0;
		}
		small{
			display: inline-block;
			width: 100%;
			color: $defaultButtonColor;
		}
		
	}
	.donate {
		background: lighten($yellow, 40%);
		width: 75px;
		height: 75px;
		border-radius: 100%;
		text-align: center;
		padding: 11px 0;
		p {
			margin: 0;
			font-size: 21px;
			font-weight: 700;
			color: $defaultButtonColor;
		}
		small{
			color: $defaultButtonColor;
		}
	}
	.list-content-img {
		width: 100px;
		display: inline-block;
	}
	
	.list-content-price {
		width: 75px;
	}
	
	.list-content-desc {
		width: calc(100% - 175px);
		padding-left: 15px !important;
		display: inline-block;
	}
}

.shopping-form-wrap{
	background: lighten($yellow, 35%);
	border-radius: 20px;
	padding: 30px;
	position: relative;
	margin-top: 30px;
	.shopping-bg {
		height: 100%;
		position: absolute;
		width: 100%;
		background-size: 96%;
		background-position: bottom;
		background-repeat: no-repeat;
		right: -15px;
		bottom: 0;
	}
	input{
		border-radius: 100px !important;
		border: 0 !important;
	}
}

@media only screen and (max-width:991px){
	.social-media-content{
		p{
		}
		h3{
		}
		ul{
			li{
				a{
					font-size: 16px;
					&:hover{
					}
				}
			}
		}
	}
	.profile-card{
		.profile-img{
			width: 75px;
			height: 75px;
			input[type=file]+span{
				color: $white !important;
				font-size: 21px !important;
				padding: 22px 0 !important;
			}
			img{
				height: 100%;
			}
		}
	}
	.shopping-form-wrap{
		padding: 10px;
		p {
			font-size: 14px;
		}
	}
	.subpage-list{
		&-body{
			background: #f8f9fa;
			border-radius: 20px;
			padding: 15px 0;
		}
		.list-content-img{
			display: none;
		}
		.list-content-desc{
			width: calc(100% - 75px);
		}
		.donate{
			width: 60px;
			height: 60px;
			p{
				font-size: 16px;
			}
		}
		&.shopping-list{
			.list-content-img{
				display: inline-block;
			}
		}
	}
	.profile-card{
		.profile-img{
		}
		.profile-body{
			h4{
				font-size: 14px;
			}
			.total-donate{
				h3{
					font-size: 16px;
				}
			}
			.btn{
				font-size: 11px;
			}
		}
	}
	.subpage-card{
		.card-header{
			h4{
				font-size: 14px;
			}
			a{
				font-size: 14px;
			}
		}
		.card-body{
			.donation-companies{
				img{
				}
			}
		}
	}
}
@media only screen and (max-width:767px){
	.subpage-header-menu{
		margin-top: 0;
		border-radius: 0;
	}
	.profile-card{
		margin-top: 85px;
		.profile-img{}
	}
	.subpage-list{
		margin-bottom: 20px;
		&-body{
			max-height: 265px;
			height: auto;
		}
	}
	.social-media-content{
		p{
			font-size: 15px;
		}
		h3{
			font-size: 21px;
		}
		ul{
			li{
				a{
					&:hover{
					}
				}
			}
		}
	}
}
@media only screen and (max-width:420px){
	
}