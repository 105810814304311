@keyframes left-to-right {
    from {
        left: 0;
        right: auto;
    }
    
    to {
        left: 210%;
        right: auto;
    }
}

@keyframes right-to-left {
    from {
        right: -210%;
        left: auto;
    }
    
    to {
        right: 0;
        left: auto;
    }
}

@keyframes menu-scrolled-bottom {
    from {
        background: linear-gradient(0deg, rgba(27, 59, 87, 0) 10%, rgba(0, 33, 84, .87) 100%);
        transition: all .5s ease;
    }

    to {
        background: linear-gradient(0deg, rgba(27, 59, 87, 1) 10%, rgba(0, 33, 84, .87) 100%);
        transition: all .5s ease;
    }
}

@keyframes menu-scrolled-top {
    from {
        background: linear-gradient(0deg, rgba(27, 59, 87, 1) 10%, rgba(0, 33, 84, .87) 100%);
        transition: all .5s ease;
    }

    to {
        background: linear-gradient(0deg, rgba(27, 59, 87, 0) 10%, rgba(0, 33, 84, .87) 100%);
        transition: all .5s ease;
    }
}
