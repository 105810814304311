.section{
	&-ticimax{
		position: relative;
		height: 79vh;
		padding: 13vh ​0;
		.section-bg{
			position: absolute;
			height: 100%;
			width: 100%;
			background-repeat: no-repeat;
		}
		h4{
			font-size: 4.6vh;
		}
		h1{
			font-size: 7.8vh;
    		font-weight: 900;
		}
		p, a{
			/* ont-size: 2.5vh; */
			margin-top: 3vh;
		}
		img{
			height: 50vh;
		}
	}
	&-companies{
		background: $gray-100;
		padding: 20px 0;
		.companies-container{
			a{
				width: 20%;
				height: 69px;
				position: relative;
				display: inline-block;
				img{
					position: absolute;
					max-width: 50%;
					min-width: 50%;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
			}
		}
	}
	&-about-tici{
		p{
			strong{
				font-size: 18px;
			}
		}
		img{
			width: 59%!important;
		}
	}
	&-form{
		.img{
			height: 386px;
			img{
				height: 100%;
			}
		}
	}
	&.mobile{
		display: none;
	}
}

.form{
	&-tici{
		textarea{
			border-radius: 10px !important;
		}
		.form-control{
			border: 1px solid $defaultButtonColor;
			border-radius: 10px !important;
		}
		.btn{
			font-size: 18px;
		}
		small{
			font-size: 19px;
		}
		
	}
	&-header {
		margin-bottom: 30px;
	}
}

.footer{
	&-tici{
		padding: 0 !important;
		.footer-box .footer-logo a i {
			color: #FFF !important;
		}
		p, span {
			color: #FFF !important;
			margin-top: 15px;
		}
		.footer-box ul li a {
			color: #FFF;
		}
	}
}

@media only screen and (max-width:767px){
	.section{
		&.mobile{
			display: block;
		}
		&.desktop{
			display: none;
		}
		&-ticimax{
			height: auto;
			h4{
				font-size: 4.6vh;
			}
			h1{
				font-size: 7.8vh;
			}
			p, a{
				font-size: 2.5vh;
				margin-top: 3vh;
			}
		}
		&-companies{
			display: none;
			.companies-container{
				a{
					width: 100%;
				}
			}
		}
		&-about-tici{
			padding: 0;
		}
		&-form{
			.img{
				height: auto;
				img{
					height: auto;
					width: 100%;
				}
			}
		}
		
	}
	.box-tici {
		margin-bottom: 30px;
		border-bottom: 1px solid #CCC;
	}
	
}