.supporters-box{
	position: relative;
	border-radius: 20px;
	transition: all 0.5s ease;
	box-shadow: $boxShadow;
	overflow: hidden;
	text-align: center;
	margin-bottom: 30px;
	&-img{
		height: 175px;
		overflow: hidden;
		border-radius: 20px;
		position: relative;
		img{
			width: 100%;
			position: relative;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	&-company{
		width: 160px;
		height: 60px;
		background: $white;
		text-align: center;
		display: inline-block;
		background: $white;
		border-radius: 20px;
		box-shadow: $boxShadow;
		position: relative;
		top: -30px;
		img{
			max-height: 50px;
			max-width: 75px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	&-detail{
		position: relative;
		top: -15px;
		h3{
			font-weight: 800;
			margin: 0;
		}
		small{

		}
	}
}
.dropdown-menu{
	width: 180px;
}

.form-scroll {
    height: 58vh;
	overflow-y: scroll;
	overflow-x: hidden;
}