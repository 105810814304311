html {
	scroll-behavior: smooth;
}

body{
	font-family: 'Barlow', sans-serif;
	color: $defaultTextColor;
}
ul, li{
	list-style-type: none;
	padding: 0;
}
p{
	color: $defaultTextColor;
}
a {
	transition: 0.3s ease all;
	color: $defaultTextColor;
    &:focus, &:active {
        outline: none !important;
        box-shadow: none !important;
	}
	&:hover{
		text-decoration: none;
	}
    &.btn-small {
        padding-left: 20px;
        padding-right: 20px;
        border: transparent;
        font-size: 12px;
        font-weight: $font-weight-bold;
        box-shadow: 0 2px 3px $black;
    }
}